import { IonIcon } from '@ionic/react';
import { PostAdditionButton } from './form.styled';
import { camera } from 'ionicons/icons';
import { useRef } from 'react';

interface ImageSelectButtonProps {
    onAddImages: (files: File[]) => void;
    disabled?: boolean;
}
const ImageSelectButton = ({ onAddImages, disabled }: ImageSelectButtonProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically trigger file input click
        }
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            onAddImages(Array.from(files));
        }
    };

    return (
        <>
            <PostAdditionButton onClick={handleButtonClick} disabled={disabled}>
                <IonIcon icon={camera} />
            </PostAdditionButton>
            <input onChange={handleFileInputChange} multiple={false} ref={fileInputRef} type="file" hidden />
        </>
    );
};

export default ImageSelectButton;
