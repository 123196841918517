import { ReactNode, createContext } from 'react';
import { Comment, PostStub, SongStub, UserStub } from '../types';
import useCommonActionContext from '../hooks/context/use-common-action-context';

export interface CommonActionContextType {
    showShareDrawer: boolean;
    setShowShareDrawer: (v: boolean) => void;
    shareLink: string | null;
    setShareLink: (value: string | null) => void;
    sharePost: PostStub | null;
    setSharePost: (value: PostStub | null) => void;
    reportPost: PostStub | null;
    setReportPost: (value: PostStub | null) => void;
    reportComment: Comment | null;
    setReportComment: (value: Comment | null) => void;
    reportSong: SongStub | null;
    setReportSong: (value: SongStub | null) => void;
    reportUser: UserStub | null;
    setReportUser: (value: UserStub | null) => void;
}

export const CommonActionContext = createContext<CommonActionContextType>({
    showShareDrawer: false,
    setShowShareDrawer: (v) => {},
    shareLink: null,
    setShareLink: (value) => {},
    sharePost: null,
    setSharePost: (value) => {},
    reportPost: null,
    setReportPost: (value) => {},
    reportComment: null,
    setReportComment: (value) => {},
    reportSong: null,
    setReportSong: (value) => {},
    reportUser: null,
    setReportUser: (value) => {},
});

const CommonActionContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useCommonActionContext();
    return <CommonActionContext.Provider value={store}>{children}</CommonActionContext.Provider>;
};

export default CommonActionContextProvider;
