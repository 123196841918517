import { IonApp, setupIonicReact } from '@ionic/react';
import LoginPage from './pages/login/login-page.component';
import Splash from './components/splash.component';
import { AuthStatus } from './types/auth/auth-status';
import MainRouter from './main-router';
import dayjs from 'dayjs';

import '@ionic/react/css/core.css';
import './theme/base.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Toast from './components/basic/toast.component';
import Providers from './Providers';
import { ReactNode, memo } from 'react';
import useAuth from './hooks/use-auth';
import useFeatureFlags from './hooks/use-feature-flags';
import FullscreenLoading from './components/fullscreen-loading.component';
import TermsAndConditionsPage from './pages/terms-and-conditions/terms-and-conditions-page.component';


setupIonicReact();
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const AppInner = memo(() => {
    const auth = useAuth();
    const features = useFeatureFlags();

    const getShowSplash = (): boolean => {
        return auth.isWaitingOnRefresh;
    };
    const getShowLoading = (): boolean => {
        if (getShowSplash()) return false;
        if (auth.state !== AuthStatus.LoggedIn) return false;
        return !features.terms;
    };

    const getPage = (): ReactNode => {
        if (auth.state !== AuthStatus.LoggedIn) return <LoginPage />;
        if (!features?.terms) return null;
        if ((features.terms.acceptedVersion ?? -1) < features.terms.effectiveVersion) return <TermsAndConditionsPage />;
        return <MainRouter />;
    };

    return (
        <>
            <Toast />
            <FullscreenLoading show={getShowLoading()} />
            <Splash show={getShowSplash()} />
            {getPage()}
        </>
    );
});

const App = () => {
    return (
        <IonApp>
            <Providers>
                <AppInner />
            </Providers>
        </IonApp>
    );
};

export default App;
