import Button from '../../components/basic/button.component';
import LoadingSpinner from '../../components/basic/loading-spinner.component';
import OxPage from '../../components/oxpage.component';
import { Subtitle } from '../../components/typography.styled';
import useFeatureFlags from '../../hooks/use-feature-flags';
import { ContentArea, LoadingHolder, TermsArea } from './terms.styled';

const TermsAndConditionsPage = () => {
    const features = useFeatureFlags();

    return (
        <OxPage>
            <ContentArea>
                <Subtitle>Terms of Service</Subtitle>
                <TermsArea>
                    <h3>Your use of the Platform</h3>
                    <p>
                        Subject to your strict compliance with these Terms of Use and our Community guidelines at any
                        and all times during your use of the Platform, Oxchord grants you a limited, personal,
                        non-exclusive, revocable, non-assignable and non-transferable right and license to use the
                        Platform in order to view Content uploaded and posted to the Website, to listen to audio Content
                        streamed from the Platform on or offline and to share and download audio Content using the
                        features of the Platform where the appropriate functionality has been enabled by the user who
                        uploaded the relevant Content (the&nbsp;<strong>"Uploader"</strong>), and subject to the
                        territorial availability of that feature and audio Content.
                    </p>
                    <p>
                        In addition, if you register to use the Platform, and subject to your strict compliance with
                        these Terms of Use and our&nbsp;Community Guidelines&nbsp;at any and all times during your use
                        of the Platform, Oxchord grants you a limited, personal, non-exclusive, revocable,
                        non-assignable and non-transferable right and license to:
                    </p>
                    <p>
                        (i) submit, upload or post Content to and keep such Content available on the Platform strictly
                        as permitted in accordance with these Terms of Use and any other applicable terms posted on the
                        Website from time to time;
                    </p>
                    <p>
                        (ii) participate in the community areas and communicate with other members of the Oxchord ®
                        community strictly in accordance with these Terms of Use and our&nbsp;Community Guidelines; and
                    </p>
                    <p>
                        (iii) use Apps and other Services provided as part of the Platform strictly as permitted in
                        accordance with these Terms of Use and any other terms applicable to those Apps or Services from
                        time to time.
                    </p>
                    <p>
                        The above licenses are conditional upon your strict compliance with these Terms of Use and
                        our&nbsp;Community Guidelines&nbsp;at any and all times during your use of the Platform,
                        including, without limitation, the following:
                    </p>
                    <p>
                        (i) You must not copy, rip or capture, or attempt to copy, rip or capture, any audio Content
                        from the Platform or any part of the Platform, other than by means of download or store for
                        offline listening in circumstances where the relevant Uploader has elected to permit downloads,
                        rips, captures or offline listening of the relevant item of Content.
                    </p>
                    <p>
                        (ii) You must not adapt, copy, republish, make available or otherwise communicate to the public,
                        display, perform, transfer, share, distribute or otherwise use or exploit any Content on or from
                        the Platform at any and all times, except (i) where such Content is Your Content at any and all
                        times during your use of the applicable Content, or (ii) as permitted under these Terms of Use,
                        and within the parameters set by the Uploader (for example, under the terms of Creative Commons
                        licenses selected by the Uploader).
                    </p>
                    <p>
                        (iii) You must not use any Content (other than Your Content) in any way that is designed to
                        create a separate content service or that replicates any part of the Platform offering.
                    </p>
                    <p>
                        (v) You must not employ any techniques or make use of any services, automated or otherwise,
                        designed to misrepresent the popularity of Your Content on the Platform, or to misrepresent your
                        activity on the Platform, including without limitation by the use of bots, botnets, scripts,
                        apps, plugins, extensions or other automated means to register accounts, log in, add followers
                        to your account, play Content, follow or unfollow other users, send messages, post comments, or
                        otherwise to act on your behalf, particularly where such activity occurs in a multiple or
                        repetitive fashion. You must not offer or promote the availability of any such techniques or
                        services to any other users of the Platform.
                    </p>
                    <p>
                        (vi) You must not alter or remove, or attempt to alter or remove, any trademark, copyright or
                        other proprietary or legal notices contained in, or appearing on, the Platform or any Content
                        appearing on the Platform (other than Your Content).
                    </p>
                    <p>
                        (vii) You must not, and must not permit any third party to, copy or adapt the object code of the
                        Website or any of the Apps or Services, or reverse engineer, reverse assemble, decompile, modify
                        or attempt to discover any source or object code of any part of the Platform, or circumvent or
                        attempt to circumvent or copy any copy protection mechanism or territorial restrictions or
                        access any rights management information pertaining to Content other than Your Content.
                    </p>
                    <p>
                        (viii) You must not use the Platform to upload, post, store, transmit, display, copy,
                        distribute, promote, make available, continue to make available or otherwise communicate to the
                        public:
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                any Content that is abusive, libellous, defamatory, pornographic or obscene, that
                                promotes or incites violence, terrorism, illegal acts, or hatred on the grounds of race,
                                ethnicity, cultural identity, religious belief, disability, gender, identity or sexual
                                orientation, or is otherwise objectionable in Oxchord’s reasonable discretion;
                            </p>
                        </li>
                        <li>
                            <p>
                                any information, Content or other material that violates, plagiarizes, misappropriates
                                or infringes the rights of third parties including, without limitation, copyright,
                                trademark rights, rights of privacy or publicity, confidential information or any other
                                right; or
                            </p>
                        </li>
                        <li>
                            <p>
                                any Content that violates, breaches or is contrary to any law, rule, regulation, court
                                order or is otherwise is illegal or unlawful in Oxchord’s reasonable opinion;
                            </p>
                        </li>
                        <li>
                            <p>
                                any material of any kind that contains any virus, Trojan horse, spyware, adware,
                                malware, bot, time bomb, worm, or other harmful or malicious component, which or might
                                overburden, impair or disrupt the Platform or servers or networks forming part of, or
                                connected to, the Platform, or which does or might restrict or inhibit any other user's
                                use and enjoyment of the Platform; or
                            </p>
                        </li>
                        <li>
                            <p>
                                any unsolicited or unauthorized advertising, promotional messages, spam or any other
                                form of solicitation.
                            </p>
                        </li>
                    </ul>
                    <p>
                        (ix) You must not commit or engage in, or encourage, induce, solicit or promote, any conduct
                        that would constitute a criminal offense, give rise to civil liability or otherwise violate any
                        law or regulation.
                    </p>
                    <p>
                        (x) You must not deliberately impersonate any person or entity or otherwise misrepresent your
                        affiliation with a person or entity, for example, by registering an account in the name of
                        another person or company, or sending messages or making comments using the name of another
                        person.
                    </p>
                    <p>
                        (xi) You must not stalk, exploit, threaten, abuse or otherwise harass another user, or any
                        Oxchord employee.
                    </p>
                    <p>
                        (xii) You must not use or attempt to use another person's account, password, or other
                        information, unless you have express permission from that other person.
                    </p>
                    <p>
                        (xiii) You must not sell or transfer, or offer to sell or transfer, any Oxchord account to any
                        third party without the prior written approval of Oxchord.
                    </p>
                    <p>
                        (xiv) You must not violate, circumvent or attempt to violate or circumvent any data security
                        measures employed by Oxchord or any Uploader; access or attempt to access data or materials
                        which are not intended for your use; log into, or attempt to log into, a server or account which
                        you are not authorized to access; attempt to scan or test the vulnerability of Oxchord’s
                        servers, system or network or attempt to breach Oxchord’s data security or authentication
                        procedures; attempt to interfere with the Website or the Services by any means including,
                        without limitation, hacking Oxchord’s servers or systems, submitting a virus, overloading,
                        mail-bombing or crashing. Without limitation to any other rights or remedies of Oxchord under
                        these Terms of Use, Oxchord reserves the right to investigate any situation that appears to
                        involve any of the above, and may report such matters to, and co-operate with, appropriate law
                        enforcement authorities in prosecuting any users who have participated in any such violations.
                    </p>
                    <p>
                        You agree to comply with the above conditions at any and all times during your use of the
                        Platform, and acknowledge and agree that Oxchord has the right, in its sole discretion, to
                        terminate your account or take such other action as we see fit if you breach any of the above
                        conditions or any of the other terms of these Terms of Use. This may include taking court action
                        and/or reporting offending users to the relevant authorities.
                    </p>
                    <h3>Acceptance of Terms of Use</h3>
                    <p>
                        Please read these Terms of Use,&nbsp;Privacy Policy,&nbsp;Cookies Policy&nbsp;and&nbsp;Community
                        Guidelines, very carefully. If you do not agree to any of the provisions set out in those
                        documents, you should not use the Platform. By accessing or using the Platform, registering an
                        account, or by viewing, accessing, streaming, uploading or downloading any information or
                        content from or to the Platform, you represent and warrant that you have read and understood
                        these Terms of Use and&nbsp;Community Guidelines, will abide by them, and that you are either 18
                        years of age or more, or the applicable age of majority in your jurisdiction, or if you are
                        under 18 years of age or the age of majority in your jurisdiction, you are 16 years of age or
                        more if you reside in the European Union and UK or 13 years of age or more if you reside in the
                        United States or anywhere else.
                    </p>
                    <h3>Your use of the Platform</h3>
                    <p>
                        Subject to your strict compliance with these Terms of Use and our&nbsp;Community
                        Guidelines&nbsp;at any and all times during your use of the Platform, Oxchord grants you a
                        limited, personal, non-exclusive, revocable, non-assignable and non-transferable right and
                        license to use the Platform in order to view Content uploaded and posted to the Platform, to
                        listen to audio Content streamed from the Platform or offline and to share and download audio
                        Content using the features of the Platform where the appropriate functionality has been enabled
                        by the user who uploaded the relevant Content (the "<strong>Uploader</strong>"), and subject to
                        the territorial availability of that feature and audio Content.
                    </p>
                    <p>
                        In addition, if you register to use the Platform, and subject to your strict compliance with
                        these Terms of Use and our&nbsp;Community Guidelines&nbsp;at any and all times during your use
                        of the Platform, Oxchord grants you a limited, personal, non-exclusive, revocable,
                        non-assignable and non-transferable right and license to:
                    </p>
                    <p>
                        (i) submit, upload or post Content to and keep such Content available on the Platform strictly
                        as permitted in accordance with these Terms of Use and any other applicable terms posted on the
                        Website from time to time;
                    </p>
                    <p>
                        (ii) participate in the community areas and communicate with other members of the Oxchord
                        community strictly in accordance with these Terms of Use and our&nbsp;Community Guidelines; and
                    </p>
                    <p>
                        (iii) use the Platform strictly as permitted in accordance with these Terms of Use and any other
                        terms applicable to the Platform.
                    </p>
                    <p>
                        The above licenses are conditional upon your strict compliance with these Terms of Use and
                        our&nbsp;Community Guidelines&nbsp;at any and all times during your use of the Platform,
                        including, without limitation, the following:
                    </p>
                    <p>
                        (i) You must not copy, rip or capture, or attempt to copy, rip or capture, any Content from the
                        Platform or any part of the Platform, other than by means of download or store for offline
                        listening in circumstances where the relevant Uploader has elected to permit downloads or
                        offline listening of the relevant item of Content.
                    </p>
                    <p>
                        (ii) You must not adapt, copy, republish, make available or otherwise communicate to the public,
                        display, perform, transfer, share, distribute or otherwise use or exploit any Content on or from
                        the Platform at any and all times, except (i) where such Content is your Content at any and all
                        times during your use of the applicable Content, or (ii) as permitted under these Terms of Use,
                        and within the parameters set by the Uploader (for example, under the terms of Creative Commons
                        licenses selected by the Uploader).
                    </p>
                    <p>
                        (iii) You must not use any Content (other than your Content) in any way that is designed to
                        create a separate content service or that replicates any part of the Platform offering.
                    </p>
                    <p>
                        (iv) You must not employ scraping or similar techniques to aggregate, repurpose, republish or
                        otherwise make use of any Content.
                    </p>
                    <p>
                        (v) You must not employ any techniques or make use of any services, automated or otherwise,
                        designed to misrepresent the popularity of your Content or the content of other users on the
                        Platform, or to misrepresent your or other users activity on the Platform, including without
                        limitation by the use of bots, botnets, scripts, apps, plugins, extensions or other automated
                        means to register accounts, log in, add followers to your or other users account, play Content,
                        follow or unfollow other users, send messages, post comments, or otherwise to act on your
                        behalf, particularly where such activity occurs in a multiple or repetitive fashion. You must
                        not offer, sell or promote the availability of any such techniques or services to any other
                        users of the Platform.
                    </p>
                    <p>
                        (vi) You must not alter or remove, or attempt to alter or remove, any trademark, copyright or
                        other proprietary or legal notices contained in, or appearing on, the Platform or any Content
                        appearing on the Platform (other than your Content).
                    </p>
                    <p>
                        (vii) You must not, and must not permit any third party to, copy or adapt the object code of the
                        Website or any of the Apps or Services, or reverse engineer, reverse assemble, decompile, modify
                        or attempt to discover any source or object code of any part of the Platform, or circumvent or
                        attempt to circumvent or copy any copy protection mechanism or territorial restrictions or
                        access any rights management information pertaining to Content other than your Content.
                    </p>
                    <p>
                        (viii) You must not use the Platform to upload, post, store, transmit, display, copy,
                        distribute, promote, make available, continue to make available or otherwise communicate to the
                        public:
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                any Content that (a) breaches our Community Guidelines; (b) includes hate speech on the
                                grounds of race, ethnicity, cultural identity, religious belief, disability, gender,
                                identity or sexual orientation; or (c) includes terrorist or extremist content,
                                violence, abuse, self-harm or harassment, nudity or pornography, child sexual abuse or
                                grooming material;
                            </p>
                        </li>
                        <li>
                            <p>
                                any information, Content or other material that violates, plagiarizes, misappropriates
                                or infringes the rights of third parties including, without limitation, copyright,
                                trademark rights, rights of privacy or publicity, personal rights, confidential
                                information or any other right; or
                            </p>
                        </li>
                        <li>
                            <p>
                                any Content that violates, breaches or is contrary to any law, rule, regulation, court
                                order or is otherwise is illegal or unlawful in Oxchord’s reasonable opinion;
                            </p>
                        </li>
                        <li>
                            <p>
                                any material of any kind that contains any virus, Trojan horse, spyware, adware,
                                malware, bot, time bomb, worm, or other harmful or malicious component, which would or
                                might overburden, impair or disrupt the Platform or servers or networks forming part of,
                                or connected to, the Platform, or which does or might restrict or inhibit any other
                                user's use and enjoyment of the Platform; or
                            </p>
                        </li>
                        <li>
                            <p>
                                any unsolicited or unauthorized advertising, promotional messages, spam or any other
                                form of solicitation.
                            </p>
                        </li>
                    </ul>
                    <p>
                        (ix) You must not commit or engage in, or encourage, induce, solicit or promote, any conduct
                        that would constitute a criminal offense, give rise to civil liability or otherwise violate any
                        law or regulation.
                    </p>
                    <p>
                        (x) You must not rent, sell or lease access to the Platform, or any Content on the Platform,
                        although this shall not prevent you from including links from your Content to any legitimate
                        online download store from where any item of your Content may be purchased.
                    </p>
                    <p>
                        (xi) You must not deliberately impersonate any person or entity or otherwise misrepresent your
                        affiliation with a person or entity, for example, by registering an account in the name of
                        another person or company, or sending messages or making comments using the name of another
                        person or using the output of generative artificial intelligence to impersonate voices or
                        likenesses without authorization.
                    </p>
                    <p>
                        (xii) You must not stalk, exploit, threaten, abuse or otherwise harass another user, or any
                        Oxchord employee.
                    </p>
                    <p>
                        (xiii) You must not use or attempt to use another person's account, password, or other
                        information, unless you have express permission from that other person.
                    </p>
                    <p>
                        (xiv) You must not sell or transfer, or offer to sell or transfer, any Oxchord account to any
                        third party without the prior written approval of Oxchord.
                    </p>
                    <p>
                        (xv) You must not collect or attempt to collect personal data, or any other kind of information
                        about other users, including without limitation, through spidering or any form of scraping.
                    </p>
                    <p>
                        (xvi) You must not violate, circumvent or attempt to violate or circumvent any data security
                        measures employed by Oxchord or any Uploader; access or attempt to access data or materials
                        which are not intended for your use; log into, or attempt to log into, a server or account which
                        you are not authorized to access; attempt to scan or test the vulnerability of Oxchord’s
                        servers, system or network or attempt to breach Oxchord’s data security or authentication
                        procedures; attempt to interfere with the Website or the Services by any means including,
                        without limitation, hacking Oxchord’s servers or systems, submitting a virus, overloading,
                        mail-bombing or crashing. Without limitation to any other rights or remedies of Oxchord under
                        these Terms of Use, Oxchord reserves the right to investigate any situation that appears to
                        involve any of the above, and may report such matters to, and cooperate with, appropriate law
                        enforcement authorities in prosecuting any users who have participated in any such violations.
                    </p>
                    <p>
                        (xvii) You must not create any accounts or upload any Content with the purpose to impersonate a
                        person or create the impression that the account or Content originates from anyone other than
                        the user associated with the account, including using the output of generative artificial
                        intelligence to impersonate a person (including a person’s voice and likeness) without
                        authorization.
                    </p>
                    <p>
                        (xviii) You must not create an account with the sole purpose of commercializing any actions
                        taken with that account (including but not limited to plays, likes, followers)
                    </p>
                    <p>
                        You agree to comply with the above conditions at any and all times during your use of the
                        Platform, and acknowledge and agree that Oxchord has the right, in its sole discretion, to
                        terminate your account or take such other action as we see fit if you breach any of the above
                        conditions or any of the other terms of these Terms of Use. This may include taking court action
                        and/or reporting offending users to the relevant authorities.
                    </p>
                    <h3>Your content</h3>
                    <p>
                        Any and all audio, text, photos, pictures, graphics, comments, and other content, data or
                        information that you upload, store, transmit, submit, exchange or make available to or via the
                        Platform (hereinafter&nbsp;<strong>"Your Content"</strong>) is generated, owned and controlled
                        solely by you, and not by Oxchord.
                    </p>
                    <p>
                        Oxchord does not claim any ownership rights in Your Content, and you hereby expressly
                        acknowledge and agree that Your Content remains your sole responsibility.
                    </p>
                    <p>
                        Without prejudice to the conditions set forth in&nbsp;Your Use of the Platform&nbsp;you must not
                        upload, store, distribute, send, transmit, display, perform, make available, continue to make
                        available or otherwise communicate to the public any Content to which you do not hold the
                        necessary rights. In particular,&nbsp;
                        <strong>
                            any unauthorized use of copyright protected works within Your Content (including by way of
                            reproduction, distribution, modification, adaptation, public display, public performance,
                            preparation of derivative works, making available or otherwise communicating to the public
                            via the Platform), independent of whether it is or becomes unauthorized at a later point,
                            may constitute an infringement of third party rights and is&nbsp;
                            <em>strictly prohibited</em>
                        </strong>
                        . Any such infringements may result in termination of your access to the Platform as described
                        in the&nbsp; Repeat Infringers &nbsp;section below, and may also result in civil litigation or
                        criminal prosecution by or on behalf of the relevant rightsholder.
                    </p>
                    <p>
                        Not all unauthorized uses of copyright protected works constitute an infringement. Users in the
                        European Union can use copyright protected works under certain exceptions or limitations to
                        copyright, namely quotation, criticism, review, use for the purpose of caricature, parody or
                        pastiche.
                    </p>
                    <p>
                        We may, from time to time, invite or provide you with means to provide feedback regarding the
                        Platform, and in such circumstances, any feedback you provide will be deemed non-confidential
                        and Oxchord shall have the right, but not the obligation, to use such feedback on an
                        unrestricted basis.
                    </p>
                    <h3>Blocking and removal of content</h3>
                    <p>
                        Notwithstanding the fact that Oxchord has no legal obligation to monitor the content on the
                        Platform, Oxchord reserves the right to block, remove or delete any content at any time, and to
                        limit or restrict access to any content, for any reason and without liability, including without
                        limitation, if we have reason to believe that such content does or might infringe the rights of
                        any third party, has been uploaded or posted in breach of these Terms of Use, our&nbsp;Community
                        Guidelines&nbsp;or applicable law, or is otherwise unacceptable to Oxchord.
                    </p>
                    <p>
                        Please also note that individual Uploaders have control over the audio content that they store
                        in their account from time to time, and may remove any or all audio content or other content
                        without notice. You have no right of continued access to any particular item of content and
                        Oxchord shall have no liability in the event that you are unable to access an item of content
                        due to its removal from the Platform, whether by Oxchord or the relevant uploader.
                    </p>
                    <h3>Severability</h3>
                    <p>
                        Should one or more provisions of these Terms of Use be found to be unlawful, void or
                        unenforceable, such provision(s) shall be deemed severable and will not affect the validity
                        and/or enforceability of the remaining provisions of the Terms of Use, which will remain in full
                        force and effect.
                    </p>
                    <h3>Community Guidelines</h3>
                    <p>
                        The examples in the below sections are for illustrative purposes and are not exhaustive. Oxchord
                        reserves the right to remove any content deemed to violate our Terms of Use at our discretion.
                    </p>
                    <h3>Copyright</h3>
                    <p>
                        We have always set out to build a diverse, collaborative community where the rights of creators
                        are respected.
                    </p>
                    <p>
                        You are responsible for all activity associated with your account, including all content that
                        you post. If you post content that belongs to someone else (for example, if you are a
                        distributor, promotional agency, manager, or radio plugger acting on behalf of an artist or
                        label), or if you use someone else’s content in a remix or other similar work, it is your
                        responsibility to ensure that you have the permission of all relevant rightsholders in order to
                        post that content to Oxchord. Anyone uploading another person’s content without the necessary
                        permissions is liable to have that content removed and/or their account terminated.
                    </p>
                    <p>
                        When reporting content for infringement, we consider reports abusive if you are not the
                        rightsholder or an authorized agent thereof (like the rightsholder’s attorney or manager).
                        Abusive reporting may result in your civil liability for damages incurred by Oxchord (including
                        costs and legal fees), the person whose content you report, or any copyright owner or authorized
                        licensee as a result of content being removed. Additionally, you risk having your account frozen
                        or, in some cases, terminated if we believe that you have abusively reported content. If you do
                        not own the copyright in the content, please do not report it for infringement.
                    </p>
                    <p>
                        If you are unsure whether you can upload something, read our copyright information pages. If you feel
                        that someone is infringing your copyright tell us about it.
                    </p>
                    <h3>Enforcement guidelines</h3>
                    <p>
                        Oxchord gives people from all over the world the power to share and connect through music and
                        audio and is the only global community where you can connect one on one with fellow fans, the
                        biggest names in music and emerging artists. While engaging with the community can be rewarding,
                        it can also be a source of frustration and misunderstanding if the context of the content is not
                        considered or understood or users don’t communicate and interact in a respectful way.
                    </p>
                    <p>
                        As a general rule we’re unable to prescreen or proactively search for many kinds of content, but
                        we aim to be fair in deciding what content is permitted on Oxchord . While complaints and
                        inquiries must be evaluated and addressed on a case-by-case basis, we aim to apply a uniform set
                        of standards to every user. When determining whether to remove content, we ask ourselves a
                        number of questions, such as:
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Is the behavior directed at an individual, group, or protected category of people?</p>
                            <ul data-rte-list="default">
                                <li>
                                    <p>Clearly targeted content will increase likelihood of removal.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Has the report been filed by the target of the abuse or a third party?</p>
                            <ul data-rte-list="default">
                                <li>
                                    <p>In some instances we may not be able to take reports from third parties.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Does the reported user have a history of violating our policies?</p>
                            <ul data-rte-list="default">
                                <li>
                                    <p>
                                        If a user has violated our Terms of Use in the past, this could impact the
                                        decision.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>How severe is the violation?</p>
                            <ul data-rte-list="default">
                                <li>
                                    <p>
                                        The severity will be reflected in the respective action (i.e. warning vs.
                                        suspension).
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Is the content of legitimate public interest?</p>
                            <ul data-rte-list="default">
                                <li>
                                    <p>The purpose of content will be considered.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        When you see content that upsets or shocks you, step back and consider the above in addition to
                        the possible context and intention of the person sharing the content. If you’re unsure of a
                        user’s intentions, feel free to engage with them in a calm and level-headed manner. The comments
                        section on Oxchord provides a unique environment for people to engage in a conversation around
                        the content. Alternatively, you could message the user privately to share any concerns.
                    </p>
                    <p>
                        Of course, it’s always possible to block or ignore a user as a means to no longer see unwanted
                        content on Oxchord, and sometimes this is the best or only way to resolve these issues.
                    </p>
                    <p>
                        Please consider the above and review the relevant section of our Community Guidelines below
                        before reporting content to our Trust and Safety Team. This will both align your expectations
                        with our policy and allow our team to focus on removing content that truly doesn’t have a place
                        on Oxchord.
                    </p>
                    <h3>Criticism</h3>
                    <p>
                        Criticism should always focus on the content, and not on the individual who created it. We do
                        not want criticism of the personal character of the creator, only the criticism of their
                        content. Please share your opinion with others, but do so in a constructive way. Before posting
                        feedback, always ask yourself whether you would appreciate receiving the same feedback. If not,
                        don’t post it.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>“This wasn't my favorite track of yours”</p>
                        </li>
                        <li>
                            <p>“I think different vocals on this track would work better”</p>
                        </li>
                        <li>
                            <p>“I’m pretty disappointed with this track tbh”</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>“You’re a piece of s**t! No one’s ever gonna listen to your garbage!”</p>
                        </li>
                        <li>
                            <p>
                                “I hate you and your voice. You should stop making music. You’re so annoying and we all
                                hate you bro”
                            </p>
                        </li>
                        <li>
                            <p>“What the hell is this!?”</p>
                        </li>
                    </ul>
                    <h3>Abuse</h3>
                    <p>
                        We have zero tolerance for abusive or threatening behavior within the community and on the
                        platform. We consider abuse to be any activity where the intent or likely result is to attack or
                        demean someone else. This includes but is not limited to content aimed at sexualizing or
                        degrading other users. Anyone found to be engaging in this type of activity risks the immediate,
                        permanent termination of their account.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>“I like this track! Thanks for this!”</p>
                        </li>
                        <li>
                            <p>Block a user who you do not wish to engage with.</p>
                        </li>
                        <li>
                            <p>“You’re Goated for this one.”</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>“F**k you”</p>
                        </li>
                        <li>
                            <p>“I’ll find u and hurt u where u at man u don’t deserve to live”</p>
                        </li>
                        <li>
                            <p>“You don’t know what the hell you’re talking about, idiot!”</p>
                        </li>
                    </ul>
                    <h3>Harassment</h3>
                    <p>
                        We define harassment as the unwanted, continued pursuit of contact with an individual who has
                        indicated they do not wish to be in contact with another individual. The difference between
                        abuse and harassment is that harassment is typically conducted over a sustained period of time,
                        whereas abuse may be a one-off event. Therefore, if an individual is sending repeated unwanted
                        messages or comments to another user, they risk the immediate, permanent termination of their
                        account.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Message or comment once: “Hey, you should check out my music, I think you’d like it”</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Repeatedly message or comment: “hey I think you’re beautiful talk to me!”</p>
                        </li>
                        <li>
                            <p>
                                Engage with someone when they’ve told you to stop, or use Oxchord to contact someone
                                when they’ve blocked you on another platform.
                            </p>
                        </li>
                        <li>
                            <p>
                                Create accounts or content with the sole purpose of “exposing” the behavior of a single
                                identifiable individual or group of individuals.
                            </p>
                        </li>
                    </ul>
                    <h3>Impersonation &amp; misleading metadata</h3>
                    <p>
                        As a platform built with creator needs in mind, we welcome the use of stage names or
                        professional identities on Oxchord. However, you cannot impersonate another person or post
                        content that is intentionally misleading.
                    </p>
                    <p>
                        This means that you must not create profiles or post content that is intended to make people
                        think you are someone else. You must not use someone else’s name or trademark without their
                        permission, or pretend to be associated with someone you aren’t, even if you feel that you are
                        helping that person by doing so. This applies to everyone from the most popular creators to
                        casual users on Oxchord.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Use tags to indicate similarities to artists that inspire you.</p>
                        </li>
                        <li>
                            <p>
                                Use your own name as a display name or URL where this is also the name of an existing
                                artist and no ™ infringement is taking place.
                            </p>
                        </li>
                        <li>
                            <p>Use your own original artwork on your profile.</p>
                        </li>
                        <li>
                            <p>
                                Mention artists that inspire you in your profile bio (i.e. “X, Y and Z artist inspire
                                me”)
                            </p>
                        </li>
                        <li>
                            <p>
                                Create fan accounts with the permission or endorsement of the artist in question. These
                                must be clearly distinguished from official profiles.
                            </p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                Upload tracks with an artist’s name in the title who is not officially associated with
                                it.
                            </p>
                        </li>
                        <li>
                            <p>
                                Use another artist’s name or variation of an artist’s name as your display name, URL or
                                Track URL.
                            </p>
                        </li>
                        <li>
                            <p>
                                Use an image of an artist or images associated with an artist (album covers etc.) as an
                                avatar, banner, track or playlist image where the artist is not officially associated
                                with the account or track.
                            </p>
                        </li>
                        <li>
                            <p>List artist names in your bio as a means of attempting to game our search.</p>
                        </li>
                        <li>
                            <p>Create fan accounts without the permission of the artist in question.</p>
                        </li>
                    </ul>
                    <h3>Pornographic Content</h3>
                    <p>
                        We do not allow any content that is predominantly sexual in nature or that could be perceived as
                        an aid to sexual gratification. This includes, but is not limited to, images containing nudity
                        and recordings or detailed descriptions of sexual acts, or links to pornographic sites.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Post ASMR of your dog eating treats, or you brushing your hair.</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Post noises directly associated with sexual acts disguised as ASMR.</p>
                        </li>
                        <li>
                            <p>Link to any pornographic content in your profile bio.</p>
                        </li>
                    </ul>
                    <h3>Violence and threatening behavior</h3>
                    <p>
                        We do not allow any use of intimidation or threats of violence between our users, against the
                        wider public or against Oxchord employees. Likewise, we don’t permit any incitement to, or
                        glorification of, violent acts either against an individual or the wider public. Additionally,
                        the use of excessive gore in regards to both imagery or language is not permitted.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                Provide commentary on violence in regards to a specific event (i.e. something that
                                happened to you in the context of a podcast episode).
                            </p>
                        </li>
                        <li>
                            <p>Refer to violence where this serves an educational purpose.</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                Upload content that has the intention to intimidate an individual into fearing a
                                physical attack against their person.
                            </p>
                        </li>
                        <li>
                            <p>
                                Upload content inciting violence (i.e. calling on or encouraging others to complete
                                violent acts against an individual or against the wider public).
                            </p>
                        </li>
                        <li>
                            <p>
                                Upload graphic images of real or dramatized violence with the intent to glorify violent
                                acts or to shock/disgust.
                            </p>
                        </li>
                    </ul>
                    <h3>Hate speech</h3>
                    <p>
                        Oxchord allows for the presentation of individual beliefs and opinions. We do not, however,
                        allow content that promotes or encourages hatred, discrimination or violence against others
                        based on race, cultural identity or ethnic background, religious beliefs, disability, gender
                        identity, or sexual orientation. We will use our discretion when necessary to determine if
                        beliefs or opinions incite hatred.
                    </p>
                    <p>
                        Please keep in mind that applicable laws state that Nazi and ISIS content is illegal, and will
                        be removed without discussion, unless clearly used for educational or journalistic purposes.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>
                                Speak about a hate group in a purely educational manner, making it clear when you are
                                quoting directly and not presenting them as your own views.
                            </p>
                        </li>
                        <li>
                            <p>
                                Specifically state that you do not endorse the groups/events/etc. that you may be
                                quoting.
                            </p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>“I mean, these [protected group], they know what’s wrong with them”</p>
                        </li>
                        <li>
                            <p>“[protected group] are threatening us. We need to get them out”</p>
                        </li>
                    </ul>
                    <h3>Misinformation</h3>
                    <p>
                        As a platform that promotes freedom of speech and connects millions of people, we feel we have a
                        responsibility to take a clear stance on the issue of misinformation. Although not a new
                        concept, the digital age has made it easier than ever to spread information almost anywhere in
                        the world.
                    </p>
                    <p>
                        We understand that in today’s day and age many people rely on the internet and social platforms
                        as their main news source. Therefore, in order to maintain the trust and safety of our
                        community, the use of false, fabricated or manipulated information is something we do not
                        condone, especially when presented as fact and when posing a potential threat or allowing for
                        long term harm to the wellbeing of both our user base and society at large. This type of
                        information can take many forms, but is often uploaded with the aim of affecting political
                        discourse.
                    </p>
                    <p>
                        Furthermore, we feel we must be explicit in stating we do not under any circumstances allow the
                        posting or sharing of harmful ideologies that misinform the public, such as those that deny the
                        occurrence of violent events, or those that target an individual or protected group by inciting
                        fear, hate or prejudice.
                    </p>
                    <p>
                        Any user whose content may be deemed in violation of our Terms of Use may be subject to the
                        immediate, permanent termination of their account. We encourage you to ensure the accuracy of
                        your content, as each user is responsible for what they upload.
                    </p>
                    <p>
                        With that all being said, we do encourage you to offer and discuss your opinion with others
                        should it be very clearly presented as such and kept in line with the rest of our Community
                        Guidelines.
                    </p>
                    <p>
                        <strong>Do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Use imaginative subjects to produce satire, comedy and entertainment content.</p>
                        </li>
                        <li>
                            <p>State or talk about your opinion as just that, opinion.</p>
                        </li>
                    </ul>
                    <p>
                        <strong>Don't do this:</strong>
                    </p>
                    <ul data-rte-list="default">
                        <li>
                            <p>Use of clickbait titles, profile names and description.</p>
                        </li>
                        <li>
                            <p>Fabricate content that deliberately presents false or misleading claims as news.</p>
                        </li>
                        <li>
                            <p>Refer to violent events as “false flags”.</p>
                        </li>
                        <li>
                            <p>Post or share conspiracy theories like denying the Holocaust.</p>
                        </li>
                    </ul>
                </TermsArea>
                {features.isWaiting ? (
                    <LoadingHolder>
                        <LoadingSpinner />
                    </LoadingHolder>
                ) : (
                    <Button type="primary" onClick={features.acceptTerms}>
                        Accept
                    </Button>
                )}
            </ContentArea>
        </OxPage>
    );
};

export default TermsAndConditionsPage;
