import { IonIcon } from '@ionic/react';
import { FloatingActionButtonLink } from '../../components/basic/basic.styled';
import LoadingSpinner from '../../components/basic/loading-spinner.component';
import FeedControlBar from '../../components/feeds/feed-control-bar.component';
import Feed from '../../components/feeds/feed.component';
import OxPage from '../../components/oxpage.component';
import useFeed from '../../hooks/use-feed';
import { CenterContentArea } from '../post/post.styled';
import { add } from 'ionicons/icons';
import AutoplayButton from '../../components/feeds/autoplay-button.component';

const HomeFeedPage = () => {
    const feedBundle = useFeed();

    return (
        <OxPage>
            <FeedControlBar
                options={feedBundle.feedOptions}
                optionsChanged={feedBundle.optionsChanged}
                setOptions={feedBundle.setFeedOptions}
                reloadFeed={feedBundle.reload}
                toggleFavorite={feedBundle.toggleFavorite}
            />
            {feedBundle.loading ? (
                <CenterContentArea>
                    <LoadingSpinner />
                </CenterContentArea>
            ) : (
                <Feed
                    posts={feedBundle.feed ?? []}
                    firstLoad={feedBundle.firstLoad}
                    onSwipe={feedBundle.onViewPost}
                    moreLoading={feedBundle.moreLoading}
                />
            )}
            <FloatingActionButtonLink to="/post/songselect">
                <IonIcon icon={add} />
            </FloatingActionButtonLink>
            <AutoplayButton />
        </OxPage>
    );
};

export default HomeFeedPage;
