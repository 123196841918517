import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import LinkCollection from '../../components/basic/link-collection.component';
import { FlexContainer } from '../../components/components.styled';
import OxPage from '../../components/oxpage.component';
import { Impact, Comment, Body, SmallBody } from '../../components/typography.styled';
import ProfilePic from '../../components/user/profile-pic.component';
import useUser from '../../hooks/use-user';
import UserViewTab from './components/user-view-tab.component';
import {
    BackgroundFadeArea,
    FollowersLink,
    MainArea,
    MainAreaBase,
    MainAreaContainer,
    PageAreaContent,
    UserTabAreaBase,
    UserTabHolder,
    UserBackgroundImageArea,
    UserNameArea,
    UserTabArea,
    UserActionsIcon,
    UserActionButton,
    EditProfileButton,
    ProfilePicContainerArea,
    UserSettingsArea,
    MoreOptionsArea,
    MoreOptionsOption,
    BlockAlert,
    DeleteConfirm,
} from './user.styled';
import LoadingSkeleton from '../../components/basic/loading-skeleton.component';
import SongsTabArea from './components/songs-tab-area.component';
import PostsTabArea from './components/posts-tab-area.component';
import FollowerDrawer from './components/follower-drawer.component';
import { add, chatbox, close, ellipsisHorizontal, pencil, remove, settingsOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import useLocations from '../../hooks/use-locations';
import Button from '../../components/basic/button.component';
import useCurrentUser from '../../hooks/use-current-user';
import useCommonAction from '../../hooks/use-common-action';
import Modal from '../../components/basic/modal.component';
import useNotifications from '../../hooks/use-notifications';
import { ToastSeverity } from '../../types/notification/toast-severity';

interface UserPageRouteParams {
    userId?: string;
}
const UserPage = () => {
    const { userId } = useParams<UserPageRouteParams>();
    const { user, posts, likes, songs } = useUser((userId ?? -1) as number);
    const [tabOn, setTabOn] = useState(0);
    const [showSettings, setShowSettings] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showBlockInfo, setShowBlockInfo] = useState(false);
    const [showDeleteInfo, setShowDeleteInfo] = useState(false);
    const [deleteClickCount, setDeleteClickCount] = useState(0);
    const [followerDrawerState, setFollowerDrawerState] = useState<'hide' | 'followers' | 'following'>('hide');
    const currentUser = useCurrentUser();
    const { getLocation } = useLocations();
    const common = useCommonAction();
    const notifications = useNotifications();

    const confirmBlock = () => {
        if (!user.data) return;
        currentUser.toggleBlocking(user.data.id);
        setShowBlockInfo(false);
        setShowMoreOptions(false);
        const unblock = currentUser.isBlockingUser(user.data.id);
        notifications.addToast({
            severity: ToastSeverity.Info,
            title: `${unblock ? 'Unblocked User' : 'Blocked User'}`,
            body: `${user.data.displayName} has been ${unblock ? 'unblocked' : 'blocked'}`,
        });
    };
    const confirmDelete = () => {
        if (deleteClickCount < 2) {
            setDeleteClickCount(deleteClickCount + 1);
        } else {
            currentUser.deleteAccount();
            currentUser.logOut();
        }
    };

    const isLoggedInUser = currentUser.userId === user.data?.id;

    const ref = useRef<HTMLDivElement>(null);
    const onScroll = (scrollTop: number) => {
        if (!ref?.current) return;
        ref.current.style.top = scrollTop / 2 + 'px';
    };
    return (
        <OxPage>
            {currentUser.isBlockingUser(user?.data?.id) ? (
                <BlockAlert>
                    <SmallBody>You are blocking this user.</SmallBody>
                </BlockAlert>
            ) : (
                <></>
            )}
            <Modal
                show={showDeleteInfo}
                onClose={() => {
                    setShowDeleteInfo(false);
                    setDeleteClickCount(0);
                }}
            >
                <FlexContainer direction="column" style={{ color: 'white' }}>
                    <Body margin="0 0 16px 0">Delete Account?</Body>
                    <SmallBody margin="0 0 8px 0">
                        Are you sure you want to delete your account? This action cannot be undone.
                    </SmallBody>
                    <ul style={{ paddingLeft: '16px', marginBottom: '8px' }}>
                        <li>
                            <SmallBody>All of your posts will be deleted.</SmallBody>
                        </li>
                        <li>
                            <SmallBody>All songs you uploaded will be deleted.</SmallBody>
                        </li>
                        <li>
                            <SmallBody>Any other user's posts using songs you uploaded will be deleted.</SmallBody>
                        </li>
                    </ul>
                    <SmallBody margin="0 0 16px 0">
                        If you still wish to delete your account, click the button below 3 times.
                    </SmallBody>
                    <FlexContainer align="center">
                        <Button type="primary" onClick={confirmDelete}>
                            Delete Account
                        </Button>
                        <DeleteConfirm $checked={deleteClickCount >= 1} style={{ marginLeft: '16px' }}>
                            <IonIcon icon={close} />
                        </DeleteConfirm>
                        <DeleteConfirm $checked={deleteClickCount >= 2}>
                            <IonIcon icon={close} />
                        </DeleteConfirm>
                        <DeleteConfirm $checked={deleteClickCount >= 3}>
                            <IonIcon icon={close} />
                        </DeleteConfirm>
                    </FlexContainer>
                </FlexContainer>
            </Modal>
            <Modal show={showBlockInfo} onClose={() => setShowBlockInfo(false)}>
                {currentUser.isBlockingUser(user?.data?.id) ? (
                    <FlexContainer direction="column" style={{ color: 'white' }}>
                        <Body margin="0 0 16px 0">Unblock {user.data?.displayName}?</Body>
                        <SmallBody margin="0 0 8px 0">Are you sure you want to unblock this user?</SmallBody>
                        <ul style={{ paddingLeft: '16px', marginBottom: '16px' }}>
                            <li>
                                <SmallBody>
                                    This user will now be able to view your posts and activity in the app.
                                </SmallBody>
                            </li>
                        </ul>
                        <Button type="primary" onClick={confirmBlock}>
                            Unblock User
                        </Button>
                    </FlexContainer>
                ) : (
                    <FlexContainer direction="column" style={{ color: 'white' }}>
                        <Body margin="0 0 16px 0">Block {user.data?.displayName}?</Body>
                        <SmallBody margin="0 0 8px 0">Are you sure you want to block this user?</SmallBody>
                        <ul style={{ paddingLeft: '16px', marginBottom: '16px' }}>
                            <li>
                                <SmallBody>This user will not be notified that they have been blocked.</SmallBody>
                            </li>
                            <li>
                                <SmallBody>
                                    Their posts will not appear in your feed and your posts will not appear in theirs.
                                </SmallBody>
                            </li>
                            <li>
                                <SmallBody>They will be unable to view your profile.</SmallBody>
                            </li>
                            <li>
                                <SmallBody>
                                    You will be unable to send messages to them. Any message they send will not be
                                    delivered to you.
                                </SmallBody>
                            </li>
                            <li>
                                <SmallBody>They will be unable to comment on your posts.</SmallBody>
                            </li>
                        </ul>
                        <Button type="primary" onClick={confirmBlock}>
                            Block User
                        </Button>
                    </FlexContainer>
                )}
            </Modal>
            {isLoggedInUser ? (
                <>
                    {/* <UserActionButton to="/post/songselect">
                        <UserActionsIcon icon={addOutline} />
                    </UserActionButton> */}
                    <UserActionButton $right onClick={() => setShowSettings(!showSettings)}>
                        <UserActionsIcon icon={showSettings ? close : settingsOutline} />
                    </UserActionButton>
                    {showSettings && (
                        <UserSettingsArea>
                            <MoreOptionsOption onClick={() => currentUser.logOut()}>Log Out</MoreOptionsOption>
                            <MoreOptionsOption onClick={() => setShowDeleteInfo(true)}>
                                Delete Account
                            </MoreOptionsOption>
                        </UserSettingsArea>
                    )}
                </>
            ) : (
                <></>
            )}
            <FollowerDrawer
                show={followerDrawerState !== 'hide'}
                onClose={() => setFollowerDrawerState('hide')}
                title={`${user.data?.displayName ?? 'This user'} ${
                    followerDrawerState === 'following' ? 'follows...' : 'is followed by...'
                }`}
                followers={(followerDrawerState === 'following' ? user?.data?.following : user?.data?.followers) ?? []}
            />
            <PageAreaContent onScroll={(e) => onScroll((e.target as HTMLDivElement).scrollTop)}>
                <UserBackgroundImageArea image={user.data?.backgroundImage ?? null} ref={ref} />
                <MainAreaContainer>
                    <BackgroundFadeArea />
                    <MainAreaBase>
                        <MainArea>
                            <ProfilePicContainerArea>
                                {isLoggedInUser && (
                                    <EditProfileButton to={`/user/${userId}/edit`}>
                                        <IonIcon icon={pencil} />
                                    </EditProfileButton>
                                )}
                                <ProfilePic user={user.data} size="large" />
                            </ProfilePicContainerArea>
                            <FlexContainer direction="row" width="100%" justify="center">
                                <FollowersLink onClick={() => setFollowerDrawerState('followers')}>
                                    <LoadingSkeleton loading={!user.data} height="32px">
                                        <Impact>{user.data?.followers?.length ?? 0}</Impact>
                                    </LoadingSkeleton>
                                    <Comment align="center">followers</Comment>
                                </FollowersLink>
                                <UserNameArea>
                                    <LoadingSkeleton loading={!user.data} height="20px">
                                        <Body align="center">{user.data?.displayName}</Body>
                                    </LoadingSkeleton>
                                    <LoadingSkeleton loading={!user.data} height="16px">
                                        <Comment align="center">@{user.data?.username}</Comment>
                                    </LoadingSkeleton>
                                    {(user.data?.title || user.data?.homeBaseId) && (
                                        <SmallBody align="center">
                                            {user.data?.title}
                                            {user.data?.title && user.data?.homeBaseId ? ' in ' : ''}
                                            {user.data?.homeBaseId ? getLocation(user.data?.homeBaseId)?.name : ''}
                                        </SmallBody>
                                    )}
                                </UserNameArea>
                                <FollowersLink onClick={() => setFollowerDrawerState('following')}>
                                    <LoadingSkeleton loading={!user.data} height="32px">
                                        <Impact>{user.data?.following?.length ?? 0}</Impact>
                                    </LoadingSkeleton>
                                    <Comment align="center">following</Comment>
                                </FollowersLink>
                            </FlexContainer>
                            <LoadingSkeleton loading={!user.data} height="16px" count={4}>
                                {!isLoggedInUser && (
                                    <FlexContainer align="center" justify="center" style={{ position: 'relative' }}>
                                        <Button
                                            type="small"
                                            onClick={() => currentUser.toggleFollowing(user.data?.id ?? -1)}
                                        >
                                            <IonIcon
                                                style={{ marginRight: '6px' }}
                                                icon={currentUser.isFollowingUser(user.data?.id ?? -1) ? remove : add}
                                            />
                                            {currentUser.isFollowingUser(user.data?.id ?? -1) ? 'Unfollow' : 'Follow'}
                                        </Button>
                                        <Button type="small" to={`/chat/${userId}`}>
                                            <IonIcon style={{ marginRight: '6px' }} icon={chatbox} />
                                            Message
                                        </Button>
                                        <Button type="small" onClick={() => setShowMoreOptions(!showMoreOptions)}>
                                            <IonIcon icon={showMoreOptions ? close : ellipsisHorizontal} />
                                        </Button>
                                        {showMoreOptions && (
                                            <MoreOptionsArea>
                                                <MoreOptionsOption onClick={() => common.setReportUser(user.data)}>
                                                    Report User
                                                </MoreOptionsOption>
                                                <MoreOptionsOption onClick={() => setShowBlockInfo(!showBlockInfo)}>
                                                    {currentUser.isBlockingUser(user?.data?.id)
                                                        ? 'Unblock User'
                                                        : 'Block User'}
                                                </MoreOptionsOption>
                                            </MoreOptionsArea>
                                        )}
                                    </FlexContainer>
                                )}
                                <SmallBody align="center" maxWidth="500px" padding="16px">
                                    {user.data?.profileMessage}
                                </SmallBody>
                            </LoadingSkeleton>
                            <LinkCollection links={user.data?.links ?? []} />
                        </MainArea>
                    </MainAreaBase>
                </MainAreaContainer>
                <UserTabHolder>
                    <UserViewTab
                        title="posts"
                        count={posts.data?.length ?? null}
                        tab={0}
                        tabOn={tabOn}
                        onClick={() => setTabOn(0)}
                    />
                    <UserViewTab
                        title="songs"
                        count={songs.data?.length ?? null}
                        tab={1}
                        tabOn={tabOn}
                        onClick={() => setTabOn(1)}
                    />
                    <UserViewTab
                        title="likes"
                        count={likes.data?.length ?? null}
                        tab={2}
                        tabOn={tabOn}
                        onClick={() => setTabOn(2)}
                    />
                </UserTabHolder>
                <UserTabAreaBase>
                    <UserTabArea>
                        {
                            [
                                <PostsTabArea posts={posts} />,
                                <SongsTabArea songs={songs} />,
                                <PostsTabArea posts={likes} showUser />,
                            ][tabOn]
                        }
                    </UserTabArea>
                </UserTabAreaBase>
            </PageAreaContent>
        </OxPage>
    );
};

export default UserPage;
