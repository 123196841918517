export enum NotificationType {
    DirectMessage = 1,
    Mentioned = 2,
    Repost = 3,
    SongUsed = 4,
    SongCredit = 5,
    PostLiked = 6,
    PostCommented = 7,
    Follow = 8,
    Admin = 9,
}
