import { useState } from "react";
import { FlexContainer } from "../components.styled";
import { LinkInputItem, LinkInputListArea, LinkXButton, NoLinksCommentHolder } from "./form.styled";
import SmallTextInput from "./small-text-input.component";
import Button from "../basic/button.component";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { Comment } from "../typography.styled";
import { getAsValidLink, getLinkIcon } from "../../utils/link.util";


interface LinkInputProps {
    links: string[];
    setLinks: (l: string[]) => void;
};
const LinkInput = ({ links, setLinks }: LinkInputProps) => {
    const [ link, setLink ] = useState("");

    const addLink = () => {
        const valid = getAsValidLink(link);
        if (!valid) {
            return;
        }
        setLinks([...links, valid]);
        setLink("");
    };
    const removeLink = (i: number) => {
        setLinks(links.filter((_,ind) => ind !== i));
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            addLink();
        }
    };

    return (
        <FlexContainer height="auto" direction="column">
            <FlexContainer direction="row" align="center">
                <SmallTextInput id="link" label="URL" value={link} setValue={setLink} onKeyDown={handleKeyDown}/>
                <Button type="small" disabled={link === ""} onClick={addLink}>Add</Button>
            </FlexContainer>
            <LinkInputListArea>
                {links.length === 0 && <NoLinksCommentHolder><Comment $noWidth100>- No Links -</Comment></NoLinksCommentHolder>}
                {links.map((l, i) =>
                    <LinkInputItem key={i}>
                        <IonIcon src={getLinkIcon(l)}/>
                        <a href={l}>{l}</a>
                        <LinkXButton onClick={() => removeLink(i)}>
                            <IonIcon icon={closeOutline}/>
                        </LinkXButton>
                    </LinkInputItem>
                )}
            </LinkInputListArea>
        </FlexContainer>
    );
};

export default LinkInput;