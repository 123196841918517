import { AuthBundle } from '../context/auth.context';
import { useEffect, useState } from 'react';
import { post } from '../services/api';
import { getLocal, setLocal } from '../services/local-storage-service';
import { Auth, AuthStatus, SignInRequest, RegisterRequest } from '../types';

const AUTH_STORE_KEY = 'auth';

const useAuthControl = (): AuthBundle => {
    const [isWaitingOnRefresh, setIsWaitingOnRefresh] = useState(true);
    const [auth, setAuth] = useState<Auth | null>(null);
    const [betaCodeState, setBetaCodeState] = useState<AuthStatus | null>(null);
    const [attemptedLogin, setAttemptedLogin] = useState<SignInRequest | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const refresh = () => {
        if (auth?.refreshToken) {
            setIsLoading(true);
            setErrorMessage(null);
            post<Auth>('/auth/refresh', { refreshToken: auth?.refreshToken })
                .then((res) => {
                    if (res.hadError) setErrorMessage(res.errorMessage ?? 'Something went wrong.');
                    else {
                        setAuth(res.data);
                    }
                })
                .catch((err) => setErrorMessage('Something went wrong.'))
                .finally(() => setIsLoading(false));
        }
    };

    const refreshFromLocal = () => {
        getLocal<Auth>(AUTH_STORE_KEY).then((a) => {
            if (a?.refreshToken) {
                post<Auth>('/auth/refresh', { refreshToken: a?.refreshToken })
                    .then((res) => {
                        if (res.hadError) setErrorMessage(res.errorMessage ?? 'Something went wrong.');
                        else {
                            setAuth(res.data);
                        }
                    })
                    .catch((err) => console.log('refresh failed. must log in again.', err))
                    .finally(() => setIsWaitingOnRefresh(false));
            } else {
                setIsWaitingOnRefresh(false);
            }
        });
    };

    useEffect(() => {
        refreshFromLocal();
    }, []);

    useEffect(() => {
        if ((auth?.authStatus === AuthStatus.LoggedIn || auth?.authStatus === AuthStatus.NeedsDetails) && auth) {
            setLocal(AUTH_STORE_KEY, auth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.idToken]);

    const tryBetaCode = (code: string) => {
        setIsLoading(true);
        setErrorMessage(null);
        post<{ isValid: boolean; message: string }>('/auth/validate_beta_code', { betaCode: code })
            .then((res) => {
                if (res.hadError || !res.data?.isValid) {
                    setBetaCodeState(AuthStatus.BadBetaCode);
                    setErrorMessage(res.errorMessage ?? 'Something went wrong.');
                } else {
                    setBetaCodeState(AuthStatus.BetaCodeValidated);
                }
            })
            .catch((err) => {
                setBetaCodeState(AuthStatus.BadBetaCode);
                setErrorMessage('Something went wrong.');
            })
            .finally(() => setIsLoading(false));
    };

    const login = (req: SignInRequest) => {
        setIsLoading(true);
        setErrorMessage(null);
        setAttemptedLogin(req);
        post<Auth>('/auth/login', req)
            .then((res) => {
                if (res.hadError) setErrorMessage(res.errorMessage ?? 'Something went wrong.');
                else {
                    setAuth(res.data);
                }
            })
            .catch((err) => setErrorMessage('Something went wrong.'))
            .finally(() => setIsLoading(false));
    };

    const register = (req: RegisterRequest) => {
        if (req.password !== req.passwordConfirm) {
            setErrorMessage('Password confirmation does not match.');
            return;
        }
        setIsLoading(true);
        setErrorMessage(null);
        setAttemptedLogin({ username: req.username, password: req.password });
        post<Auth>('/auth/register', req)
            .then((res) => {
                if (res.hadError) setErrorMessage(res.errorMessage ?? 'Something went wrong.');
                else {
                    setAuth(res.data);
                }
            })
            .catch((err) => setErrorMessage('Something went wrong.'))
            .finally(() => setIsLoading(false));
    };

    const tryVerification = () => {
        if (attemptedLogin !== null) login(attemptedLogin);
    };

    const resendVerification = () => {
        if (attemptedLogin !== null) post('/auth/resend_verification', attemptedLogin);
    };

    const logout = () => {
        setLocal(AUTH_STORE_KEY, null);
        refreshFromLocal();
        setAuth(null);
    };

    return {
        auth,
        userId: auth?.userId,
        state: auth?.authStatus ?? betaCodeState ?? AuthStatus.LoggedOut,
        errorMessage,
        isLoading,
        isWaitingOnRefresh,
        tryBetaCode,
        login,
        tryVerification,
        resendVerification,
        register,
        logout,
        refresh,
    };
};

export default useAuthControl;
