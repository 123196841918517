import { ReactNode, createContext } from 'react';
import { FavoriteFeed, User } from '../types';
import useCurrentUserContext from '../hooks/context/use-current-user-context';

export interface CurrentUserContextType {
    userId?: number;
    user?: User;
    isLoading: boolean;
    reload: () => void;
    updateUser: (u: User) => void;
    setFavoriteFeeds: (f: FavoriteFeed[]) => void;
    isFollowingUser: (id?: number) => boolean;
    toggleFollowing: (id: number) => void;
    isBlockingUser: (id?: number) => boolean;
    toggleBlocking: (id: number) => void;
    logOut: () => void;
    deleteAccount: () => void;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
    isLoading: false,
    reload: () => {},
    updateUser: (u) => {},
    setFavoriteFeeds: (f) => {},
    isFollowingUser: (id) => false,
    toggleFollowing: (id) => {},
    isBlockingUser: (id) => false,
    toggleBlocking: (id) => {},
    logOut: () => {},
    deleteAccount: () => {},
});

const CurrentUserContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useCurrentUserContext();
    return <CurrentUserContext.Provider value={store}>{children}</CurrentUserContext.Provider>;
};

export default CurrentUserContextProvider;
