import { SwiperSlide } from 'swiper/react';
import {
    CarouselPaginationButton,
    CarouselPaginationButtonInner,
    CarouselPaginationHolder,
    PostImageCarouselHolder,
    PostImageCarouselImage,
    PostImageCarouselSwiper,
} from './feed.styled';
import { useState } from 'react';
import Swiper from 'swiper';

interface PostImageCarouselProps {
    images: string[];
    size?: string;
}
const PostImageCarousel = ({ images, size = '300px' }: PostImageCarouselProps) => {
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [slideOn, setSlideOn] = useState(0);

    return (
        <>
            <PostImageCarouselHolder className="carousel" height={size}>
                <PostImageCarouselSwiper onSwiper={setSwiper} onSlideChange={(s) => setSlideOn(s.activeIndex)}>
                    {images.map((i, index) => (
                        <SwiperSlide key={index}>
                            <PostImageCarouselImage image={i} />
                        </SwiperSlide>
                    ))}
                </PostImageCarouselSwiper>
            </PostImageCarouselHolder>
            {images.length > 1 && (
                <CarouselPaginationHolder>
                    {images.map((_, index) => (
                        <CarouselPaginationButton key={index} onClick={() => swiper?.slideTo(index)}>
                            <CarouselPaginationButtonInner selected={index === slideOn} />
                        </CarouselPaginationButton>
                    ))}
                </CarouselPaginationHolder>
            )}
        </>
    );
};

export default PostImageCarousel;
