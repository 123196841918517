import { useContext, useEffect, useRef, useState } from 'react';
import { SongContext } from '../context/song.context';

interface useSongPositionBundle {
    pos: number;
    duration: number;
    percent: number;
    seek: (pos: number) => void;
}
const useSongPosition = (): useSongPositionBundle => {
    const frameRef = useRef<number>();
    const [pos, setPos] = useState(0);
    const { audio } = useContext(SongContext);

    useEffect(() => {
        if (!audio) return;
        const animate = () => {
            setPos(audio.getPosition());
            frameRef.current = requestAnimationFrame(animate);
        };

        frameRef.current = window.requestAnimationFrame(animate);

        return () => {
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current);
            }
        };
    }, [audio]);

    const duration = audio?.duration ?? 0;

    const seek = (pos: number) => {
        audio?.seek(pos);
    };

    return {
        pos,
        duration,
        percent: pos && duration ? (pos / duration) * 100 : 0,
        seek,
    };
};

export default useSongPosition;
