import { useEffect, useState } from 'react';
import { Comment, Post, PostStub } from '../types';
import { post } from '../services/api';
import useAuth from './use-auth';
import useCurrentUser from './use-current-user';

export interface MyComment {
    comment: Comment;
    parentId: number | null;
}

export interface PostInteractionBundle {
    reacted: boolean;
    reactedToComment: (id: number) => boolean;
    reactCount: number;
    commentReactCount: (id: number) => number;
    commentCount: number;
    myComments: MyComment[];
    reactToPost: () => void;
    reactToComment: (id: number) => void;
    commentComposingId: number | null;
    setCommentComposingId: (id: number | null) => void;
    comment: string;
    setComment: (comment: string) => void;
    submitComment: () => void;
}

const usePostInteraction = (postStub: PostStub, comments?: Comment[]): PostInteractionBundle => {
    const auth = useAuth();
    const currentUser = useCurrentUser();
    const [reacted, setReacted] = useState(postStub.reaction !== null);
    const [commentComposingId, setCommentComposingId] = useState<number | null>(null);
    const [comment, setComment] = useState('');
    const [myComments, setMyComments] = useState<MyComment[]>([]);
    const [commentReactions, setCommentReactions] = useState<{ id: number; reaction: number }[]>(
        comments
            ? comments
                  .map((c) => c.childComments)
                  .reduce((a, b) => [...a, ...b], comments)
                  .filter((c) => !!c.reactions.find((r) => r.user.id === currentUser.userId))
                  .map((c) => ({
                      id: c.postCommentId,
                      reaction: c.reactions.find((r) => r.user.id === currentUser.userId)?.reactionIndex ?? 0,
                  }))
            : [],
    );

    const reactedToComment = (id: number) => !!commentReactions.find((r) => r.id === id);
    const commentReactCount = (id: number) => {
        const hasExistingReaction = !!comments
            ?.find((c) => c.postCommentId === id)
            ?.reactions.find((r) => r.user.id === currentUser.userId);
        const commentReacted = reactedToComment(id);
        return (
            (comments?.find((c) => c.postCommentId === id)?.reactions?.length ?? 0) -
            (hasExistingReaction ? 1 : 0) +
            (commentReacted ? 1 : 0)
        );
    };

    const reactToPost = () => {
        setReacted(!reacted);
        post<Post>(`/post/${postStub.postId}/react/0`, {}, auth.auth)
            .then((res) => {})
            .catch((e) => console.log(e));
    };
    const reactToComment = (id: number) => {
        if (reactedToComment(id)) {
            setCommentReactions(commentReactions.filter((r) => r.id !== id));
        } else {
            setCommentReactions([{ id, reaction: 0 }, ...commentReactions]);
        }
        post<Post>(`/post/${postStub.postId}/comment/${id}/react/0`, {}, auth.auth)
            .then((res) => {})
            .catch((e) => console.log(e));
    };

    const submitComment = () => {
        setMyComments([
            {
                parentId: commentComposingId,
                comment: {
                    postCommentId: -1,
                    childComments: [],
                    reactions: [],
                    body: comment,
                    user: currentUser.user ?? { id: -1, username: '', displayName: '', profilePicUrl: '' },
                    createdAt: new Date(),
                    deletedAt: null,
                },
            },
            ...myComments,
        ]);
        setComment('');
        setCommentComposingId(null);
        const endpoint =
            commentComposingId === null
                ? `/post/${postStub.postId}/comment`
                : `/post/${postStub.postId}/comment/${commentComposingId}/comment`;
        post<Post>(endpoint, { comment }, auth.auth)
            .then((res) => {})
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        setComment('');
    }, [commentComposingId]);

    return {
        reacted,
        reactedToComment,
        reactCount:
            postStub.reactionCount -
            (postStub.reaction !== null && !reacted ? 1 : 0) +
            (postStub.reaction === null && reacted ? 1 : 0),
        commentReactCount,
        commentCount: postStub.commentCount + myComments.length,
        myComments,
        reactToPost,
        reactToComment,
        commentComposingId,
        setCommentComposingId,
        comment,
        setComment,
        submitComment,
    };
};

export default usePostInteraction;
