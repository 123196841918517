import { useState } from 'react';

const useKeyboardState = (): boolean => {
    const [isOpen, setIsOpen] = useState(false);

    window.addEventListener('ionKeyboardDidShow', () => {
        setIsOpen(true);
    });
    window.addEventListener('ionKeyboardDidHide', () => {
        setIsOpen(false);
    });

    return isOpen;
};

export default useKeyboardState;
