import styled from 'styled-components';

interface TypographyProps {
    color?: string;
    align?: 'left' | 'center' | 'right';
    maxWidth?: string;
    margin?: string;
    padding?: string;
    $block?: boolean;
    $noWidth100?: boolean;
}

export const Title = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0 0 20px 0'};
    font-size: 2.5rem;
    font-weight: 600;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const Impact = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    text-align: ${(props) => props.align ?? 'center'};
    padding: ${(props) => props.padding ?? '0 20px'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 600;
    font-size: 1.5rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const Subtitle = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 400;
    font-size: 1.5rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const Body = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 400;
    font-size: 1.2rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const SmallBody = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 400;
    font-size: 1rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const SubtleBody = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    opacity: 0.8;
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 200;
    font-size: 1rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const Comment = styled.span<TypographyProps>`
    ${(props) => (props.$noWidth100 ? '' : 'width: 100%;')}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    color: ${(props) => props.color ?? props.theme.color.base.contrast};
    opacity: 0.75;
    text-align: ${(props) => props.align ?? 'left'};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    font-weight: 200;
    font-size: 0.8rem;
    ${(props) => (props.$block ? 'display: block;' : '')}
`;

export const A = styled.a`
    color: ${(props) => props.theme.color.primary.main};
    text-decoration: underline;
    cursor: pointer;
    &:visited {
        color: ${(props) => props.theme.color.primary.main};
    }
`;
