import { IonIcon } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline, locationSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../components/basic/button.component';
import LoadingBar from '../../components/basic/loading-bar.component';
import Pagination from '../../components/basic/pagination.component';
import { SlideBackground } from '../../components/feeds/feed.styled';
import OxPage from '../../components/oxpage.component';
import { Subtitle, Title } from '../../components/typography.styled';
import useAuth from '../../hooks/use-auth';
import useSongHandler from '../../hooks/use-song-handler';
import {
    CenterContentArea,
    ContentArea,
    LocationChip,
    PostPreviewBackgroundArea,
    PostPreviewContentArea,
    PostPreviewTitleHolder,
    SongUploadInputPageButtonHolder,
} from './post.styled';
import LocationSelect from '../../components/posts/location-select.component';
import PostBodyInput from '../../components/forms/post-body-input.component';
import useLocations from '../../hooks/use-locations';
import { FlexContainer } from '../../components/components.styled';
import Modal from '../../components/basic/modal.component';
import PostCardPreview from '../../components/feeds/post-card-preview.component';
import usePostDetails from '../../hooks/use-post-details';
import PostListCard from '../../components/feeds/post-list-card.component';
import { toPostStub } from '../../types';
import { post } from '../../services/api';
import useCurrentUser from '../../hooks/use-current-user';

const RepostPage = () => {
    const { postId } = useParams<{ postId: string }>();
    const [pageOn, setPageOn] = useState(0);
    const [locationModal, setLocationModal] = useState(false);

    const { post: originalPost } = usePostDetails(+postId);

    const [locationId, setLocationId] = useState(0);
    const [body, setBody] = useState('');
    const [uploadProgress, setUplaodProgress] = useState(0);

    const { setSongById, song } = useSongHandler();
    const currentUser = useCurrentUser();
    const auth = useAuth();

    const locations = useLocations();

    useEffect(() => {
        if (currentUser.user?.homeBaseId) setLocationId(currentUser.user?.homeBaseId);
    }, [currentUser.user?.homeBaseId]);
    useEffect(() => {
        if (originalPost?.song.songId) setSongById(originalPost?.song.songId);
    }, [originalPost?.song.songId, setSongById]);

    const startUpload = () => {
        setUplaodProgress(50);
        post('/post/repost', { OriginalPostId: postId, Body: body, LocationId: locationId }, auth.auth)
            .then((res) => {
                setUplaodProgress(100);
            })
            .catch((err) => console.log(err));
    };

    return (
        <OxPage>
            <Modal top show={locationModal} onClose={() => setLocationModal(false)}>
                <FlexContainer direction="column">
                    <Subtitle>Select Location</Subtitle>
                    <LocationSelect
                        small
                        showHomeAtTop
                        hideGlobal
                        selectedId={locationId}
                        setSelectedId={(id) => {
                            id && setLocationId(id);
                            setLocationModal(false);
                        }}
                    />
                </FlexContainer>
            </Modal>
            <Pagination pageOn={pageOn} fullscreen>
                {/* 0: Creating the post */}
                <ContentArea>
                    <Title>Repost</Title>
                    {originalPost && currentUser.user && (
                        <PostListCard post={toPostStub(originalPost, currentUser.user.id)} showUser hideButtons />
                    )}
                    <FlexContainer $wrap style={{ marginBottom: '16px' }}>
                        <LocationChip onClick={() => setLocationModal(true)}>
                            <IonIcon icon={locationSharp} />
                            {locations.getLocation(locationId)?.name}
                        </LocationChip>
                    </FlexContainer>
                    <PostBodyInput
                        id="body"
                        label="What's on your mind?"
                        post={{
                            body,
                            setBody,
                            images: [],
                            imagesLoading: false,
                            onAddImages: () => {},
                            removeImage: () => {},
                        }}
                        disableImages
                    />
                    <FlexContainer justify="flex-end">
                        <Button disabled={body.trim().length === 0} type="primary" onClick={() => setPageOn(1)}>
                            Preview <IonIcon icon={arrowForwardOutline} />
                        </Button>
                    </FlexContainer>
                </ContentArea>
                {/* 1: Preview */}
                <PostPreviewBackgroundArea>
                    <SlideBackground image={originalPost?.backgroundImageUrl || song?.coverImageUrl || ''} />
                    <PostPreviewContentArea>
                        <PostPreviewTitleHolder>
                            <Title>Preview</Title>
                        </PostPreviewTitleHolder>
                        <PostCardPreview
                            post={{
                                body,
                                setBody,
                                images: [],
                                imagesLoading: false,
                                onAddImages: () => {},
                                removeImage: () => {},
                            }}
                            repost={
                                (originalPost && currentUser.user && toPostStub(originalPost, currentUser.user.id)) ??
                                undefined
                            }
                        />
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" onClick={() => setPageOn(0)}>
                                <IonIcon icon={arrowBackOutline} /> Back
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setPageOn(2);
                                    startUpload();
                                }}
                            >
                                Repost <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </PostPreviewContentArea>
                </PostPreviewBackgroundArea>
                {/* 2: Uploading */}
                <CenterContentArea>
                    <Title>Posting...</Title>
                    <LoadingBar fillPercent={uploadProgress} />
                    <SongUploadInputPageButtonHolder>
                        <Button type="small" to={`/user/${currentUser.userId}`} disabled={uploadProgress < 100}>
                            Done <IonIcon icon={arrowForwardOutline} />
                        </Button>
                    </SongUploadInputPageButtonHolder>
                </CenterContentArea>
            </Pagination>
        </OxPage>
    );
};

export default RepostPage;
