import useNotifications from '../../hooks/use-notifications';
import { SmallBody, Subtitle } from '../typography.styled';
import { ToastBody, ToastHolder } from './basic.styled';

const Toast = () => {
    const { showToast, toast } = useNotifications();

    return (
        <ToastHolder show={showToast}>
            {toast && (
                <ToastBody severity={toast.severity}>
                    <Subtitle>{toast.title}</Subtitle>
                    <SmallBody>{toast.body}</SmallBody>
                </ToastBody>
            )}
        </ToastHolder>
    );
};
export default Toast;
