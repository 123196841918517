import { StyledLabel, StyledLabelText, StyledTextInput } from "./form.styled";

interface TextInputProps {
    id: string;
    label: string;
    value: string;
    setValue: (v: string) => void;
    $isPassword?: boolean;
};
const TextInput = ({ id, label, value, setValue, $isPassword }: TextInputProps) => {
    return (
        <>
            <StyledLabel htmlFor={id} $hasInput={value ? true : false}>
                <StyledLabelText>{label}</StyledLabelText>
                <StyledTextInput
                    type={$isPassword ? "password" : "text"}
                    id={id}
                    name={id}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </StyledLabel>
        </>
    );
};

export default TextInput;