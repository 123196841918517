import { useState } from "react";
import Form from "../../../components/forms/form.component";
import SubmitButton from "../../../components/forms/submit-button.component";
import TextInput from "../../../components/forms/text-input.component";
import useAuth from "../../../hooks/use-auth";

const SignInForm = () => {
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const authBundle = useAuth();

    const onSubmit = () => {
        authBundle.login({
            username: username,
            password: password
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <TextInput id="username" label="Username" value={username} setValue={setUsername} />
            <TextInput id="password" label="Password" value={password} setValue={setPassword} $isPassword />
            <SubmitButton text="Sign In" isLoading={authBundle.isLoading}/>
        </Form>
    );
};

export default SignInForm;