import { IonIcon } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline, locationSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../components/basic/button.component';
import LoadingBar from '../../components/basic/loading-bar.component';
import Pagination from '../../components/basic/pagination.component';
import { SlideBackground } from '../../components/feeds/feed.styled';
import SongPlayback from '../../components/music/song-playback.component';
import OxPage from '../../components/oxpage.component';
import { SmallBody, Subtitle, Title } from '../../components/typography.styled';
import usePost from '../../hooks/use-post';
import useSongHandler from '../../hooks/use-song-handler';
import {
    CenterContentArea,
    ContentArea,
    LocationChip,
    PostPreviewBackgroundArea,
    PostPreviewContentArea,
    PostPreviewTitleHolder,
    SongUploadInputPageButtonHolder,
} from './post.styled';
import LocationSelect from '../../components/posts/location-select.component';
import PostBodyInput from '../../components/forms/post-body-input.component';
import useLocations from '../../hooks/use-locations';
import { FlexContainer } from '../../components/components.styled';
import Modal from '../../components/basic/modal.component';
import PostCardPreview from '../../components/feeds/post-card-preview.component';
import useCurrentUser from '../../hooks/use-current-user';

const PostPage = () => {
    const { songId } = useParams<{ songId: string }>();
    const [pageOn, setPageOn] = useState(0);
    const [locationModal, setLocationModal] = useState(false);

    const { setSongById, song, setStartPos } = useSongHandler();
    const currentUser = useCurrentUser();

    const post = usePost(song ?? null);
    const locations = useLocations();

    useEffect(() => {
        setSongById(+songId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [songId, currentUser.userId]);

    const onSelectStartPos = (pos: number) => {
        setStartPos(pos);
        post.setSongStart(pos);
    };

    return (
        <OxPage>
            <Modal top show={locationModal} onClose={() => setLocationModal(false)}>
                <FlexContainer direction="column">
                    <Subtitle>Select Location</Subtitle>
                    <LocationSelect
                        small
                        showHomeAtTop
                        hideGlobal
                        selectedId={post.locationId}
                        setSelectedId={(id) => {
                            id && post.setLocationId(id);
                            setLocationModal(false);
                        }}
                    />
                </FlexContainer>
            </Modal>
            <Pagination pageOn={pageOn} fullscreen>
                {/* 0: Creating the post */}
                <ContentArea>
                    <Title>New Post</Title>
                    <SongPlayback
                        startPos={post.songStart}
                        setStartPos={post.setSongStart}
                        onFinishDrag={onSelectStartPos}
                    />
                    {song && song.tags && song.tags.length > 0 && (
                        <SmallBody margin="-6px 0 8px 0">
                            Song Tags: {song.tags.map((t) => `#${t}`).join(', ')}
                        </SmallBody>
                    )}
                    <FlexContainer $wrap style={{ marginBottom: '16px' }}>
                        <LocationChip onClick={() => setLocationModal(true)}>
                            <IonIcon icon={locationSharp} />
                            {locations.getLocation(post.locationId)?.name}
                        </LocationChip>
                    </FlexContainer>
                    <PostBodyInput id="body" label="What's on your mind?" post={post} />
                    <FlexContainer justify="flex-end">
                        <Button disabled={post.body.trim().length === 0} type="primary" onClick={() => setPageOn(1)}>
                            Preview <IonIcon icon={arrowForwardOutline} />
                        </Button>
                    </FlexContainer>
                </ContentArea>
                {/* 1: Preview */}
                <PostPreviewBackgroundArea>
                    <SlideBackground image={post.background?.preview || song?.coverImageUrl || ''} />
                    <PostPreviewContentArea>
                        <PostPreviewTitleHolder>
                            <Title>preview</Title>
                        </PostPreviewTitleHolder>
                        <PostCardPreview post={post} />
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" onClick={() => setPageOn(0)}>
                                <IonIcon icon={arrowBackOutline} /> Back
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setPageOn(2);
                                    post.startUpload();
                                }}
                            >
                                Post <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </PostPreviewContentArea>
                </PostPreviewBackgroundArea>
                {/* 2: Uploading */}
                <CenterContentArea>
                    <Title>Posting...</Title>
                    <LoadingBar fillPercent={post.uploadProgress} />
                    <SongUploadInputPageButtonHolder>
                        <Button type="small" to={`/user/${currentUser.userId}`} disabled={post.uploadProgress < 100}>
                            Done <IonIcon icon={arrowForwardOutline} />
                        </Button>
                    </SongUploadInputPageButtonHolder>
                </CenterContentArea>
            </Pagination>
        </OxPage>
    );
};

export default PostPage;
