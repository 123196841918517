import { IonIcon } from '@ionic/react';
import { SongUploadBundle } from '../../hooks/use-song-upload';
import { FlexContainer } from '../components.styled';
import { Body, SubtleBody } from '../typography.styled';
import {
    ExplicitMarker,
    MusicDiskImage,
    PlaybackContent,
    PlaybackHolder,
    PlaybackPlayPauseButton,
    PlaybackPositionBackground,
} from './music.styled';
import { playOutline, pauseOutline } from 'ionicons/icons';
import useSongHandler from '../../hooks/use-song-handler';
import { SongState } from '../../types';
import useSongPosition from '../../hooks/use-song-position';

interface SongUploadPlaybackProps {
    songBundle: SongUploadBundle;
}
const SongUploadPlayback = ({ songBundle }: SongUploadPlaybackProps) => {
    const { state } = useSongHandler();
    const { percent } = useSongPosition();

    return (
        <PlaybackHolder>
            <PlaybackPositionBackground $playing={state === SongState.Playing} percent={percent} />
            <PlaybackContent>
                <FlexContainer direction="row" align="center">
                    <PlaybackPlayPauseButton onClick={() => songBundle.togglePlaying()}>
                        <IonIcon icon={state === SongState.Playing ? pauseOutline : playOutline} />
                    </PlaybackPlayPauseButton>
                    <MusicDiskImage
                        image={songBundle.cover?.preview ?? null}
                        size="small"
                        speed="10s"
                        $playing={state === SongState.Playing}
                        $hideBorder
                    />
                    <FlexContainer direction="column" padding="0 0 0 16px">
                        <Body>
                            {songBundle.isExplicit && <ExplicitMarker>E</ExplicitMarker>}
                            {songBundle.title}
                        </Body>
                        <SubtleBody>{songBundle.artist}</SubtleBody>
                    </FlexContainer>
                </FlexContainer>
            </PlaybackContent>
        </PlaybackHolder>
    );
};

export default SongUploadPlayback;
