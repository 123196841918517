import { useEffect, useState } from "react";
import { get } from "../services/api";
import { SongStub } from "../types";
import useAuth from "./use-auth";


const useSongSearch = (query: string) => {
    const [ results, setResults ] = useState<SongStub[]>([]);

    const auth = useAuth();

    useEffect(() => {
        if (!query || query === "") {
            setResults([]);
            return;
        }
        const getData = setTimeout(() => {
            const params = (new URLSearchParams({ query: query })).toString();
            get<SongStub[]>(`/song/search?${params}`, auth.auth)
                .then(r => setResults(r.data ?? []))
                .catch(e => console.log(e));
        }, 1000);
        return () => clearTimeout(getData);
    }, [ query ]);

    return results;
};

export default useSongSearch;