import { useParams } from 'react-router';
import OxPage from '../../components/oxpage.component';
import { ChatContentArea, ChatHeaderArea, ChatHeaderContentArea, ContentArea, MessageArea } from './chat.styled';
import ProfilePic from '../../components/user/profile-pic.component';
import { Body, SmallBody, Subtitle } from '../../components/typography.styled';
import { getChatTitle } from '../../utils/chat.util';
import { BackButton } from '../post-details/post-details.styled';
import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import useMessages from '../../hooks/use-messages';
import NotificationBubble from '../../components/messages/notification-bubble';
import useCurrentUser from '../../hooks/use-current-user';
import { useEffect, useRef } from 'react';
import ChatTop from '../../components/messages/chat-top.component';
import useNotifications from '../../hooks/use-notifications';
import LoadingSpinner from '../../components/basic/loading-spinner.component';
import ChatTextbox from '../../components/messages/chat-textbox.component';
import ChatBubble from '../../components/messages/chat-bubble.component';
import { BlockAlert } from '../user/user.styled';

const ChatPage = () => {
    const { chatId } = useParams<{ chatId: string }>();

    const chat = useMessages(+chatId);
    const currentUser = useCurrentUser();
    const notifications = useNotifications();

    const chatAreaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [chat.messages]);
    useEffect(() => {
        if (chat.messages) notifications.setSeenByChatId(+chatId);
    }, [chatId, chat.messages, notifications]);

    if (chat.isLoading) {
        return (
            <OxPage>
                <ChatHeaderArea>
                    <ChatHeaderContentArea>
                        <BackButton to="/chat">
                            <IonIcon icon={arrowBack} />
                        </BackButton>
                        <Subtitle>Chat</Subtitle>
                    </ChatHeaderContentArea>
                </ChatHeaderArea>
                <ContentArea style={{ paddingTop: '80px' }}>
                    <LoadingSpinner />
                </ContentArea>
            </OxPage>
        );
    }

    const reversed = chat.messages.slice().reverse();
    const isNotification = +chatId < 0;

    return (
        <OxPage>
            {currentUser.isBlockingUser(chat?.chatId) ? (
                <BlockAlert>
                    <SmallBody>You are blocking this user.</SmallBody>
                </BlockAlert>
            ) : (
                <></>
            )}
            <ChatHeaderArea>
                <ChatHeaderContentArea>
                    <BackButton to="/chat">
                        <IonIcon icon={arrowBack} />
                    </BackButton>
                    {!isNotification && chat.user ? (
                        <ProfilePic size="small" user={chat.user} />
                    ) : (
                        <ProfilePic size="small" isAdminUser={true} />
                    )}
                    <Subtitle>{getChatTitle(chat)}</Subtitle>
                </ChatHeaderContentArea>
            </ChatHeaderArea>
            <ChatContentArea $hasTextbox={!isNotification} ref={chatAreaRef}>
                <MessageArea>
                    <ChatTop chat={chat} />
                    {isNotification
                        ? reversed.map((m) => (
                              <NotificationBubble key={m.notificationId} notification={m} currentUser={currentUser} />
                          ))
                        : reversed.map((m) => <ChatBubble key={m.notificationId} message={m} />)}
                </MessageArea>
            </ChatContentArea>
            {!isNotification && !currentUser.isBlockingUser(chat?.chatId) ? (
                <ChatTextbox id="chat-page-input" value={chat.draft} setValue={chat.setDraft} onSend={chat.send} />
            ) : (
                <></>
            )}
        </OxPage>
    );
};

export default ChatPage;
