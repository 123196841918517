import { chatbubblesOutline, heart, heartOutline, paperPlaneOutline, playOutline } from 'ionicons/icons';
import { PostStub } from '../../types';
import { FlexContainer } from '../components.styled';
import { StyledIcon } from '../navigation/navigation.styled';
import { Body, Comment, SmallBody } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import {
    LikeButtonPulse,
    PostButton,
    PostLikeButton,
    PostLinkButton,
    PostListCardArea,
    PostListCardContentArea,
    PostUserArea,
    SongPlayButton,
    SongPlayButtonCover,
} from './feed.styled';
import usePostInteraction from '../../hooks/use-post-interaction';
import useCommonAction from '../../hooks/use-common-action';

interface PostListCardProps {
    post: PostStub;
    showUser?: boolean;
    playing?: boolean;
    hideButtons?: boolean;
    onClickPlay?: () => void;
}
const PostListCard = ({ post, showUser, onClickPlay, playing, hideButtons }: PostListCardProps) => {
    const interact = usePostInteraction(post);
    const { setSharePost } = useCommonAction();

    return (
        <PostListCardArea image={post.backgroundImageUrl ?? post.song?.coverImageUrl ?? null}>
            <PostListCardContentArea $hideButtons={hideButtons}>
                <FlexContainer direction="row" align="center">
                    <FlexContainer direction="column" padding="0 5px 0 0">
                        {showUser && (
                            <PostUserArea to={`/home/user/${post.user?.id ?? 0}`} $hideInteract={hideButtons}>
                                <ProfilePic size="small" user={post.user ?? null} />
                                <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                    <SmallBody>{post.user?.displayName ?? ''}</SmallBody>
                                    <Comment style={{ marginTop: '-6px' }}>@{post.user?.username ?? ''}</Comment>
                                </FlexContainer>
                            </PostUserArea>
                        )}
                        <SmallBody>{post.body}</SmallBody>
                    </FlexContainer>
                    <SongPlayButton onClick={onClickPlay} $hideButtons={hideButtons}>
                        <SongPlayButtonCover image={post.song?.coverImageUrl ?? ''} $playing={playing} speed="10s">
                            {playing === false && <StyledIcon icon={playOutline} style={{ fontSize: '32px' }} />}
                        </SongPlayButtonCover>
                    </SongPlayButton>
                </FlexContainer>
                {!hideButtons && (
                    <FlexContainer direction="row" align="center" justify="space-between" margin="10px 0 0 0">
                        <PostLikeButton reacted={interact.reacted} onClick={interact.reactToPost}>
                            {interact.reacted && <LikeButtonPulse></LikeButtonPulse>}
                            <StyledIcon icon={interact.reacted ? heart : heartOutline} style={{ fontSize: '24px' }} />
                            <Body $noWidth100 margin="0 0 0 12px">
                                {interact.reactCount}
                            </Body>
                        </PostLikeButton>
                        <PostButton onClick={() => setSharePost(post)}>
                            <StyledIcon icon={paperPlaneOutline} style={{ fontSize: '24px' }} />
                        </PostButton>
                        <PostLinkButton to={`/user/post-details/${post.postId}`}>
                            <StyledIcon icon={chatbubblesOutline} style={{ fontSize: '24px' }} />
                            <Body $noWidth100 margin="0 0 0 12px">
                                {post.commentCount}
                            </Body>
                        </PostLinkButton>
                    </FlexContainer>
                )}
            </PostListCardContentArea>
        </PostListCardArea>
    );
};

export default PostListCard;
