import { useState } from 'react';
import Button from '../../../components/basic/button.component';
import OxPage from '../../../components/oxpage.component';
import { Title, Subtitle } from '../../../components/typography.styled';
import {
    ContentArea,
    CoverPreviewHolder,
    SongUploadInputPage,
    SongUploadInputPageButtonHolder,
} from '../../post/post.styled';
import { useDropzone } from 'react-dropzone';
import Pagination from '../../../components/basic/pagination.component';
import { arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import Filedrop from '../../../components/basic/filedrop.component';
import LoadingBar from '../../../components/basic/loading-bar.component';
import TextInput from '../../../components/forms/text-input.component';
import useUserEdit from '../../../hooks/use-user-edit';
import useAuth from '../../../hooks/use-auth';
import MultilineTextInput from '../../../components/forms/multiline-text-input.component';
import LocationSelect from '../../../components/posts/location-select.component';
import { UserImage } from '../../../components/user/user.styled';
import useCurrentUser from '../../../hooks/use-current-user';

const UserDetailsPage = () => {
    const auth = useAuth();
    const currentUser = useCurrentUser();
    const userEditBundle = useUserEdit(+(currentUser.userId ?? '-1'));
    const [pageOn, setPageOn] = useState(0);

    const profilePicDropzone = useDropzone({
        onDrop: userEditBundle.onDropProfilePic,
        accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] },
    });

    return (
        <OxPage>
            <ContentArea>
                <Title padding="0 32px">Tell me about yourself</Title>
                <Pagination pageOn={pageOn}>
                    {/* Basic Info */}
                    <SongUploadInputPage key={0}>
                        <Subtitle margin="0 0 16px 0">Let's start with the basics</Subtitle>
                        <TextInput
                            id="name"
                            label="Display Name"
                            value={userEditBundle.userPreview.displayName}
                            setValue={userEditBundle.setName}
                        />
                        <TextInput
                            id="title"
                            label="Title (Optional)"
                            value={userEditBundle.userPreview.title ?? ''}
                            setValue={userEditBundle.setTitle}
                        />
                        <MultilineTextInput
                            id="bio"
                            label="Bio (Optional)"
                            value={userEditBundle.userPreview.profileMessage}
                            setValue={userEditBundle.setMessage}
                        />
                        <SongUploadInputPageButtonHolder>
                            <Button
                                type="small"
                                disabled={userEditBundle.userPreview.displayName === ''}
                                onClick={() => setPageOn(1)}
                            >
                                Next <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </SongUploadInputPage>
                    {/* Home Base */}
                    <SongUploadInputPage key={1}>
                        <Subtitle margin="0 0 16px 0">Where do you call home?</Subtitle>
                        <LocationSelect
                            selectedId={userEditBundle.userPreview.homeBaseId ?? null}
                            setSelectedId={userEditBundle.setHomeBaseId}
                        />
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" onClick={() => setPageOn(0)}>
                                <IonIcon icon={arrowBackOutline} /> Back
                            </Button>
                            <Button
                                type="small"
                                disabled={(userEditBundle.userPreview.homeBaseId ?? null) === null}
                                onClick={() => setPageOn(2)}
                            >
                                Next <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </SongUploadInputPage>
                    {/* Profile Picture */}
                    <SongUploadInputPage key={2}>
                        <Subtitle margin="0 0 16px 0">Upload a profile pic</Subtitle>
                        {userEditBundle.profilePic ? (
                            <CoverPreviewHolder>
                                <UserImage image={`url(${userEditBundle.profilePic.preview})`} size="large" />
                                <Button type="small" onClick={userEditBundle.clearProfilePic}>
                                    Clear
                                </Button>
                            </CoverPreviewHolder>
                        ) : (
                            <Filedrop state={profilePicDropzone} hint="any image file will do" />
                        )}
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" onClick={() => setPageOn(1)}>
                                <IonIcon icon={arrowBackOutline} /> Back
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    userEditBundle.startUpload();
                                    setPageOn(3);
                                }}
                            >
                                Submit <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </SongUploadInputPage>
                    {/* Links */}
                    {/* <SongUploadInputPage key={2}>
                        <Subtitle margin="0 0 16px 0">Add Links</Subtitle>
                        <LinkHolderArea>
                            {songBundle.links.map((l,i) => <SongLink key={i} url={l} onRemove={() => songBundle.removeLink(i)}/>)}
                        </LinkHolderArea>
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" onClick={() => setPageOn(1)}><IonIcon icon={arrowBackOutline}/> Back</Button>
                            <Button type="small" onClick={() => setPageOn(3)}>Next <IonIcon icon={arrowForwardOutline}/></Button>
                        </SongUploadInputPageButtonHolder>
                    </SongUploadInputPage> */}
                    {/* Upload Progress */}
                    <SongUploadInputPage key={4}>
                        <div style={{ height: '100px' }} />
                        <LoadingBar fillPercent={userEditBundle.uploadProgress} />
                        <SongUploadInputPageButtonHolder>
                            <Button type="small" disabled={userEditBundle.uploadProgress < 100} onClick={auth.refresh}>
                                Let's get started <IonIcon icon={arrowForwardOutline} />
                            </Button>
                        </SongUploadInputPageButtonHolder>
                    </SongUploadInputPage>
                </Pagination>
            </ContentArea>
        </OxPage>
    );
};

export default UserDetailsPage;
