import { useState } from 'react';
import { PostInteractionBundle } from '../../hooks/use-post-interaction';
import { PostStub } from '../../types';
import { FlexContainer } from '../components.styled';
import { StyledIcon } from '../navigation/navigation.styled';
import { Body, Comment, SmallBody } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import {
    LikeButtonPulse,
    PostActionButton,
    PostActionButtonLink,
    PostActionsArea,
    PostBodyArea,
    PostButton,
    PostCardArea,
    PostCardHolder,
    PostEllipsisButton,
    PostLikeButton,
    PostLinkButton,
    PostUserArea,
    PostUserPreviewArea,
    RepostMessageArea,
    VerifiedBubble,
} from './feed.styled';
import {
    heartOutline,
    paperPlaneOutline,
    chatbubblesOutline,
    heart,
    ellipsisHorizontal,
    repeatOutline,
    checkmarkCircleOutline,
} from 'ionicons/icons';
import PostBody from './post-body.component';
import PostImageCarousel from './post-image-carousel.component';
import { IonIcon } from '@ionic/react';
import useCurrentUser from '../../hooks/use-current-user';
import useCommonAction from '../../hooks/use-common-action';
import useSongHandler from '../../hooks/use-song-handler';
import { getDisplayDate } from '../../utils/date.util';
import dayjs from 'dayjs';

interface PostCardProps {
    post: PostStub;
    detailView?: boolean;
    interact?: PostInteractionBundle;
}
const PostCard = ({ post, detailView, interact }: PostCardProps) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const { setSharePost, setReportPost } = useCommonAction();
    const currentUser = useCurrentUser();

    const { song } = useSongHandler();
    const verified: string | undefined = song?.credits?.find((c) => c.user?.id === post.user?.id)?.role;

    const iconSize = detailView ? '24px' : '32px';
    const carouselSize = post.repost ? '160px' : '200px';
    const link = `/home/post-details/${post.postId}`;

    const startReport = () => {
        setReportPost(post);
    };

    return (
        <>
            <PostCardHolder $detailView={detailView} $minimized={isMinimized}>
                <PostCardArea
                    $detailView={detailView}
                    $minimized={isMinimized}
                    onClick={() => {
                        if (isMinimized) {
                            setIsMinimized(false);
                            setShowActions(false);
                        }
                    }}
                >
                    <FlexContainer direction="row" align="stretch" justify="space-between">
                        {isMinimized ? (
                            <PostUserPreviewArea>
                                <ProfilePic size="small" user={post.user ?? null} />
                                <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                    <SmallBody>{post.user?.displayName ?? ''}</SmallBody>
                                    <Comment style={{ marginTop: '-6px' }}>@{post.user?.username ?? ''}</Comment>
                                </FlexContainer>
                            </PostUserPreviewArea>
                        ) : (
                            <PostUserArea to={`/home/user/${post.user?.id ?? 0}`}>
                                <ProfilePic size="small" user={post.user ?? null} />
                                <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                    <SmallBody>{post.user?.displayName ?? ''}</SmallBody>
                                    <Comment style={{ marginTop: '-6px' }}>@{post.user?.username ?? ''}</Comment>
                                </FlexContainer>
                            </PostUserArea>
                        )}
                        <PostEllipsisButton onClick={() => setShowActions(!showActions)}>
                            <StyledIcon icon={ellipsisHorizontal} style={{ fontSize: '24px' }} />
                        </PostEllipsisButton>
                    </FlexContainer>
                    <PostBodyArea>
                        <Body>
                            <PostBody body={post.body} />
                        </Body>
                    </PostBodyArea>
                    {post.media && post.media.length > 0 && (
                        <PostImageCarousel images={post.media} size={carouselSize} />
                    )}
                    <FlexContainer direction="column" align="stretch">
                        <Comment align="center">
                            {post.location?.name} • {getDisplayDate(dayjs(post.createdAt))}
                        </Comment>
                        <FlexContainer direction="row" align="stretch" justify="space-between">
                            <PostLikeButton reacted={interact?.reacted ?? false} onClick={interact?.reactToPost}>
                                {interact?.reacted && <LikeButtonPulse></LikeButtonPulse>}
                                <StyledIcon
                                    icon={interact?.reacted ? heart : heartOutline}
                                    style={{ fontSize: iconSize }}
                                />
                                <Body $noWidth100 margin="0 0 0 12px">
                                    {interact?.reactCount ?? post.reactionCount}
                                </Body>
                            </PostLikeButton>
                            <PostButton onClick={() => setSharePost(post)}>
                                <StyledIcon icon={paperPlaneOutline} style={{ fontSize: iconSize }} />
                            </PostButton>
                            <PostLinkButton to={link}>
                                <StyledIcon icon={chatbubblesOutline} style={{ fontSize: iconSize }} />
                                <Body $noWidth100 margin="0 0 0 12px">
                                    {post.commentCount}
                                </Body>
                            </PostLinkButton>
                        </FlexContainer>
                    </FlexContainer>
                </PostCardArea>
                <PostActionsArea $show={showActions && !isMinimized} $detailView={detailView}>
                    {post.user && post.user.id !== currentUser.userId && (
                        <PostActionButton onClick={() => currentUser.toggleFollowing(post.user?.id ?? -1)}>
                            {currentUser.isFollowingUser(post.user.id) ? 'Unfollow User' : 'Follow User'}
                        </PostActionButton>
                    )}
                    {!detailView && <PostActionButtonLink to={link}>View Details</PostActionButtonLink>}
                    <PostActionButton $red onClick={startReport}>
                        Report Post
                    </PostActionButton>
                </PostActionsArea>
            </PostCardHolder>
            {verified && !post.repost && (
                <VerifiedBubble>
                    <IonIcon icon={checkmarkCircleOutline} />
                    <SmallBody>Verified {verified}</SmallBody>
                </VerifiedBubble>
            )}
            {post.repost && (
                <RepostMessageArea>
                    <IonIcon icon={repeatOutline} />
                    <SmallBody>Reposted</SmallBody>
                </RepostMessageArea>
            )}
            {post.repost && (
                <PostCardHolder $detailView={detailView} $minimized={!isMinimized} $isRepost>
                    <PostCardArea
                        $isRepost
                        $detailView={detailView}
                        $minimized={!isMinimized}
                        onClick={() => {
                            if (!isMinimized) {
                                setIsMinimized(true);
                                setShowActions(false);
                            }
                        }}
                    >
                        <FlexContainer direction="row" align="stretch" justify="space-between">
                            {!isMinimized ? (
                                <PostUserPreviewArea>
                                    <ProfilePic size="small" user={post.repost.user ?? null} />
                                    <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                        <SmallBody>{post.repost.user?.displayName ?? ''}</SmallBody>
                                        <Comment style={{ marginTop: '-6px' }}>
                                            @{post.repost.user?.username ?? ''}
                                        </Comment>
                                    </FlexContainer>
                                </PostUserPreviewArea>
                            ) : (
                                <PostUserArea to={`/home/user/${post.repost.user?.id ?? 0}`}>
                                    <ProfilePic size="small" user={post.repost.user ?? null} />
                                    <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                        <SmallBody>{post.repost.user?.displayName ?? ''}</SmallBody>
                                        <Comment style={{ marginTop: '-6px' }}>
                                            @{post.repost.user?.username ?? ''}
                                        </Comment>
                                    </FlexContainer>
                                </PostUserArea>
                            )}
                            <PostEllipsisButton onClick={() => setShowActions(!showActions)}>
                                <StyledIcon icon={ellipsisHorizontal} style={{ fontSize: '24px' }} />
                            </PostEllipsisButton>
                        </FlexContainer>
                        <PostBodyArea>
                            <Body>
                                <PostBody body={post.repost.body} />
                            </Body>
                        </PostBodyArea>
                        {post.repost.media && post.repost.media.length > 0 && (
                            <PostImageCarousel images={post.repost.media} size={carouselSize} />
                        )}
                        <FlexContainer direction="column" align="stretch">
                            <Comment align="center">
                                {post.repost.location?.name} • {getDisplayDate(dayjs(post.repost.createdAt))}
                            </Comment>
                            <FlexContainer direction="row" align="stretch" justify="space-between">
                                <PostLikeButton reacted={interact?.reacted ?? false} onClick={interact?.reactToPost}>
                                    {interact?.reacted && <LikeButtonPulse></LikeButtonPulse>}
                                    <StyledIcon
                                        icon={interact?.reacted ? heart : heartOutline}
                                        style={{ fontSize: iconSize }}
                                    />
                                    <Body $noWidth100 margin="0 0 0 12px">
                                        {interact?.reactCount ?? post.repost.reactionCount}
                                    </Body>
                                </PostLikeButton>
                                <PostButton onClick={() => setSharePost(post.repost ?? null)}>
                                    <StyledIcon icon={paperPlaneOutline} style={{ fontSize: iconSize }} />
                                </PostButton>
                                <PostLinkButton to={link}>
                                    <StyledIcon icon={chatbubblesOutline} style={{ fontSize: iconSize }} />
                                    <Body $noWidth100 margin="0 0 0 12px">
                                        {post.repost.commentCount}
                                    </Body>
                                </PostLinkButton>
                            </FlexContainer>
                        </FlexContainer>
                    </PostCardArea>
                    <PostActionsArea $show={showActions && isMinimized} $detailView={detailView}>
                        {post.repost?.user && post.repost.user.id !== currentUser.userId && (
                            <PostActionButton onClick={() => currentUser.toggleFollowing(post.repost?.user?.id ?? -1)}>
                                {currentUser.isFollowingUser(post.repost.user.id) ? 'Unfollow user' : 'Follow User'}
                            </PostActionButton>
                        )}
                        {!detailView && <PostActionButtonLink to={link}>View Details</PostActionButtonLink>}
                        <PostActionButton $red onClick={startReport}>
                            Report Post
                        </PostActionButton>
                    </PostActionsArea>
                </PostCardHolder>
            )}
        </>
    );
};

export default PostCard;
