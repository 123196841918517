import LoadingSkeleton from "../../../components/basic/loading-skeleton.component";
import { FlexContainer } from "../../../components/components.styled";
import { Impact, Comment } from "../../../components/typography.styled";
import { UserTabButton } from "../user.styled";

interface UserViewTabProps {
    title: string;
    count: number|null;
    tab: number;
    tabOn: number;
    onClick: () => void;
};
const UserViewTab = ({ title, count, tab, tabOn, onClick }: UserViewTabProps) => {
    return (
        <UserTabButton tab={tab} tabOn={tabOn} onClick={onClick}>
            <FlexContainer direction="column" align="center" justify="center">
                <LoadingSkeleton loading={count == null} width="30px" height="20px">
                    <Impact align="center">{count}</Impact>
                </LoadingSkeleton>
                <Comment align="center">{title}</Comment>
            </FlexContainer>
        </UserTabButton>
    );
};

export default UserViewTab;