import styled from 'styled-components';
import { addAlpha } from '../../utils/color.util';

export const ContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${(props) => props.theme.margin.huge});
    max-width: 600px;
    margin: ${(props) => props.theme.margin.huge} auto;
    padding-top: var(--ion-safe-area-top, 0);
    align-items: center;
`;

export const SongUploadInputPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.margin.medium} ${(props) => props.theme.margin.huge};
`;
export const SongUploadInputPageButtonHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const CoverPreviewHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
interface CoverPreviewProps {
    image: string;
}
export const CoverPreview = styled.div<CoverPreviewProps>`
    width: 200px;
    height: 200px;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
`;

export const LinkHolderArea = styled.div``;
export const LinkArea = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    padding: 4px 0;
    &:last-child {
        border-bottom: none;
    }
    & > * {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;
export const LinkXButton = styled.button`
    outline: none;
    border: none;
    width: 28px;
    height: 28px;
    min-width: 28px;
    background: none;
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    border-radius: ${(props) => props.theme.border.radius.small};
    transition: background-color 0.2s;
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
`;

export const PostPreviewBackgroundArea = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const PostPreviewContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${(props) => props.theme.margin.huge});
    max-width: 600px;
    margin: auto;
    padding-top: ${(props) => props.theme.margin.huge};
    align-items: center;
`;

export const PostPreviewTitleHolder = styled.div`
    position: absolute;
    top: 0;
    z-index: 10;
    margin-top: var(--ion-safe-area-top, 0);
`;

export const CenterContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${(props) => props.theme.margin.huge});
    max-width: 600px;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
`;

export const LocationChip = styled.button`
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.3)};
    border-radius: 999px;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    padding-left: ${(props) => props.theme.padding.medium};
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
    & > ion-icon {
        margin-right: ${(props) => props.theme.padding.small};
        position: relative;
        top: 2px;
    }
`;
