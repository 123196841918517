import { SplashArea, PermanentSplashArea, SplashImage } from "./components.styled";
import splashImage from "../assets/splash.png";

interface SplashProps {
    show: boolean;
};

const Splash = ({ show }: SplashProps) => {
    if (show){
        return (
            <PermanentSplashArea>
                <SplashImage src={splashImage}/>
            </PermanentSplashArea>
        );

    }
    return (
        <SplashArea>
            <SplashImage src={splashImage}/>
        </SplashArea>
    );
};

export default Splash;