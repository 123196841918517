import { ReactNode } from "react";
import { BannerArea, BasicIcon, PopupBodySection, Severity, StyledPopupBadge, StyledPopupBadgeHolder } from "./basic.styled";
import { alertCircleOutline, warningOutline, informationCircleOutline } from 'ionicons/icons';

interface BannerProps {
    severity: Severity;
    children?: ReactNode;
};
const Banner = ({ severity, children }: BannerProps) => {
    const switchIcon = (s: Severity) => {
        switch (s) {
            case "error":
                return <BasicIcon icon={alertCircleOutline} />
            case "warning":
                return <BasicIcon icon={warningOutline} />
            case "info":
                return <BasicIcon icon={informationCircleOutline} />
        }
    };

    return (
        <BannerArea severity={severity}>
            {switchIcon(severity)}
            <PopupBodySection>
                {children}
            </PopupBodySection>
        </BannerArea>
    )
};

export default Banner;