import { ReactNode } from "react";
import { BasicIcon, PopupBodySection, Severity, StyledPopupBadge, StyledPopupBadgeHolder } from "./basic.styled";
import { alertCircleOutline, warningOutline, informationCircleOutline } from 'ionicons/icons';

interface PopupBadgeProps {
    show: boolean;
    severity: Severity;
    children?: ReactNode;
};
const PopupBadge = ({ show, severity, children }: PopupBadgeProps) => {
    const switchIcon = (s: Severity) => {
        switch (s) {
            case "error":
                return <BasicIcon icon={alertCircleOutline} />
            case "warning":
                return <BasicIcon icon={warningOutline} />
            case "info":
                return <BasicIcon icon={informationCircleOutline} />
        }
    };

    return (
        <StyledPopupBadgeHolder>
            <StyledPopupBadge severity={severity} $show={show}>
                {switchIcon(severity)}
                <PopupBodySection>
                    {children}
                </PopupBodySection>
            </StyledPopupBadge>
        </StyledPopupBadgeHolder>
    )
};

export default PopupBadge;