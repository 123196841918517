import { Preferences } from '@capacitor/preferences';

export const getLocal = async <T>(key: string) => {
    return Preferences.get({ key: key })
        .then(data => {
            if (data.value) {
                return JSON.parse(data.value) as T;
            }
            return null;
        })
        .catch(e => { return null });
};

export const setLocal = async <T>(key: string, value: T) => {
    if (!value) return Preferences.remove({ key })
        .then(r => { return true })
        .catch(e => { return false });
    else return Preferences.set({ key, value: JSON.stringify(value) })
        .then(r => { return true })
        .catch(e => { return false });
};