import { useEffect, useState } from 'react';
import { get } from '../services/api';
import { UserStub } from '../types';
import useAuth from './use-auth';

const useUserSearch = (query: string) => {
    const [results, setResults] = useState<UserStub[]>([]);

    const auth = useAuth();

    useEffect(() => {
        if (!query || query === '') {
            setResults([]);
            return;
        }
        const getData = setTimeout(() => {
            const params = new URLSearchParams({ query: query }).toString();
            get<UserStub[]>(`/user/search?${params}`, auth.auth)
                .then((r) => setResults(r.data ?? []))
                .catch((e) => console.log(e));
        }, 1000);
        return () => clearTimeout(getData);
    }, [query]);

    return results;
};

interface UserSearchCallbackProps {
    userAutocomplete: (search: string, callback: (data: UserStub[]) => void) => void;
}
export const useUserSearchCallback = (): UserSearchCallbackProps => {
    const auth = useAuth();

    const userAutocomplete = (search: string, callback: (data: UserStub[]) => void) => {
        const params = new URLSearchParams({ query: search }).toString();
        get<UserStub[]>(`/user/search?${params}`, auth.auth)
            .then((r) => {
                if (r.hadError) {
                    callback([]);
                    return;
                }
                console.log(r);
                callback(r.data ?? []);
            })
            .catch((e) => console.log(e));
    };

    return {
        userAutocomplete,
    };
};

export default useUserSearch;
