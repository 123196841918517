import { useState } from 'react';
import { useParams } from 'react-router';
import OxPage from '../../../components/oxpage.component';
import { Body, Subtitle, Title } from '../../../components/typography.styled';
import Button from '../../../components/basic/button.component';
import {
    BackgroundImagePreview,
    ContentCenterer,
    FixedTitleArea,
    FixedTitleContent,
    PageAreaContent,
} from '../user.styled';
import ProfilePic from '../../../components/user/profile-pic.component';
import Modal from '../../../components/basic/modal.component';
import Filedrop from '../../../components/basic/filedrop.component';
import { useDropzone } from 'react-dropzone';
import useUserEdit from '../../../hooks/use-user-edit';
import { UserImage } from '../../../components/user/user.styled';
import { FlexContainer } from '../../../components/components.styled';
import LoadingBar from '../../../components/basic/loading-bar.component';
import SmallTextInput from '../../../components/forms/small-text-input.component';
import useLocations from '../../../hooks/use-locations';
import LocationSelect from '../../../components/posts/location-select.component';
import LinkInput from '../../../components/forms/link-input.component';
import useCurrentUser from '../../../hooks/use-current-user';

interface UserPageRouteParams {
    userId?: string;
}
const UserEditPage = () => {
    const { userId } = useParams<UserPageRouteParams>();
    const currentUser = useCurrentUser();
    const { getLocation } = useLocations();

    const [showPPModal, setShowPPModal] = useState(false);
    const [showBgModal, setShowBgModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);

    const userEditBundle = useUserEdit(+(userId ?? '-1'));

    const profilePicDropzone = useDropzone({
        onDrop: userEditBundle.onDropProfilePic,
        accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] },
    });

    const backgroundDropzone = useDropzone({
        onDrop: userEditBundle.onDropBackground,
        accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] },
    });

    return (
        <OxPage>
            <PageAreaContent>
                <ContentCenterer>
                    {currentUser.userId !== userEditBundle.userPreview.id ? (
                        <>
                            <Title>You shouldn't be here...</Title>
                            <Body>This isn't your profile, so you don't have permission to edit it.</Body>
                            <Button type="small" to={`/user/${userId}`}>
                                Back
                            </Button>
                        </>
                    ) : (
                        <>
                            <FixedTitleArea>
                                <FixedTitleContent>
                                    <Title margin="0" $noWidth100>
                                        Edit Profile
                                    </Title>
                                    <Button type="primary" onClick={userEditBundle.startUpload}>
                                        Save
                                    </Button>
                                </FixedTitleContent>
                            </FixedTitleArea>
                            <div style={{ height: '85px' }}></div>
                            {userEditBundle.uploadProgress > 0 ? (
                                <>
                                    <LoadingBar fillPercent={userEditBundle.uploadProgress} />
                                    {userEditBundle.uploadProgress >= 100 && (
                                        <Button type="small" to="./">
                                            Done
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div style={{ height: 'var(--ion-safe-area-top, 0)' }}></div>
                                    <Subtitle margin="200px 0 0 0">Profile Picture</Subtitle>
                                    <ProfilePic user={userEditBundle.userPreview} size="large" />
                                    <Button type="small" onClick={() => setShowPPModal(true)}>
                                        Change Profile Picture
                                    </Button>
                                    <Subtitle margin="200px 0 0 0">Background</Subtitle>
                                    <BackgroundImagePreview image={userEditBundle.userPreview.backgroundImage} />
                                    <Button type="small" onClick={() => setShowBgModal(true)}>
                                        Change Background Image
                                    </Button>
                                    <Subtitle margin="200px 0 0 0">Basic Info</Subtitle>
                                    <FlexContainer width="100%" direction="column" height="auto">
                                        <SmallTextInput
                                            id="display-name"
                                            label="Display Name"
                                            value={userEditBundle.userPreview.displayName}
                                            setValue={userEditBundle.setName}
                                        />
                                        <FlexContainer align="center" justify="flex-start">
                                            <Body $noWidth100>
                                                Location:{' '}
                                                {getLocation(userEditBundle.userPreview.homeBaseId ?? 0)?.name ??
                                                    'None'}
                                            </Body>
                                            <Button type="small" onClick={() => setShowLocationModal(true)}>
                                                Edit
                                            </Button>
                                        </FlexContainer>
                                        <SmallTextInput
                                            id="title"
                                            label="Title"
                                            value={userEditBundle.userPreview.title ?? ''}
                                            setValue={userEditBundle.setTitle}
                                        />
                                        <SmallTextInput
                                            id="message"
                                            label="Bio"
                                            value={userEditBundle.userPreview.profileMessage}
                                            setValue={userEditBundle.setMessage}
                                        />
                                    </FlexContainer>
                                    <Subtitle margin="200px 0 0 0">Links</Subtitle>
                                    <LinkInput
                                        links={userEditBundle.userPreview.links}
                                        setLinks={userEditBundle.setLinks}
                                    />
                                </>
                            )}
                        </>
                    )}
                </ContentCenterer>
            </PageAreaContent>
            <Modal top show={showLocationModal} onClose={() => setShowLocationModal(false)}>
                <Subtitle padding="0 0 20px 0">Location</Subtitle>
                <FlexContainer direction="column">
                    <LocationSelect
                        small
                        hideGlobal
                        showHomeAtTop
                        selectedId={userEditBundle.userPreview.homeBaseId ?? 0}
                        setSelectedId={(l) => userEditBundle.setHomeBaseId(l ?? 0)}
                    />
                </FlexContainer>
            </Modal>
            <Modal top show={showPPModal} onClose={() => setShowPPModal(false)}>
                <Subtitle padding="0 0 20px 0">Upload Profile Picture</Subtitle>
                <FlexContainer width="100%" direction="column" align="center" margin="10px 0 0 0">
                    {userEditBundle.profilePic ? (
                        <>
                            <UserImage image={`url(${userEditBundle.profilePic.preview})`} size="large" />
                            <Button type="small" onClick={userEditBundle.clearProfilePic}>
                                Clear
                            </Button>
                        </>
                    ) : (
                        <Filedrop state={profilePicDropzone} />
                    )}
                </FlexContainer>
            </Modal>
            <Modal top show={showBgModal} onClose={() => setShowBgModal(false)}>
                <Subtitle padding="0 0 20px 0">Upload Background Image</Subtitle>
                <FlexContainer width="100%" direction="column" align="center" margin="10px 0 0 0">
                    {userEditBundle.background ? (
                        <>
                            <BackgroundImagePreview image={userEditBundle.background.preview} />
                            <Button type="small" onClick={userEditBundle.clearBackground}>
                                Clear
                            </Button>
                        </>
                    ) : (
                        <Filedrop state={backgroundDropzone} />
                    )}
                </FlexContainer>
            </Modal>
        </OxPage>
    );
};

export default UserEditPage;
