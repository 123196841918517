import { useState } from 'react';
import PopupBadge from '../../components/basic/popup-badge.component';
import { FlexContainer } from '../../components/components.styled';
import OxPage from '../../components/oxpage.component';
import { A, Body, Comment, Title } from '../../components/typography.styled';
import { AuthStatus } from '../../types/';
import ConfirmEmailForm from './components/confirm-email-form.component';
import CoverText from './components/cover-text.component';
import RegisterForm from './components/register-form.component';
import SignInForm from './components/sign-in-form.component';
import UserDetailsPage from './components/user-details.component';
import useAuth from '../../hooks/use-auth';

const LoginPage = () => {
    const [gotStarted, setGotStarted] = useState(false);
    const [onSignIn, setOnSignIn] = useState(false);
    const authBundle = useAuth();

    if (authBundle.state === AuthStatus.NeedsDetails) {
        return <UserDetailsPage />;
    }

    return (
        <OxPage>
            <PopupBadge show={authBundle.errorMessage !== null} severity="error">
                <Body $block>{authBundle.errorMessage}</Body>
            </PopupBadge>
            <FlexContainer
                align="center"
                justify="center"
                direction="column"
                padding="30px"
                maxWidth="600px"
                margin="auto"
            >
                {!gotStarted ? (
                    <CoverText onContinue={() => setGotStarted(true)} />
                ) : authBundle.state === AuthStatus.NeedsEmailConfirm ? (
                    <ConfirmEmailForm />
                ) : (
                    <>
                        <Title>{onSignIn ? 'Sign in' : 'Sign up'}</Title>
                        <Comment margin="0 0 64px 0">
                            {onSignIn ? 'Welcome back' : "Let's get you plugged into your audience"}
                        </Comment>
                        {onSignIn ? (
                            <>
                                <SignInForm />
                                <Body align="center" padding="30px 0 0 0">
                                    Need an account? <A onClick={() => setOnSignIn(!onSignIn)}>Sign Up</A>
                                </Body>
                            </>
                        ) : (
                            <>
                                <RegisterForm />
                                <Body align="center" padding="30px 0 0 0">
                                    Already have an account? <A onClick={() => setOnSignIn(!onSignIn)}>Sign In</A>
                                </Body>
                            </>
                        )}
                    </>
                )}
            </FlexContainer>
        </OxPage>
    );
};

export default LoginPage;
