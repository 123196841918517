import { chatbox } from 'ionicons/icons';
import { NotificationBadge, StyledIcon } from './navigation.styled';
import useNotifications from '../../hooks/use-notifications';

const ChatTabIcon = () => {
    const { newNotifications } = useNotifications();
    return (
        <>
            <StyledIcon icon={chatbox} />
            {newNotifications.length > 0 && <NotificationBadge>{newNotifications.length}</NotificationBadge>}
        </>
    );
};

export default ChatTabIcon;
