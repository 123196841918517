import { useEffect, useRef, useState } from 'react';
import Button from '../../../components/basic/button.component';
import OxPage from '../../../components/oxpage.component';
import { Title, Subtitle, Body, Comment, SmallBody } from '../../../components/typography.styled';
import {
    ContentArea,
    CoverPreview,
    CoverPreviewHolder,
    SongUploadInputPage,
    SongUploadInputPageButtonHolder,
} from '../post.styled';
import { useDropzone } from 'react-dropzone';
import useSongUpload from '../../../hooks/use-song-upload';
import SongUploadPlayback from '../../../components/music/song-upload-playback.component';
import Pagination from '../../../components/basic/pagination.component';
import SmallTextInput from '../../../components/forms/small-text-input.component';
import { arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import Checkbox from '../../../components/basic/checkbox.component';
import Filedrop from '../../../components/basic/filedrop.component';
import LoadingBar from '../../../components/basic/loading-bar.component';
import PopupBadge from '../../../components/basic/popup-badge.component';
import LinkInput from '../../../components/forms/link-input.component';
import SongCreditInput from '../../../components/forms/song-credit-input.component';
import useTags from '../../../hooks/use-tags';
import ChipInput from '../../../components/forms/chip-input.component';

const PostSongUploadPage = () => {
    const songBundle = useSongUpload();
    const [pageOn, setPageOn] = useState(0);
    const { tagAutocomplete, customTagValidation } = useTags();

    const songDropzone = useDropzone({
        onDrop: songBundle.onDrop,
        accept: { 'audio/*': ['.m4a', '.mp4', '.mp3', '.flac', '.wav', '.wma', '.aac'] },
    });

    const coverPhotoDropzone = useDropzone({
        onDrop: songBundle.onDropCover,
        accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] },
    });

    return (
        <OxPage>
            <PopupBadge show={songBundle.uploadErrorMessage !== null} severity="error">
                <Body $block>{songBundle.uploadErrorMessage}</Body>
            </PopupBadge>
            <ContentArea>
                <Title>Upload A Song</Title>
                {songBundle.file === null ? (
                    <Filedrop state={songDropzone} hint="any song file will do" />
                ) : (
                    <>
                        <SongUploadPlayback songBundle={songBundle} />
                        <Pagination pageOn={pageOn}>
                            {/* Title and Artist */}
                            <SongUploadInputPage key={0}>
                                <Subtitle margin="0 0 16px 0">Basic Info</Subtitle>
                                <SmallTextInput
                                    id="title"
                                    label="Title"
                                    value={songBundle.rawTitle}
                                    setValue={songBundle.setTitle}
                                />
                                <SmallTextInput
                                    id="artist"
                                    label="Artist"
                                    value={songBundle.artist}
                                    setValue={songBundle.setArtist}
                                />
                                <Checkbox
                                    label="Explicit"
                                    checked={songBundle.isExplicit}
                                    setChecked={songBundle.setIsExplicit}
                                />
                                <SongUploadInputPageButtonHolder>
                                    <Button
                                        type="small"
                                        disabled={songBundle.title === '' || songBundle.artist === ''}
                                        onClick={() => setPageOn(1)}
                                    >
                                        Next <IonIcon icon={arrowForwardOutline} />
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                            {/* Cover Photo */}
                            <SongUploadInputPage key={1}>
                                <Subtitle margin="0 0 16px 0">Cover Photo</Subtitle>
                                {songBundle.cover ? (
                                    <CoverPreviewHolder>
                                        <CoverPreview image={songBundle.cover.preview} />
                                        <Button type="small" onClick={songBundle.clearCover}>
                                            Clear
                                        </Button>
                                    </CoverPreviewHolder>
                                ) : (
                                    <Filedrop state={coverPhotoDropzone} hint="any image file will do" />
                                )}
                                <SongUploadInputPageButtonHolder>
                                    <Button type="small" onClick={() => setPageOn(0)}>
                                        <IonIcon icon={arrowBackOutline} /> Back
                                    </Button>
                                    <Button
                                        type="small"
                                        disabled={songBundle.cover == null}
                                        onClick={() => setPageOn(2)}
                                    >
                                        Next <IonIcon icon={arrowForwardOutline} />
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                            {/* Tags */}
                            <SongUploadInputPage key={2}>
                                <Subtitle margin="0 0 16px 0">Add Tags</Subtitle>
                                <Comment>
                                    Tags help your music find the right audience. Consider including the track's genre.
                                </Comment>
                                <ChipInput
                                    id="tags"
                                    title="Song Tags"
                                    label="Tags"
                                    values={songBundle.tags.map((t, i) => ({ id: i, value: t }))}
                                    setValues={(vals) => songBundle.setTags(vals.map((v) => v.value))}
                                    autocomplete={tagAutocomplete}
                                    customValidation={customTagValidation}
                                />
                                <SongUploadInputPageButtonHolder>
                                    <Button type="small" onClick={() => setPageOn(1)}>
                                        <IonIcon icon={arrowBackOutline} /> Back
                                    </Button>
                                    <Button type="small" onClick={() => setPageOn(3)}>
                                        Next <IonIcon icon={arrowForwardOutline} />
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                            {/* Credits */}
                            <SongUploadInputPage key={3}>
                                <Subtitle margin="0 0 16px 0">Add Credits</Subtitle>
                                <SongCreditInput credits={songBundle.credits} setCredits={songBundle.setCredits} />
                                <SongUploadInputPageButtonHolder>
                                    <Button type="small" onClick={() => setPageOn(2)}>
                                        <IonIcon icon={arrowBackOutline} /> Back
                                    </Button>
                                    <Button type="small" onClick={() => setPageOn(4)}>
                                        Next <IonIcon icon={arrowForwardOutline} />
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                            {/* Links */}
                            <SongUploadInputPage key={4}>
                                <Subtitle margin="0 0 16px 0">Add Links</Subtitle>
                                <LinkInput links={songBundle.links} setLinks={songBundle.setLinks} />
                                <SongUploadInputPageButtonHolder>
                                    <Button type="small" onClick={() => setPageOn(3)}>
                                        <IonIcon icon={arrowBackOutline} /> Back
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setPageOn(5);
                                            songBundle.startUpload();
                                        }}
                                    >
                                        Upload
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                            {/* Upload Progress */}
                            <SongUploadInputPage key={5}>
                                <div style={{ height: '100px' }} />
                                <LoadingBar fillPercent={songBundle.uploadProgress} />
                                <SongUploadInputPageButtonHolder>
                                    <Button
                                        type="small"
                                        to={`/post/song/${songBundle.songId}`}
                                        disabled={songBundle.uploadProgress < 100}
                                    >
                                        Continue to post <IonIcon icon={arrowForwardOutline} />
                                    </Button>
                                </SongUploadInputPageButtonHolder>
                            </SongUploadInputPage>
                        </Pagination>
                    </>
                )}
            </ContentArea>
        </OxPage>
    );
};

export default PostSongUploadPage;
