import { useState } from 'react';
import useLocations from '../../hooks/use-locations';
import SmallTextInput from '../forms/small-text-input.component';
import { LocationListHolder, LocationListItem, LocationListItemImage } from './posts.styled';
import { Body, Comment } from '../typography.styled';
import { IonIcon } from '@ionic/react';
import { home as homeFilled } from 'ionicons/icons';
import { FlexContainer } from '../components.styled';
import useCurrentUser from '../../hooks/use-current-user';

interface LocationSelectProps {
    selectedId: number | null;
    small?: boolean;
    hideGlobal?: boolean;
    showHomeAtTop?: boolean;
    setSelectedId: (id: number | null) => void;
}
const LocationSelect = ({ selectedId, small, hideGlobal, showHomeAtTop, setSelectedId }: LocationSelectProps) => {
    const { locations, getLocation } = useLocations();
    const [search, setSearch] = useState('');
    const currentUser = useCurrentUser();
    const homeId = currentUser.user?.homeBaseId;
    const home = getLocation(homeId ?? 0);

    return (
        <FlexContainer direction="column">
            <SmallTextInput id="location-search" label="Search Locations" value={search} setValue={setSearch} />
            <LocationListHolder $small={small}>
                {showHomeAtTop && home && homeId !== 0 && (
                    <LocationListItem
                        onClick={() => setSelectedId(homeId ?? 0)}
                        location={home}
                        selectedId={selectedId}
                        $small={small}
                    >
                        <LocationListItemImage />
                        <p>
                            <IonIcon icon={homeFilled} />
                            <Body>{home.name} </Body>
                            <Comment>• 100 Users</Comment>
                        </p>
                    </LocationListItem>
                )}
                {locations
                    .filter((l) => !hideGlobal || l.locationId > 0)
                    .filter((l) => !showHomeAtTop || l.locationId !== homeId)
                    .filter((l) => search === '' || l.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    .map((l) => (
                        <LocationListItem
                            onClick={() => setSelectedId(l.locationId)}
                            key={l.locationId}
                            location={l}
                            selectedId={selectedId}
                            $small={small}
                        >
                            <LocationListItemImage />
                            <p>
                                <Body>{l.name} </Body>
                                <Comment>• 100 Users</Comment>
                            </p>
                        </LocationListItem>
                    ))}
            </LocationListHolder>
        </FlexContainer>
    );
};

export default LocationSelect;
