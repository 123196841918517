import { useState } from "react";

import { FlexContainer } from "../../components/components.styled";
import { Body, Title } from "../../components/typography.styled";
import Button from "../../components/basic/button.component";
import TextInput from "../../components/forms/text-input.component";
import SongListItem from "../../components/music/song-list-item.component";
import OxPage from "../../components/oxpage.component";
import useSongSearch from "../../hooks/use-song-search";
import useUserSearch from "../../hooks/use-user-search";
import { ContentArea } from "../post/post.styled";
import UserListing from "../../components/user/user-listing.component";
import { SearchResultLinkArea } from "./search.styled";


const SearchPage = () => {
    const [ search, setSearch ] = useState("");
    // const results = useSongSearch(search);
    const results2 = useUserSearch(search);

    return (
        <OxPage>
            <ContentArea>
                <FlexContainer direction="column" align="center" justify="center">
                <Title>Search for a User</Title>
                    <TextInput id="search" label="Start Typing" value={search} setValue={setSearch}/>
                    {
                        search === ""
                        ?
                            <>
                                <Body align="center" margin="0 0 16px 0">(start typing to see search results)</Body>
                            </>
                        :
                            <>
                                {
                                    results2.map(u => <SearchResultLinkArea to={`/user/${u.id}`}>
                                        <UserListing
                                            key={u.id}
                                            user={u}
                                        />
                                    </SearchResultLinkArea>)
                                }
                            </>
                    }
                </FlexContainer>
            </ContentArea>
        </OxPage>
    );
};

export default SearchPage;