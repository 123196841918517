import { useCallback, useState } from 'react';
import { compressionOptions, convertIfHeic } from '../utils/image.util';
import imageCompression from 'browser-image-compression';
import { post, postFile } from '../services/api';
import useAuth from './use-auth';
import { SongUploadRequest } from '../types/requests/song-upload-request';
import { Song, SongCredit } from '../types';
import useSongHandler from './use-song-handler';

export interface SongUploadBundle {
    file: File | null;
    title: string;
    rawTitle: string;
    setTitle: (value: string) => void;
    artist: string;
    setArtist: (value: string) => void;
    isExplicit: boolean;
    setIsExplicit: (value: boolean) => void;
    cover: ImageFileBundle | null;
    tags: string[];
    setTags: (t: string[]) => void;
    links: string[];
    setLinks: (l: string[]) => void;
    credits: SongCredit[];
    setCredits: (l: SongCredit[]) => void;
    onDrop: (files: File[]) => void;
    clearSong: () => void;
    onDropCover: (files: File[]) => void;
    clearCover: () => void;
    togglePlaying: () => void;
    startUpload: () => void;
    isUploading: boolean;
    uploadProgress: number;
    uploadErrorMessage: string | null;
    songId: number | null;
}

interface SongFileBundle {
    file: File;
    title: string;
}

interface ImageFileBundle {
    preview: string;
    file: File;
    title: string;
}

const useSongUpload = (): SongUploadBundle => {
    const [songFile, setSongFile] = useState<SongFileBundle | null>(null);
    const [coverFile, setCoverFile] = useState<ImageFileBundle | null>(null);
    const [title, setTitle] = useState<string>('');
    const [artist, setArtist] = useState<string>('');
    const [isExplicit, setIsExplicit] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>([]);
    const [links, setLinks] = useState<string[]>([]);
    const [credits, setCredits] = useState<SongCredit[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [songObject, setSongObject] = useState<Song | null>(null);

    const auth = useAuth();

    const { togglePlaying, setSongByFile } = useSongHandler();

    const onDrop = useCallback(
        (files: File[]) => {
            if (files.length > 0) {
                var file = files[0] as File;
                setSongByFile(file);
                setSongFile({
                    file,
                    title: file.name,
                });
            }
        },
        [setSongByFile],
    );
    const clearSong = () => {
        setSongFile(null);
    };

    const onDropCover = useCallback((files: File[]) => {
        if (files.length !== 1) return;
        //setIsProcessing(true);
        Promise.all(files.map((f) => convertIfHeic(f).then((f) => imageCompression(f, compressionOptions))))
            .then((out) => {
                const file = out[0];
                setCoverFile({
                    preview: URL.createObjectURL(file),
                    file: file,
                    title: file.name,
                });
            })
            .catch((err) => {
                console.log(err);
            });
        //.finally(() => setIsProcessing(false));
    }, []);
    const clearCover = () => {
        setCoverFile(null);
    };

    const startUpload = () => {
        if (!songFile) return;
        if (!coverFile) return;
        const body: SongUploadRequest = {
            title: title,
            artist: artist,
            links: links,
            credits: credits.map((c) => ({ role: c.role, name: c.name, userId: c.user?.id })),
            tags: tags,
            isExplicit: isExplicit,
            canAnyoneRepost: true,
        };
        setUploadProgress(25);
        post<Song>('/song', body, auth.auth)
            .then((s) => {
                console.log(s);
                if (s.hadError) {
                    setUploadError(s.errorMessage ?? 'Something went wrong');
                    return;
                }
                setUploadProgress(50);
                postFile<Song>(`/song/${s.data?.songId}/file`, songFile.file, auth.auth)
                    .then((a) => {
                        console.log(a);
                        if (a.hadError) {
                            setUploadError(a.errorMessage ?? 'Something went wrong');
                            return;
                        }
                        setUploadProgress(75);
                        postFile<Song>(`/song/${s.data?.songId}/cover`, coverFile.file, auth.auth)
                            .then((b) => {
                                console.log(b);
                                if (b.hadError) {
                                    setUploadError(b.errorMessage ?? 'Something went wrong');
                                    return;
                                }
                                setUploadProgress(100);
                                setSongObject(b.data);
                            })
                            .catch((e) => {
                                console.log(e);
                                setUploadError('Something went wrong');
                            });
                    })
                    .catch((e) => {
                        console.log(e);
                        setUploadError('Something went wrong');
                    });
            })
            .catch((e) => {
                console.log(e);
                setUploadError('Something went wrong');
            });
    };

    return {
        file: songFile?.file ?? null,
        title: title && title !== '' ? title : songFile?.title ?? '',
        rawTitle: title,
        setTitle,
        artist,
        setArtist,
        isExplicit,
        setIsExplicit,
        cover: coverFile,
        tags,
        setTags,
        links,
        setLinks,
        credits,
        setCredits,
        onDrop,
        clearSong,
        onDropCover,
        clearCover,
        togglePlaying,
        startUpload,
        isUploading: uploadProgress > 0,
        uploadProgress,
        uploadErrorMessage: uploadError,
        songId: songObject?.songId ?? null,
    };
};

export default useSongUpload;
