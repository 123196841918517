import { SongCredit } from "../../types";
import { SmallBody, Comment } from "../typography.styled";
import ProfilePic from "../user/profile-pic.component";
import { SongCreditItemLinkArea, SongCreditItemButtonArea, SongCreditListArea } from "./music.styled";

interface SongCreditListProps {
    credits: SongCredit[];
    closeDrawer: () => void;
};
const SongCreditList = ({ credits, closeDrawer }: SongCreditListProps) => {
    return (
        <SongCreditListArea>
            {credits.map((c,i) =>
                c.user
                ?
                    <SongCreditItemLinkArea key={i} to={`/user/${c.user?.id}`} onClick={closeDrawer}>
                        <ProfilePic user={c.user} size="tiny"/>
                        <SmallBody>
                            {c.user.displayName}
                            <Comment>
                                {` ● ${c.role}`}
                            </Comment>
                        </SmallBody>
                    </SongCreditItemLinkArea>
                :
                    <SongCreditItemButtonArea key={i}>
                        <SmallBody>
                            {c.name ?? ''}
                            <Comment>
                                {` ● ${c.role}`}
                            </Comment>
                        </SmallBody>
                    </SongCreditItemButtonArea>
            )}
        </SongCreditListArea>
    );
};

export default SongCreditList;