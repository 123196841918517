import { BadgeSize } from '../../types/basic';
import { UserStub } from '../../types';
import { AdminImage, UserDefaultBadge, UserImage } from './user.styled';
import adminUserImage from '../../assets/admin.png';

const getInitials = (name: string): string => {
    var abriv = '';
    if (name.length <= 2) abriv = name.toUpperCase();
    else {
        abriv = name
            .split(' ')
            .map((w) => (w.length > 0 ? w[0] : ''))
            .join('')
            .toUpperCase();
    }
    return abriv;
};

interface ProfilePicProps {
    user?: UserStub | null;
    isAdminUser?: boolean;
    size: BadgeSize;
}
const ProfilePic = ({ user, isAdminUser, size }: ProfilePicProps) => {
    //if (!user || (!user.profilePic && !user.userName)) return
    if (isAdminUser) return <AdminImage size={size} src={adminUserImage} />;
    if (user?.profilePicUrl) return <UserImage size={size} image={`url(${user.profilePicUrl})`} />;
    return (
        <UserDefaultBadge size={size} userId={user?.id || -1}>
            {getInitials(user?.username || '')}
        </UserDefaultBadge>
    );
};

export default ProfilePic;
