import { ReactNode, createContext } from 'react';
import { Song, SongState } from '../types';
import useSongContext from '../hooks/context/use-song-context';
import { AudioPlayer } from 'react-use-audio-player';

export interface SongContextType {
    state: SongState;
    audio?: AudioPlayer;
    song?: Song;
    songData?: string;
    startPos?: number;
    autoplay: boolean;
    setAutoplay: (v: boolean) => void;
    setSong: (s: Song | null, pos?: number) => void;
    setSongById: (id: number, pos?: number) => void;
    setSongByFile: (f: File) => void;
    togglePlaying: () => void;
    setStartPos: (v?: number) => void;
}

export const SongContext = createContext<SongContextType>({
    state: SongState.None,
    autoplay: true,
    setAutoplay: () => {},
    setSong: () => {},
    setSongById: () => {},
    setSongByFile: () => {},
    togglePlaying: () => {},
    setStartPos: () => {},
});

const SongContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useSongContext();
    return <SongContext.Provider value={store}>{children}</SongContext.Provider>;
};

export default SongContextProvider;
