import { IonIcon } from '@ionic/react';
import { FloatingActionButton } from '../basic/basic.styled';
import { volumeHighOutline, volumeMuteOutline } from 'ionicons/icons';
import useSongHandler from '../../hooks/use-song-handler';

const AutoplayButton = () => {
    const { autoplay, setAutoplay } = useSongHandler();

    return (
        <FloatingActionButton right={80} onClick={() => setAutoplay(!autoplay)}>
            <IonIcon icon={autoplay ? volumeHighOutline : volumeMuteOutline} />
        </FloatingActionButton>
    );
};

export default AutoplayButton;
