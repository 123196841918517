import { useEffect, useState } from 'react';
import { Post } from '../types';
import useAuth from './use-auth';
import { get } from '../services/api';

interface PostDetailsBundle {
    post: Post | null;
    loading: boolean;
    error?: string | null;
}

const usePostDetails = (postId: number): PostDetailsBundle => {
    const [post, setPost] = useState<Post | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const auth = useAuth();

    useEffect(() => {
        console.log('hhhhh');
        setLoading(true);
        get<Post>(`/post/${postId}`, auth.auth)
            .then((res) => {
                console.log(res);
                if (res.data) {
                    setPost(res.data);
                }
            })
            .catch((e) => {
                console.log(e);
                setError('Something went wrong.');
            })
            .finally(() => setLoading(false));
    }, [auth?.auth, postId]);

    return {
        post,
        loading,
        error,
    };
};

export default usePostDetails;
