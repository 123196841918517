import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import relativeTime from 'dayjs/plugin/relativeTime';
import { Comment as CommentText, SmallBody } from '../../../components/typography.styled';
import ProfilePic from '../../../components/user/profile-pic.component';
import { Comment } from '../../../types';
import {
    CommentAreaLine,
    CommentBody,
    CommentFadeInArea,
    CommentHeader,
    CommentLayout,
    CommentSendButton,
} from '../post-details.styled';
import { FlexContainer } from '../../../components/components.styled';
import { CommentButton, CommentLikeButton, LikeButtonPulse } from '../../../components/feeds/feed.styled';
import { PostInteractionBundle } from '../../../hooks/use-post-interaction';
import { StyledIcon } from '../../../components/navigation/navigation.styled';
import { chatbubblesOutline, heart, heartOutline, send } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import SmallTextInput from '../../../components/forms/small-text-input.component';

interface PostCommentProps {
    comment: Comment;
    interact: PostInteractionBundle;
    isChild?: boolean;
    preventInteract?: boolean;
}
const PostComment = ({ comment, interact, isChild, preventInteract }: PostCommentProps) => {
    const reacted = interact.reactedToComment(comment.postCommentId);
    const reactionCount = interact.commentReactCount(comment.postCommentId);
    const showReply = interact.commentComposingId === comment.postCommentId;
    return (
        <CommentLayout $isChild={isChild}>
            <CommentHeader>
                <ProfilePic user={comment.user} size="small" />
                <CommentText margin="0 0 0 6px">
                    {comment.user.displayName ?? comment.user.username} • {dayjs(comment.createdAt).fromNow()}
                </CommentText>
            </CommentHeader>
            <CommentBody $isChild={isChild}>
                <CommentAreaLine />
                <FlexContainer direction="column">
                    <SmallBody>{comment.body}</SmallBody>
                    <FlexContainer direction="row" justify="flex-start">
                        {!preventInteract && (
                            <CommentLikeButton
                                reacted={reacted}
                                onClick={() => interact.reactToComment(comment.postCommentId)}
                            >
                                {reacted && <LikeButtonPulse></LikeButtonPulse>}
                                <StyledIcon icon={reacted ? heart : heartOutline} style={{ fontSize: '16px' }} />
                                <CommentText $noWidth100 margin="0 0 0 6px">
                                    {reactionCount}
                                </CommentText>
                            </CommentLikeButton>
                        )}
                        {!showReply && !isChild && !preventInteract && (
                            <CommentButton onClick={() => interact.setCommentComposingId(comment.postCommentId)}>
                                <StyledIcon icon={chatbubblesOutline} style={{ fontSize: '16px' }} />
                                <CommentText $noWidth100 margin="0 0 0 6px">
                                    Reply
                                </CommentText>
                            </CommentButton>
                        )}
                    </FlexContainer>
                    {showReply && !isChild && !preventInteract && (
                        <FlexContainer>
                            <SmallTextInput
                                $autofocus
                                $multiline
                                id="comment-reply"
                                label="Reply"
                                value={interact.comment}
                                setValue={interact.setComment}
                            />
                            <CommentSendButton onClick={() => interact.submitComment()}>
                                <IonIcon icon={send} />
                            </CommentSendButton>
                        </FlexContainer>
                    )}
                    {interact.myComments
                        .filter((c) => c.parentId === comment.postCommentId)
                        .map((myComment, index) => (
                            <CommentFadeInArea key={-1 - index}>
                                <PostComment isChild preventInteract comment={myComment.comment} interact={interact} />
                            </CommentFadeInArea>
                        ))}
                    {comment.childComments &&
                        comment.childComments.map((cc, i) => (
                            <PostComment key={i} isChild comment={cc} interact={interact} />
                        ))}
                </FlexContainer>
            </CommentBody>
        </CommentLayout>
    );
};

export default PostComment;
