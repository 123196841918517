import { FormEvent, ReactNode } from "react";
import { StyledForm } from "./form.styled";

interface FormProps {
    onSubmit: () => void;
    children?: ReactNode;
};
const Form = ({ onSubmit, children }: FormProps) => {
    const submitAction = (e: FormEvent) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <StyledForm onSubmit={submitAction}>
            {children}
        </StyledForm>
    );
};

export default Form;