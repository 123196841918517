import { IonIcon } from "@ionic/react";
import { StyledChip, StyledChipDelete, StyledChipHolderArea, StyledChipInputArea, StyledChipSuggestion, StyledChipSuggestionArea, StyledChipText, StyledLabel, StyledLabelText, StyledSmallLabel, StyledSmallLabelText, StyledSmallTextInput, StyledTextArea, StyledTextInput } from "./form.styled";
import { closeOutline } from "ionicons/icons";
import Modal from "../basic/modal.component";
import { useEffect, useMemo, useRef, useState } from "react";
import { Body, Comment, SmallBody, Subtitle } from "../typography.styled";
import { FlexContainer } from "../components.styled";
import SmallTextInput from "./small-text-input.component";
import useDebounce from "../../hooks/use-debounce";

interface ChipInputProps {
    id: string;
    title?: string;
    subtitle?: string;
    label: string;
    values: { id: number, value: string }[];
    setValues: (v: { id: number, value: string }[]) => void;
    autocomplete?: (v: string, callback: (data: { value: { id: number, value: string }, comment: string }[]) => void) => void;
    customValidation?: (values: { id: number, value: string }[], v: string) => { id: number, value: string } | null;
};
const ChipInput = ({ id, title, subtitle, label, values, setValues, autocomplete, customValidation }: ChipInputProps) => {
    const [ editing, setEditing ] = useState(false);
    const [ search, setSearch ] = useState("");
    const [ suggestions, setSuggestions ] = useState<{ value: { id: number, value: string }, comment: string }[]>([]);

    const inputElement = useRef<HTMLInputElement>(null);

    useDebounce(() => {
            if (!search || search === "") setSuggestions([]);
            else if (autocomplete) autocomplete(search, data => setSuggestions(data));
        }, [ search ], 300
    );

    useEffect(() => {
        if (editing && inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
        }
    }, [ editing ]);

    const removeChip = (id: number) => {
        const filtered = values.filter(v => v.id !== id);
        if (filtered.length !== values.length) {
            setValues(filtered);
        }
    };
    const addChip = (chip: { id: number, value: string }) => {
        if (values.findIndex(v => chip.id === v.id) >= 0) return;
        values.push(chip);
        setSearch("");
        if (inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
        }
    };
    const enterCustomChip = (value: string) => {
        if (value === "") {
            setEditing(false);
            return;
        }
        if (!customValidation) return;
        const chip = customValidation(values, value);
        if (!chip) return;
        values.push(chip);
        setSearch("");
        return;
    };

    return (
        <>
            <StyledLabel htmlFor={id} $hasInput={(values && values.length > 0) ? true : false} style={{marginTop:"10px"}}>
                <StyledLabelText>{label}</StyledLabelText>
                <StyledChipInputArea id={id} onClick={() => setEditing(true)}>
                    { values.map((v) => <StyledChip key={v.id} onClick={e => e.stopPropagation()}>
                        <StyledChipText>{v.value}</StyledChipText>
                        <StyledChipDelete onClick={() => removeChip(v.id)}><IonIcon icon={closeOutline}/></StyledChipDelete>
                    </StyledChip>)}
                </StyledChipInputArea>
            </StyledLabel>
            <Modal top show={editing} onClose={() => setEditing(false)}>
                <h2><Subtitle>{title}</Subtitle></h2>
                <p><Comment>{subtitle}</Comment></p>
                <FlexContainer direction="column">
                    <StyledChipHolderArea>
                        { values.map((v) => <StyledChip key={v.id}>
                            <StyledChipText>{v.value}</StyledChipText>
                            <StyledChipDelete onClick={() => removeChip(v.id)}><IonIcon icon={closeOutline}/></StyledChipDelete>
                        </StyledChip>)}
                    </StyledChipHolderArea>
                    <StyledSmallLabel htmlFor={id+"_search"} $hasInput={search ? true : false}>
                        <StyledSmallLabelText>{label}</StyledSmallLabelText>
                        <StyledSmallTextInput
                            type="text"
                            id={id}
                            name={id}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            onKeyDown={e => { if (e.key === 'Enter') enterCustomChip(search); }}
                            ref={inputElement}
                        />
                    </StyledSmallLabel>
                    <StyledChipSuggestionArea>
                        { suggestions.map(s =>
                            <StyledChipSuggestion key={s.value.id} onClick={() => addChip(s.value)}>
                                <SmallBody>{s.value.value} </SmallBody>
                                <Comment>• {s.comment}</Comment>
                            </StyledChipSuggestion>) }
                    </StyledChipSuggestionArea>
                </FlexContainer>
            </Modal>
        </>
    );
};

export default ChipInput;