import styled from 'styled-components';
import { addAlpha, darkenColor, lightenColor } from '../../utils/color.util';
import { LocationDetail } from '../../types/location/location-detail';

interface LocationListHolderProps {
    $small?: boolean;
}
export const LocationListHolder = styled.div<LocationListHolderProps>`
    width: 100%;
    height: 100%;
    min-height: 200px;
    max-height: ${(props) => (props.$small ? '25vh' : '50vh')};
    border-radius: ${(props) => props.theme.border.radius.large};
    overflow-y: scroll;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.3)};
`;

interface LocationListItemProps {
    location: LocationDetail;
    selectedId: number | null;
    $small?: boolean;
}
export const LocationListItem = styled.button<LocationListItemProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${(props) => (props.$small ? '30px' : '60px')};
    outline: none;
    border: none;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0)};
    text-align: left;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.5)};
        div {
            width: 30%;
            margin-right: -20px;
        }
    }
    &:nth-child(odd) > div {
        background-color: ${(props) => props.theme.color.primary.dark};
    }
    ${(props) =>
        props.location.locationId === props.selectedId
            ? `
        & > div {
            background-color: ${props.theme.color.primary.dark};
            width: 100% !important;
            margin-right: -70% !important;
            opacity: 1;
        }
    `
            : ''}
    ${(props) =>
        props.selectedId !== null && props.location.locationId !== props.selectedId
            ? `
        & > p {
            opacity: 0.6;
        }
    `
            : ''}
    & > p > ion-icon {
        color: ${(props) => props.theme.color.base.contrast};
        font-size: 16px;
        margin-right: 6px;
        position: relative;
        top: 2px;
    }
`;

export const LocationListItemImage = styled.div`
    background-color: ${(props) => props.theme.color.primary.main};
    height: 100%;
    width: 20%;
    margin-right: 10px;
    transition: width 0.4s, margin-right 0.4s;
    opacity: 0.4;
`;

interface ReportReasonChipProps {
    $selected: boolean;
}
export const ReportReasonChip = styled.button<ReportReasonChipProps>`
    border-radius: 100px;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.padding.small};
    outline: none;
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    color: ${(props) => props.theme.color.base.contrast};
    background-color: ${(props) => (props.$selected ? props.theme.color.error.main : props.theme.color.base.dark)};
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) =>
            props.$selected
                ? darkenColor(props.theme.color.error.main, 0.2)
                : lightenColor(props.theme.color.base.dark, 0.2)};
    }
`;
