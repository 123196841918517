import { NotificationExpanded } from '../../types/notification/notification-expanded';
import { SmallBody } from '../typography.styled';
import { BubbleTimestamp, ChatBubbleHolder, ChatBubbleReceived, ChatBubbleSent } from './messages.styled';

const GAP_SECONDS = 120;
const TIMESTAMP_SECONDS = 900;
const DAY_SECONDS = 86400;

interface ChatBubbleProps {
    message: NotificationExpanded;
}
const ChatBubble = ({ message }: ChatBubbleProps) => {
    return (
        <ChatBubbleHolder
            $showSpace={
                !message.isSameSender ||
                (message.timeGapSeconds > GAP_SECONDS && message.timeGapSeconds <= TIMESTAMP_SECONDS)
            }
        >
            {message.timeGapSeconds > TIMESTAMP_SECONDS && (
                <BubbleTimestamp>
                    {message.timeGapSeconds > DAY_SECONDS
                        ? message.time.format()
                        : message.time.fromNow().toLocaleLowerCase()}
                </BubbleTimestamp>
            )}
            {message.isSent ? (
                <ChatBubbleSent>
                    <SmallBody color="inherit">{message.body}</SmallBody>
                </ChatBubbleSent>
            ) : (
                <ChatBubbleReceived>
                    <SmallBody color="inherit">{message.body}</SmallBody>
                </ChatBubbleReceived>
            )}
        </ChatBubbleHolder>
    );
};

export default ChatBubble;
