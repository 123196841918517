import OxPage from '../../components/oxpage.component';
import { Body, Title } from '../../components/typography.styled';
import useMessageList from '../../hooks/use-message-list';
import { ContentArea } from './chat.styled';
import MessageListItem from '../../components/messages/message-list-item.component';
import BetaFeedback from '../../components/messages/beta-feedback.component';

const ChatListPage = () => {
    const messages = useMessageList();

    const notifications = messages.messages.filter((m) => m.chatId < 0);
    const chats = messages.messages.filter((m) => m.chatId > 0);

    return (
        <OxPage>
            <ContentArea>
                <Title>Notifications</Title>
                <BetaFeedback />
                {notifications.map((chat) => (
                    <MessageListItem key={chat.chatId} chat={chat} isUnread={messages.isUnread(chat)} />
                ))}
                {chats.length > 0 && <Body style={{ marginLeft: '20px', marginTop: '20px' }}>Messages</Body>}
                {chats.map((chat) => (
                    <MessageListItem key={chat.chatId} chat={chat} isUnread={messages.isUnread(chat)} />
                ))}
            </ContentArea>
        </OxPage>
    );
};

export default ChatListPage;
