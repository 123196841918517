import { ReactNode, createContext } from "react";
import { LocationDetail } from "../types/location/location-detail";
import useCommonData from "../hooks/context/use-common-data";

export interface CommonDataContextType {
    locations: LocationDetail[];
};

export const CommonDataContext = createContext<CommonDataContextType>({
    locations: []
});

const CommonDataContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useCommonData();
    return (
        <CommonDataContext.Provider value={store}>
            {children}
        </CommonDataContext.Provider>
    );
};

export default CommonDataContextProvider;