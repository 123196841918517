export const padWithZeros = (num: number, size: number): string => {
    let nums = num.toFixed(0);
    while (nums.length < size) nums = '0' + nums;
    return nums;
};

export const secondsToTimestamp = (sec: number): string => {
    return `${padWithZeros(Math.floor(sec / 60), 1)}:${padWithZeros(Math.round(sec % 60), 2)}`;
};

const tagTest = /[^\w.-_]/;

export const getWordAtIndex = (text: string, index: number): string | null => {
    // console.log(`pos: ${index}, length: ${text.length}`);
    if (index < 0 || index > text.length) {
        return null;
    }
    let startIndex = Math.max(index - 1, 0);
    let endIndex = index - 1;

    while (startIndex >= 0 && !tagTest.test(text[startIndex])) {
        startIndex--;
    }
    if (['#', '@'].indexOf(text[startIndex]) < 0) startIndex++;
    while (endIndex < text.length && !tagTest.test(text[endIndex])) {
        endIndex++;
    }

    // console.log(`pos: ${index}, start: ${startIndex}, end: ${endIndex}, word: ${text.substring(startIndex, endIndex)}`);
    const word = text.substring(startIndex, endIndex);
    return word;
};

export const replaceWordAtIndex = (text: string, index: number, tag: string): string | null => {
    // console.log(`pos: ${index}, length: ${text.length}`);
    if (index < 0 || index > text.length) {
        return null;
    }
    let startIndex = Math.max(index - 1, 0);
    let endIndex = index - 1;

    while (startIndex >= 0 && !tagTest.test(text[startIndex])) {
        startIndex--;
    }
    if (['#', '@'].indexOf(text[startIndex]) < 0) startIndex++;
    while (endIndex < text.length && !tagTest.test(text[endIndex])) {
        endIndex++;
    }
    // console.log(`pos: ${index}, start: ${startIndex}, end: ${endIndex}, word: ${text.substring(startIndex, endIndex)}`);

    return text.substring(0, startIndex) + tag + text.substring(endIndex);
};
