
export const addAlpha = (color: string, opacity: number): string => {
    // coerce values so ti is between 0 and 1.
    if (color.startsWith("rgb(")) {
        return `rgba(${color.substring(color.indexOf("("), color.lastIndexOf(")") - 1)}, ${opacity})`
    }
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    if (_opacity === 0) return color + "00";
    return color + _opacity.toString(16).toUpperCase();
};

export const wash = (color: string): string => {
    return addAlpha(color, 0.4);
};

const componentToHex = (c:number): string => {
    const component = Math.round(Math.min(Math.max(c, 0), 255));
    const hex = component.toString(16).toUpperCase();
    return hex.length === 1 ? "0" + hex : hex;
};
const alphaComponentToHex = (a:number): string => {
    const opacity = Math.round(Math.min(Math.max(a, 0), 1) * 255);
    const hex = opacity.toString(16).toUpperCase();
    return hex.length === 1 ? "0" + hex : hex;
};
const hexToComponent = (h:string): number => {
    if (h.length === 1) {
        var c = parseInt(h+h, 16);
        return c;
    }
    return parseInt(h, 16);
};
const hexToAlphaComponent = (h:string): number => {
    if (h.length === 1) {
        var c = parseInt(h+h, 16);
        return c / 256;
    }
    return parseInt(h, 16) / 256;
};

export const getRGBA = (color: string): number[] => {
    if (color.startsWith("rgb(")) {
        return color
            .substring(color.indexOf("("), color.lastIndexOf(")") - 1)
            .split(",")
            .map(v => +(v.trim()))
            .concat([ 1 ]);
    }
    if (color.startsWith("rgba(")) {
        return color
            .substring(color.indexOf("("), color.lastIndexOf(")") - 1)
            .split(",")
            .map(v => +v);
    }
    if (color.startsWith("#") && color.length === 4) {
        return Array.from(color.substring(1))
            .map(c => hexToComponent(c))
            .concat([ 1 ]);
    }
    if (color.startsWith("#") && color.length === 7) {
        return (color.substring(1).match(/.{1,2}/g) as string[])
            .map(c => hexToComponent(c))
            .concat([ 1 ]);
    }
    if (color.startsWith("#") && color.length === 5) {
        return Array.from(color.substring(1))
            .map((c,i) => i === 3 ? hexToAlphaComponent(c) : hexToComponent(c));
    }
    if (color.startsWith("#") && color.length === 9) {
        return (color.substring(1).match(/.{1,2}/g) as string[])
        .map((c,i) => i === 3 ? hexToAlphaComponent(c) : hexToComponent(c));
    }
    return [0,0,0,0];
};

export const getHex = (components: number[]): string => {
    return "#" + components
        .map((c,i) => i === 3 ? alphaComponentToHex(c) : componentToHex(c))
        .join("");
};

export const interpolateColor = (c1: string, c2: string, r: number): string => {
    const comp1 = getRGBA(c1);
    const comp2 = getRGBA(c2);
    const comp = comp1.map((c,i) => Math.max(Math.min(c + (comp2[i] - c) * r, 255), 0));
    return getHex(comp);
};

export const lightenColor = (c: string, l: number): string => {
    return interpolateColor(c, "#fff", l);
};

export const darkenColor = (c: string, d: number): string => {
    return interpolateColor(c, "#000", d);
};