import styled from "styled-components";

import { addAlpha } from "../../utils/color.util";
import { Link } from "react-router-dom";

export const ContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${props => props.theme.margin.huge});
    max-width: 600px;
    margin: ${props => props.theme.margin.huge} auto;
    align-items: center;
`;

export const SearchResultLinkArea = styled(Link)`
    width: 100%;
    padding: ${props => props.theme.padding.medium} ${props => props.theme.padding.large};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px rgba(255,255,255,0.1);
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(255,255,255,0.05);
    }
    &:active {
        transition: background-color 0s;
        background-color: rgba(255,255,255,0.1);
    }
    &:last-child {
        border-bottom: none;
    }

`;