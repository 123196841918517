import { IonIcon } from '@ionic/react';
import {
    FeedControlCollapseArea,
    FeedSelectRow,
    FeedOptionButtonSimple,
    FeedSortButtonHolder,
    FeedSortButton,
} from './feed.styled';
import { locationSharp, headset, sparkles, flame, shuffle } from 'ionicons/icons';
import { FeedOptions, FeedSortMode } from '../../types';
import useLocations from '../../hooks/use-locations';
import Modal from '../basic/modal.component';
import { useEffect, useRef, useState } from 'react';
import { SmallBody, Subtitle, Comment } from '../typography.styled';
import LocationSelect from '../posts/location-select.component';
import { FlexContainer } from '../components.styled';
import {
    StyledChipSuggestion,
    StyledChipSuggestionArea,
    StyledSmallLabel,
    StyledSmallLabelText,
    StyledSmallTextInput,
} from '../forms/form.styled';
import useDebounce from '../../hooks/use-debounce';
import useTags from '../../hooks/use-tags';
import Checkbox from '../basic/checkbox.component';
import { matchesAnyFavoriteFeed } from '../../utils/feed.util';
import useCurrentUser from '../../hooks/use-current-user';

enum ModalOption {
    Location = 0,
    Filter = 1,
}

interface FeedOptionsSelectProps {
    options: FeedOptions;
    setOptions: (o: FeedOptions) => void;
    toggleFavorite: (o: FeedOptions) => void;
}
const FeedOptionsSelect = ({ options, setOptions, toggleFavorite }: FeedOptionsSelectProps) => {
    const currentUser = useCurrentUser();
    const [modalOption, setModalOption] = useState<ModalOption | null>(null);

    const { getLocation } = useLocations();

    const [search, setSearch] = useState(options.tags[0] ?? '');
    const [suggestions, setSuggestions] = useState<{ value: { id: number; value: string }; comment: string }[]>([]);
    const inputElement = useRef<HTMLInputElement>(null);
    const { tagAutocomplete, customTagValidation } = useTags();
    useDebounce(
        () => {
            if (!search || search === '') setSuggestions([]);
            else tagAutocomplete(search, (data) => setSuggestions(data));
        },
        [search],
        300,
    );
    useEffect(() => {
        if (modalOption === ModalOption.Filter && inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
        }
    }, [modalOption]);

    const setFilter = (f: string) => {
        setOptions({ ...options, tags: [customTagValidation([], f)?.value ?? ''] });
        setModalOption(null);
    };
    const selectLocation = (id: number | null) => {
        setOptions({ ...options, locationId: id ?? 0 });
        setModalOption(null);
    };

    return (
        <FeedControlCollapseArea $show>
            <Modal top show={modalOption === ModalOption.Location} onClose={() => setModalOption(null)}>
                <FlexContainer direction="column">
                    <Subtitle>Select Location</Subtitle>
                    <LocationSelect small selectedId={options.locationId} setSelectedId={selectLocation} />
                </FlexContainer>
            </Modal>
            <Modal top show={modalOption === ModalOption.Filter} onClose={() => setModalOption(null)}>
                <FlexContainer direction="column">
                    <Subtitle>Select Filter</Subtitle>
                    <StyledSmallLabel htmlFor="tag-search" $hasInput={search ? true : false}>
                        <StyledSmallLabelText>Search</StyledSmallLabelText>
                        <StyledSmallTextInput
                            type="text"
                            id="tag-search"
                            name="tag-search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') setFilter(search);
                            }}
                            ref={inputElement}
                        />
                    </StyledSmallLabel>
                    <StyledChipSuggestionArea>
                        {suggestions.map((s) => (
                            <StyledChipSuggestion key={s.value.id} onClick={() => setFilter(s.value.value)}>
                                <SmallBody>{s.value.value} </SmallBody>
                                <Comment>• {s.comment}</Comment>
                            </StyledChipSuggestion>
                        ))}
                    </StyledChipSuggestionArea>
                </FlexContainer>
            </Modal>
            <FeedSelectRow>
                <IonIcon icon={locationSharp} />
                <FeedOptionButtonSimple onClick={() => setModalOption(ModalOption.Location)}>
                    {getLocation(options.locationId)?.name ?? 'Global'}
                </FeedOptionButtonSimple>
            </FeedSelectRow>
            <FeedSelectRow>
                <IonIcon icon={headset} />
                <FeedOptionButtonSimple
                    onClick={() => setModalOption(ModalOption.Filter)}
                    $gray={options.tags.length === 0}
                >
                    {options.tags[0] ?? '- no filters selected -'}
                </FeedOptionButtonSimple>
            </FeedSelectRow>
            <FeedSelectRow style={{ marginTop: '24px' }}>
                <Checkbox
                    checked={matchesAnyFavoriteFeed(options, currentUser.user?.favoriteFeeds ?? [])}
                    setChecked={() => toggleFavorite(options)}
                    label="Favorite"
                />
            </FeedSelectRow>
            <FeedSelectRow>
                <FeedSortButtonHolder>
                    <FeedSortButton
                        $selected={options.sortMode === FeedSortMode.New}
                        onClick={() => setOptions({ ...options, sortMode: FeedSortMode.New })}
                    >
                        <IonIcon icon={sparkles} />
                        <SmallBody>New</SmallBody>
                    </FeedSortButton>
                    <FeedSortButton
                        $selected={options.sortMode === FeedSortMode.Trending}
                        onClick={() => setOptions({ ...options, sortMode: FeedSortMode.Trending })}
                    >
                        <IonIcon icon={flame} />
                        <SmallBody>Trending</SmallBody>
                    </FeedSortButton>
                    <FeedSortButton
                        $selected={options.sortMode === FeedSortMode.Random}
                        onClick={() => setOptions({ ...options, sortMode: FeedSortMode.Random })}
                    >
                        <IonIcon icon={shuffle} />
                        <SmallBody>Random</SmallBody>
                    </FeedSortButton>
                </FeedSortButtonHolder>
            </FeedSelectRow>
        </FeedControlCollapseArea>
    );
};

export default FeedOptionsSelect;
