import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

interface TabBarAreaProps {
    $expanded: boolean;
}
export const TabBarArea = styled.div<TabBarAreaProps>`
    width: 100vw;
    background-color: ${(props) => props.theme.color.base.main};
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 100;
    transition: height 0.3s ease;
    height: ${(props) =>
        props.$expanded
            ? 'calc(100% - max(8px, var(--ion-safe-area-bottom, 0)) - max(100px, var(--ion-safe-area-top, 0)))'
            : 'calc(60px + max(24px, var(--ion-safe-area-bottom, 0)))'};
`;

export const TabLinksArea = styled.nav`
    display: flex;
    position: fixed;
    bottom: 0;
    padding-bottom: max(24px, var(--ion-safe-area-bottom, 0));
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: calc(50px + max(24px, var(--ion-safe-area-bottom, 0)));
`;

export const TabButton = styled(Link)`
    display: block;
    color: white;
    font-size: 32px;
    width: 100%;
    max-width: 180px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const StyledIcon = styled(IonIcon)`
    pointer-events: none;
`;
export const NotificationBadge = styled.div`
    background-color: ${(props) => props.theme.color.primary.main};
    color: ${(props) => props.theme.color.primary.contrast} !important;
    min-width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 999px;
    margin-left: 24px;
    top: 6px;
    font-size: 14px;
    padding: 0 5px 0 7px;
`;

export const CenterSpacer = styled.button<TabBarAreaProps>`
    display: block;
    color: white;
    font-size: 42px;
    width: 100px;
    min-width: 100px;
    height: 100%;
    margin: 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s, opacity 0.2s;
    opacity: ${(props) => (props.$expanded ? '1' : '0')};
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const SlideDrawerArea = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
`;

export const DrawerHolder = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;
export const SwiperDrawerArea = styled(Swiper)`
    position: static;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
`;
export const NoSwipingSlide = styled.div`
    width: 100%;
    height: 100%;
    pointer-events: none;
`;
