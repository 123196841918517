import styled, { css } from 'styled-components';
import { addAlpha, lightenColor } from '../../utils/color.util';
import { Link } from 'react-router-dom';
import { Comment, SmallBody } from '../typography.styled';
import { BarberpoleThick } from '../basic/basic.styled';

export const MessageListItemArea = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(props) => props.theme.color.base.main};
    padding: ${(props) => props.theme.padding.medium};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.1)};
    }
`;
export const MessageListItemTextHolder = styled.div`
    margin-left: ${(props) => props.theme.margin.large};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    & > span:not(:first-child) {
        margin-top: -4px;
    }
`;
export const UnreadMarker = styled.div`
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 999px;
    background-color: ${(props) => props.theme.color.primary.main};
`;

export const BubbleTimestamp = styled(Comment)`
    display: inline-block;
    margin-left: ${(props) => props.theme.margin.medium};
    margin-bottom: -10px;
    width: 100%;
    text-align: center;
`;

export const NotificationBubbleArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(props) => props.theme.color.base.dark};
    border-radius: ${(props) => props.theme.border.radius.medium};
    margin: ${(props) => props.theme.margin.small};
    padding: ${(props) => props.theme.padding.large};
    color: ${(props) => props.theme.color.base.contrast};
`;
export const NotificationTextArea = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.theme.margin.large};
`;
export const NotificationActionButtonHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -${(props) => props.theme.margin.small};
`;

const NotificationActionStyle = css`
    background-color: ${(props) => props.theme.color.base.dark};
    outline: none;
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.small};
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.small};
    font-size: 0.8em;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.base.dark, 0.2)};
        border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 1)};
        color: ${(props) => addAlpha(props.theme.color.base.contrast, 1)};
    }
`;
export const NotificationActionButton = styled.button`
    ${NotificationActionStyle}
`;
export const NotificationActionLink = styled(Link)`
    ${NotificationActionStyle}
`;
export const NotificationActionInfo = styled.div`
    color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.8)};
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.small};
    font-size: 0.8em;
    font-weight: 500;
`;

export const NotificationChatTopArea = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const NotificationTopMessage = styled(SmallBody)`
    width: 100%;
    text-align: center;
    margin: 60px 0 ${(props) => props.theme.margin.huge} 0;
`;

export const MessageChatTopArea = styled.div`
    width: 100%;
    margin-top: ${(props) => props.theme.margin.huge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const MessageChatTopMessage = styled(SmallBody)`
    width: 100%;
    text-align: center;
    margin: ${(props) => props.theme.margin.large} 0 ${(props) => props.theme.margin.small} 0;
`;

interface ChatTextboxHolderProps {
    $keyboardOpen?: boolean;
}
export const ChatTextboxHolder = styled.div<ChatTextboxHolderProps>`
    position: absolute;
    bottom: 0;
    background-color: ${(props) => props.theme.color.base.main};
    padding-bottom: ${(props) => (props.$keyboardOpen ? '8px' : 'calc(120px + var(--ion-safe-area-bottom, 0))')};
    width: 100%;
`;
export const ChatTextboxHolderInner = styled.div`
    width: calc(100% - 20px);
    max-width: 500px;
    display: flex;
    align-items: center;
    margin: ${(props) => props.theme.margin.medium} auto;
`;
export const ChatTextArea = styled.textarea`
    width: 100%;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    outline: none;
    z-index: 5;
    resize: none;
`;
export const MessageSendButton = styled.button`
    border: none;
    outline: none;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    background-color: ${(props) => props.theme.color.primary.main};
    border-radius: 999px;
    margin-left: ${(props) => props.theme.margin.medium};
    cursor: pointer;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.4)};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    color: ${(props) => props.theme.color.base.contrast};
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.4)};
    }
    & > ion-icon {
        margin-top: 4px;
        font-size: 14px;
    }
`;

interface ChatBubbleHolderProps {
    $showSpace?: boolean;
}
export const ChatBubbleHolder = styled.div<ChatBubbleHolderProps>`
    margin: ${(props) => props.theme.margin.small} 0;
    margin-top: ${(props) => (props.$showSpace ? props.theme.margin.large : props.theme.margin.small)};
`;
const ChatBubbleShared = styled.div`
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    width: fit-content;
    max-width: 80%;
`;
export const ChatBubbleSent = styled(ChatBubbleShared)`
    background-color: ${(props) => props.theme.color.primary.main};
    color: ${(props) => props.theme.color.primary.contrast};
    margin-left: auto;
`;
export const ChatBubbleReceived = styled(ChatBubbleShared)`
    background-color: ${(props) => props.theme.color.base.dark};
    color: ${(props) => props.theme.color.base.contrast};
    border: solid 1px;
    border-color: ${(props) => props.theme.color.base.contrast};
    margin-right: auto;
`;

export const BetaFeedbackListItemArea = styled(Link)`
    display: block;
    width: 100%;
    background-color: ${(props) => props.theme.color.primary.main};
    /* background-size: calc(200% + 50px) 100%;
    animation: ${BarberpoleThick} 10s linear infinite;
    background-image: repeating-linear-gradient(
        -45deg,
        ${(props) => props.theme.color.primary.main},
        ${(props) => props.theme.color.primary.main} 30px,
        ${(props) => props.theme.color.primary.dark} 30px,
        ${(props) => props.theme.color.primary.dark} 60px
    ); */
    border-radius: ${(props) => props.theme.border.radius.large};
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
`;
export const BetaFeedbackListItemAreaInner = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.color.base.main};
    display: flex;
    padding: ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
`;
