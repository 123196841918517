import { useContext } from 'react';
import { PostStub } from '../types';
import { CommonActionContext, CommonActionContextType } from '../context/common-action.context';

interface UseCommonActionBundle {
    showReportDrawer: boolean;
}

const useCommonAction = (): CommonActionContextType & UseCommonActionBundle => {
    const state = useContext(CommonActionContext);

    const setShareLink = (link: string | null) => {
        state.setShareLink(link);
        if (link) state.setShowShareDrawer(true);
    };
    const setSharePost = (post: PostStub | null) => {
        state.setSharePost(post);
        if (post) state.setShowShareDrawer(true);
    };
    return {
        ...state,
        setShareLink,
        setSharePost,
        showReportDrawer: !!state.reportPost || !!state.reportComment || !!state.reportSong || !!state.reportUser,
    };
};

export default useCommonAction;
