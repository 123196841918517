import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { ReactNode } from "react";
import { ModalArea, ModalBackground, ModalCloseArea } from "./basic.styled";
import Button from "./button.component";


interface ModalProps {
    show: boolean;
    onClose: () => void;
    top?: boolean;
    children: ReactNode;
};
const Modal = ({ show, onClose, top, children }: ModalProps) => {
    return (
        <ModalBackground $visible={show} $top={top} onClick={onClose} >
            <ModalArea onClick={e => e.stopPropagation()} >
                {children}
            </ModalArea>
            <ModalCloseArea>
                <Button type="small"><IonIcon icon={closeOutline}/></Button>
            </ModalCloseArea>
        </ModalBackground>
    );
};

export default Modal;