import { useCallback, useEffect, useState } from 'react';
import { get, postNoBody } from '../services/api';
import { FavoriteFeed, FeedOptions, FeedSortMode, FeedType, PostStub } from '../types';
import useAuth from './use-auth';
import { matchesAnyFavoriteFeed, matchesFavoriteFeed, matchesOptions } from '../utils/feed.util';
import useCurrentUser from './use-current-user';

export interface FeedBundle {
    feed: PostStub[] | null;
    feedOptions: FeedOptions;
    firstLoad: boolean;
    optionsChanged: boolean;
    loading: boolean;
    moreLoading: boolean;
    error?: string | null;
    setFeedOptions: (options: FeedOptions) => void;
    reload: () => void;
    toggleFavorite: (options: FeedOptions) => void;
    onViewPost: (index: number) => void;
}

const useFeed = (): FeedBundle => {
    const [loadCount, setLoadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [moreLoading, setMoreLoading] = useState(false);
    const [batchOn, setBatchOn] = useState(0);
    const [feed, setFeed] = useState<PostStub[] | null>(null);
    const [feedOptions, setFeedOptions] = useState<FeedOptions>({
        feedType: FeedType.Home,
        locationId: 0,
        tags: [],
        sortMode: FeedSortMode.Trending,
    });
    const [activeOptions, setActiveOptions] = useState<FeedOptions | null>(null);

    const auth = useAuth();
    const currentUser = useCurrentUser();

    const buildQuery = useCallback((options: FeedOptions, batch?: number): string => {
        let query = `/post?FeedType=${options.feedType}`;
        if (options.feedType === FeedType.Discover) {
            query += `&LocationId=${options.locationId}`;
            query += `&SortMode=${options.sortMode}`;
            if (options.tags.length > 0) {
                query += `&FilterTag=${options.tags[0]}`;
            }
        }
        if (batch) {
            query += `&BatchNumber=${batch}`;
        }
        return query;
    }, []);

    const reload = () => {
        setLoading(true);
        setActiveOptions(feedOptions);
        setFeed([]);
        setBatchOn(0);
        get<PostStub[]>(buildQuery(feedOptions, 0), auth.auth)
            .then((r) => {
                if (r.data) {
                    setFeed(r.data);
                    setLoadCount(loadCount + 1);
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false));
    };

    const toggleFavorite = (options: FeedOptions) => {
        if (!auth || !currentUser.user) return;
        let faves: FavoriteFeed[] = [];
        if (matchesAnyFavoriteFeed(options, currentUser.user.favoriteFeeds ?? [])) {
            faves = currentUser.user.favoriteFeeds.filter((f) => !matchesFavoriteFeed(options, f));
        } else {
            faves = [
                ...(currentUser.user.favoriteFeeds ?? []),
                {
                    feedType: options.feedType,
                    locationId: options.locationId,
                    tag: options.tags.length > 0 ? options.tags[0] : '',
                },
            ];
        }
        currentUser.setFavoriteFeeds(faves);
    };

    const onViewPost = (index: number) => {
        if (!feed || feed.length <= index) return;
        const postOn = feed[index];
        if (!postOn) return;
        postNoBody(`/post/seen`, { postIds: [postOn.postId] }, auth.auth);
        if (index >= feed.length - 2 && !moreLoading && batchOn >= 0) {
            setMoreLoading(true);
            setBatchOn((b) => b + 1);
            get<PostStub[]>(buildQuery(feedOptions, batchOn + 1), auth.auth)
                .then((r) => {
                    if (r.data) {
                        if (r.data.length === 0) {
                            setBatchOn(-1);
                        } else {
                            setFeed([...feed, ...r.data]);
                        }
                    }
                })
                .catch((e) => console.log(e))
                .finally(() => setMoreLoading(false));
        }
    };

    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.userId]);

    return {
        feed,
        feedOptions,
        firstLoad: loadCount < 2,
        optionsChanged: !matchesOptions(feedOptions, activeOptions),
        loading,
        moreLoading,
        error: null,
        setFeedOptions,
        reload,
        toggleFavorite,
        onViewPost,
    };
};

export default useFeed;
