import styled from 'styled-components';
import { BadgeSize } from '../../types/basic';
import { Barberpole, SpinAnimation } from '../basic/basic.styled';
import { addAlpha, lightenColor } from '../../utils/color.util';
import { Link } from 'react-router-dom';

export const NowPlayingArea = styled.div`
    padding-top: max(16px, var(--ion-safe-area-top, 0));
    height: 50px;
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 500;
`;

export const NowPlayingAreaInner = styled.div`
    max-width: 400px;
`;

const getDiskSize = (size: BadgeSize): string => {
    switch (size) {
        case 'tiny':
            return '32px';
        case 'small':
            return '50px';
        case 'medium':
            return '80px';
        case 'large':
            return '200px';
    }
};
const getHolderSize = (size: BadgeSize): string => {
    switch (size) {
        case 'tiny':
            return '40px';
        case 'small':
            return '60px';
        case 'medium':
            return '120px';
        case 'large':
            return '260px';
    }
};

interface MusicDetailsAreaProps {
    $expanded: boolean;
    $hidden?: boolean;
}
export const MusicDetailsArea = styled.div<MusicDetailsAreaProps>`
    display: ${(props) => (props.$hidden ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-top: ${(props) => (props.$expanded ? '80px' : 'calc(-120px - var(--ion-statusbar-padding, 0px))')};
    /* margin-top: calc(-120px - var(--ion-statusbar-padding, 0)); */
    ${(props) => (props.$expanded ? '' : 'pointer-events: none; & > div { pointer-events: all; }')}
    transition: margin-top 0.2s;
    & > *:nth-child(2) {
        ${(props) => (props.$expanded ? '' : 'margin-top: 20px;')}
    }
`;

interface MusicDiskHolderProps {
    size: BadgeSize;
}
export const MusicDiskHolder = styled.div<MusicDiskHolderProps>`
    position: relative;
    width: ${(props) => getHolderSize(props.size)};
    height: ${(props) => getHolderSize(props.size)};
    min-width: ${(props) => getHolderSize(props.size)};
    min-height: ${(props) => getHolderSize(props.size)};
    border-radius: 1000px;
    transition: all 0.3s ease;
    background-color: ${(props) => props.theme.color.base.main};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
`;

interface MusicDiskImageProps {
    size: BadgeSize;
    image: string | null;
    $playing: boolean;
    speed: string;
    $hideBorder?: boolean;
}
export const MusicDiskImage = styled.div<MusicDiskImageProps>`
    width: ${(props) => getDiskSize(props.size)};
    height: ${(props) => getDiskSize(props.size)};
    min-width: ${(props) => getDiskSize(props.size)};
    min-height: ${(props) => getDiskSize(props.size)};
    ${(props) =>
        props.image
            ? `
        background-image: url(${props.image});
        background-position: center;
        background-size: cover;
    `
            : `
        background-color: black;
    `}
    border-radius: 1000px;
    ${(props) => (props.$hideBorder ? '' : 'border: solid 2px white;')}
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    ${SpinAnimation}
    animation-play-state: ${(props) => (props.$playing ? 'running' : 'paused')};
`;
export const MusicDiskContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    opacity: 0;
    transition: opacity 0.2s;
    &:hover {
        opacity: 1;
    }
`;
export const TrackSeekHolder = styled.div`
    margin: auto;
    width: 100%;
    max-width: 400px;
    z-index: 100;
    margin-top: -12px;
`;
export const MusicDetailsBody = styled.div<MusicDetailsAreaProps>`
    background-color: ${(props) => props.theme.color.base.main};
    width: 100%;
    z-index: 1;
    transition: all 0.3s;
    /* height: ${(props) => (props.$expanded ? 'calc(100vh - 160px - var(--ion-safe-area-top, 0))' : '0')}; */
    /* height: ${(props) => (props.$expanded ? '100%' : '0')}; */
    height: 100%;
    margin-top: ${(props) => (props.$expanded ? '-156px' : 'var(--ion-statusbar-padding, 0)')};
    padding-top: ${(props) => (props.$expanded ? '156px' : '0')};
`;
export const MusicDetailsContentArea = styled.div`
    width: calc(100% - 60px);
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SongCreditListArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
export const SongCreditItemLinkArea = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.padding.small};
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    }
    & > div {
        margin-right: ${(props) => props.theme.margin.small};
    }
`;
export const SongCreditItemButtonArea = styled.button`
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.padding.small};
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    }
    & > div {
        margin-right: ${(props) => props.theme.margin.small};
    }
`;

interface MusicCloseButtonProps {
    show: boolean;
}
export const MusicCloseButton = styled.button<MusicCloseButtonProps>`
    position: absolute;
    bottom: 10px;
    background-color: ${(props) => props.theme.color.base.main};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 40px;
    width: 100px;
    height: 50px;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    opacity: ${(props) => (props.show ? 1 : 0)};
    left: calc(50% - 50px);
    transition: background-color 0.2s, opacity 0.2s;
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.1)};
    }
`;
export const MusicDetailsButtonHolder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SongListItemArea = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
    &:active {
        transition: background-color 0s;
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:last-child {
        border-bottom: none;
    }
`;
export const SongListItemLinkArea = styled(Link)`
    width: 100%;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
    &:active {
        transition: background-color 0s;
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:last-child {
        border-bottom: none;
    }
`;

export const PlaybackHolder = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.large};
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-bottom: ${(props) => props.theme.margin.large};
    overflow: hidden;
    padding: 10px;
`;
interface PlaybackPositionBackgroundProps {
    percent: number;
    $playing?: boolean;
}
export const PlaybackPositionBackground = styled.div.attrs<PlaybackPositionBackgroundProps>((props) => ({
    style: {
        width: `${props.percent}%`,
        animationPlayState: props.$playing === false ? 'paused' : 'running',
    },
}))<PlaybackPositionBackgroundProps>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-size: calc(200% + 50px) 100%;
    animation: ${Barberpole} 3s linear infinite;
    background-image: repeating-linear-gradient(
        45deg,
        ${(props) => props.theme.color.primary.main},
        ${(props) => props.theme.color.primary.main} 10px,
        ${(props) => props.theme.color.primary.dark} 10px,
        ${(props) => props.theme.color.primary.dark} 20px
    );
`;
export const PlaybackContent = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.medium};
    padding: ${(props) => props.theme.padding.large};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
`;

export const ExplicitMarker = styled.span`
    position: relative;
    top: -2px;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    color: ${(props) => props.theme.color.base.main};
    background-color: ${(props) => props.theme.color.base.contrast};
    border-radius: 4px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    text-align: center;
`;

export const PlaybackPlayPauseButton = styled.button`
    width: 28px;
    min-width: 28px;
    height: 28px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    outline: none;
    border-radius: 999px;
    color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.9)};
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0)};
    transition: background-color 0.4s;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.4)};
    }
`;

export const TrackSeekContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    & > span {
        width: 50px;
        text-align: center;
    }
`;
export const TrackSeekSliderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 25px;
    margin-bottom: 8px;
`;
interface TrackSeekSliderLineProps {
    redzone: number;
}
export const TrackSeekSliderLine = styled.div<TrackSeekSliderLineProps>`
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.4)};
    height: 2px;
    border-radius: 4px;
    padding: 0 8px;
    margin-top: 12px;
    border-right: solid ${(props) => props.redzone}px ${(props) => props.theme.color.error.main};
    transition: border-right 0.2s;
`;
interface TrackPosProps {
    percent: number;
    $canDrag?: boolean;
}
export const TrackSeekSliderCurrentPos = styled.div.attrs<TrackPosProps>((props) => ({
    style: {
        left: `${props.percent}%`,
    },
}))<TrackPosProps>`
    position: absolute;
    top: 9px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-left: -4px;
    background-color: ${(props) => props.theme.color.base.contrast};
`;
export const TrackSeekSliderHandle = styled.div.attrs<TrackPosProps>((props) => ({
    style: {
        left: `${props.percent}%`,
    },
}))<TrackPosProps>`
    position: absolute;
    top: 3px;
    margin-left: -1px;
    cursor: ${(props) => (props.$canDrag ? 'grab' : 'auto')};
    & > * > * {
        cursor: ${(props) => (props.$canDrag ? 'grab' : 'auto')};
    }
`;
export const TrackSeekSliderHandleLine = styled.div`
    width: 2px;
    height: 20px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.color.base.contrast};
`;
export const TrackSeekSliderHandleBall = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 999px;
    margin-left: -9px;
    margin-top: -2px;
    background-color: ${(props) => props.theme.color.base.dark};
    border: solid 2px ${(props) => props.theme.color.base.contrast};
`;
export const TrackSeekSliderHandleTime = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -36px;
    left: -25px;
    width: 50px;
    height: 30px;
    border-radius: ${(props) => props.theme.border.radius.medium};
    background-color: ${(props) => props.theme.color.base.dark};
`;
