import heic2any from "heic2any";

export const compressionOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
};

export const convertIfHeic = (file: File): Promise<File> => {
    if (file.type === 'image/heic' || file.type === 'image/heif') {
        const fblob = file as Blob;
        return heic2any({ blob: fblob, toType: 'image/jpeg', quality: 0.5 })
            .then(b => new File([b as Blob], "convertedFile.jpeg", {
                type: 'image/jpeg'
            }));
    }
    return Promise.resolve(file);
};
