import { useState } from "react";
import Button from "../../../components/basic/button.component";
import { Title, Comment, A, Body } from "../../../components/typography.styled";
import useAuth from "../../../hooks/use-auth";

const ConfirmEmailForm = () => {
    const [ resent, setResent ] = useState(false);
    const authBundle = useAuth();

    const requestResend = () => {
        if (!resent) {
            authBundle.resendVerification();
            setResent(true);
        }
    };

    return (
        <>
            <Title>Verify Your Email</Title>
            <Comment margin="0 0 16px 0">
                An confirmation email was sent to you. Please click the link before continuing.
            </Comment>
            {
                !resent &&
                <Comment margin="0 0 32px 0">
                    Didn't receive an email? <A onClick={requestResend}>Resend it.</A>
                </Comment>
            }
            <Button type="big" isLoading={authBundle.isLoading} onClick={() => authBundle.tryVerification()}>Continue</Button>
            <Body align="center" margin="16px"><A onClick={() => authBundle.logout()}>Back</A></Body>
        </>
    );
};

export default ConfirmEmailForm;