import { useEffect, useState } from "react";
import { CommonDataContextType } from "../../context/common.context";
import useAuth from "../use-auth";
import { LocationDetail } from "../../types/location/location-detail";
import { get } from "../../services/api";


const useCommonData = (): CommonDataContextType => {
    const auth = useAuth();
    const [ locations, setLocations ] = useState<LocationDetail[]>([]);

    useEffect(() => {
        if (!auth.auth?.idToken) return;
        if (!locations || locations.length === 0) {
            get<LocationDetail[]>('/location', auth.auth)
                .then(res => {
                    if (!res.error && res.data) setLocations(res.data);
                })
                .catch(e => console.log(e));
        }
    }, [ auth.auth?.idToken ]);

    return {
        locations
    }
};

export default useCommonData;