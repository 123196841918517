import { ApiWrapper, Auth } from '../types';
export const API_URL = process.env.REACT_APP_API_URL;

export const get = async <T>(endpoint: string, auth: Auth | null = null): Promise<ApiWrapper<T>> => {
    const headers: any = {
        'Content-Type': 'application/json',
    };
    if (auth?.idToken) headers['Authorization'] = `Bearer ${auth.idToken}`;
    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'GET',
            headers: headers,
        });
        const hadError = response.status >= 300;
        const json = await response.json();
        return {
            data: hadError ? null : (json as T),
            isLoading: false,
            hadError: hadError,
            status: response.status,
            errorMessage: json.hasOwnProperty('message') ? (json.message as string) : response.statusText,
            error: response.status !== 200 ? json : undefined,
        };
    } catch (e) {
        console.log(e);
    }
    return Promise.resolve({ isLoading: false, hadError: true, data: null });
};

export const post = async <T>(endpoint: string, body: any, auth: Auth | null = null): Promise<ApiWrapper<T>> => {
    const headers: any = {
        'Content-Type': 'application/json',
    };
    if (auth?.idToken) headers['Authorization'] = `Bearer ${auth.idToken}`;
    const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
    });
    const json = await response.json();
    return {
        data: json as T,
        isLoading: false,
        hadError: response.status !== 200,
        status: response.status,
        errorMessage: json.hasOwnProperty('message') ? (json.message as string) : response.statusText,
        error: json,
    };
};
export const postNoBody = async (endpoint: string, body: any, auth: Auth | null = null): Promise<ApiWrapper<null>> => {
    const headers: any = {
        'Content-Type': 'application/json',
    };
    if (auth?.idToken) headers['Authorization'] = `Bearer ${auth.idToken}`;
    const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
    });
    return {
        data: null,
        isLoading: false,
        hadError: response.status !== 200,
        status: response.status,
    };
};

export const put = async <T>(endpoint: string, body: any, auth: Auth | null = null): Promise<ApiWrapper<T>> => {
    const headers: any = {
        'Content-Type': 'application/json',
    };
    if (auth?.idToken) headers['Authorization'] = `Bearer ${auth.idToken}`;
    const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(body),
    });
    const json = await response.json();
    return {
        data: json as T,
        isLoading: false,
        hadError: response.status !== 200,
        status: response.status,
        errorMessage: json.hasOwnProperty('message') ? (json.message as string) : response.statusText,
        error: json,
    };
};

export const postFile = async <T>(endpoint: string, file: File, auth: Auth | null = null): Promise<ApiWrapper<T>> => {
    const headers: any = {
        // 'Content-Type': 'multipart/form-data'
    };
    if (auth?.idToken) headers['Authorization'] = `Bearer ${auth.idToken}`;
    let body = new FormData();
    body.append('file', file);
    const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: body,
    });
    const json = await response.json();
    return {
        data: json as T,
        isLoading: false,
        hadError: response.status !== 200,
        status: response.status,
        errorMessage: json.hasOwnProperty('message') ? (json.message as string) : response.statusText,
        error: json,
    };
};
