import { useState } from "react";
import Button from "../../../components/basic/button.component";
import TextInput from "../../../components/forms/text-input.component";
import SongListItem from "../../../components/music/song-list-item.component";
import OxPage from "../../../components/oxpage.component";
import { Title, Body } from "../../../components/typography.styled";
import useSongSearch from "../../../hooks/use-song-search";
import { ContentArea } from "../post.styled";


const PostSongSelectPage = () => {
    const [ search, setSearch ] = useState("");
    const results = useSongSearch(search);

    return (
        <OxPage>
            <ContentArea>
                <Title>Select a Song</Title>
                <TextInput id="search" label="Start Typing" value={search} setValue={setSearch}/>
                {
                    search === ""
                    ?
                        <>
                            <Body align="center" margin="0 0 16px 0">- or -</Body>
                            <Button type="big" to="/post/songupload">Upload a new song</Button>
                        </>
                    :
                        <>
                            {results.map(s => <SongListItem
                                key={s.songId}
                                song={s}
                                playing={false}
                                to={`/post/song/${s.songId}`}
                            />)}
                        </>
                }
            </ContentArea>
        </OxPage>
    );
};

export default PostSongSelectPage;