import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { ReactChild } from 'react';

export const PageArea = styled(IonPage)`
    z-index: 0;
    height: 100%;
    background-color: ${props => props.theme.color.base.main};
`;

interface OxPageProps {
    children: ReactChild | ReactChild[];
};
const OxPage = ({ children }: OxPageProps) => {
    return (
        <PageArea>
            { children }
        </PageArea>
    );
};

export default OxPage;