import { AudioPlayer, useGlobalAudioPlayer } from 'react-use-audio-player';
import { SongContextType } from '../../context/song.context';
import { Song, SongState } from '../../types';
import { useEffect, useReducer, useState } from 'react';
import { get } from '../../services/api';
import useAuth from '../use-auth';

enum SongReducerActionType {
    StartSong,
    StartFromData,
    SetStartPos,
}
interface SongReducerAction {
    action: SongReducerActionType;
    song?: Song;
    data?: string;
    pos?: number;
}
interface SongReducerType {
    song?: Song;
    songData?: string;
    startPos?: number;
}

const initialValue: SongReducerType = {};

const songReducer = (state: SongReducerType, action: SongReducerAction): SongReducerType => {
    switch (action.action) {
        case SongReducerActionType.StartSong:
            return {
                ...state,
                song: action.song,
                songData: undefined,
                startPos: action.pos,
            };
        case SongReducerActionType.StartFromData:
            return {
                ...state,
                song: undefined,
                songData: action.data,
                startPos: undefined,
            };
        case SongReducerActionType.SetStartPos:
            return {
                ...state,
                startPos: action.pos,
            };
    }
    return state;
};

const useSongContext = (): SongContextType => {
    const audio = useGlobalAudioPlayer();

    const [state, dispatch] = useReducer(songReducer, initialValue);
    const [autoplay, setAutoplay] = useState(true);

    const auth = useAuth();

    const setSong = (song: Song | null, pos?: number) => {
        if (!song) {
            audio?.stop();
            return;
        }
        if (song.songId === state.song?.songId) {
            if (pos !== undefined) audio.seek(pos);
            return;
        }
        if (song.url) {
            audio?.stop();
            audio?.load(song.url, {
                autoplay,
                html5: true,
                onload: () => {
                    if (pos) {
                        audio.seek(pos);
                    }
                },
            });
            dispatch({ action: SongReducerActionType.StartSong, song, pos });
        }
    };

    const setSongData = (data: string) => {
        audio?.stop();
        audio?.load(data, { autoplay: true });
        dispatch({ action: SongReducerActionType.StartFromData, data });
    };

    const setSongByFile = (file: File) => {
        var reader = new FileReader();
        reader.addEventListener('load', () => {
            var data = reader.result as string;
            setSongData(data);
        });
        reader.readAsDataURL(file);
    };

    const setSongById = (id: number, pos?: number) => {
        if (state.song?.songId === id) {
            if (pos !== undefined) audio.seek(pos);
            return;
        }
        audio.stop();
        get<Song>(`/song/${id}`, auth.auth)
            .then((res) => setSong(res.data, pos))
            .catch((e) => console.log(e));
    };

    const togglePlaying = () => {
        if (!audio.playing) {
            setAutoplay(true);
        }
        audio?.togglePlayPause();
    };

    const setStartPos = (pos?: number) => {
        dispatch({ action: SongReducerActionType.SetStartPos, pos });
    };

    useEffect(() => {
        if (audio) audio.seek(state.startPos ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.startPos]);

    const setAutoplayState = (v: boolean) => {
        if (!v) {
            audio.pause();
        } else {
            audio.play();
        }
        setAutoplay(v);
    };

    return {
        state: getSongState(state.songData, state.song, audio),
        audio: audio,
        ...state,
        autoplay,
        setAutoplay: setAutoplayState,
        setSong,
        setSongById,
        setSongByFile,
        togglePlaying,
        setStartPos,
    };
};

const getSongState = (songData?: string, song?: Song, audioPlayer?: AudioPlayer): SongState => {
    if (!audioPlayer || (!song && !songData)) return SongState.None;
    if (audioPlayer.isLoading) return SongState.Loading;
    if (audioPlayer.playing) return SongState.Playing;
    if (audioPlayer.stopped) return SongState.Done;
    if (audioPlayer.paused) return SongState.Paused;
    return SongState.None;
};

export default useSongContext;
