import { UserStub, SongStub, Post } from '../';
import { LocationDetail } from '../location/location-detail';

export interface PostStub {
    postId: number;
    body: string;
    song: SongStub | null;
    songStartPosition?: number;
    backgroundImageUrl?: string;
    media?: string[];
    repostedId?: number;
    repost?: PostStub;
    location?: LocationDetail;

    reactionCount: number;
    commentCount: number;
    reaction: number | null;

    user?: UserStub;
    createdAt: Date;
}

export const toPostStub = (post: Post, userId: number): PostStub => ({
    ...post,
    reactionCount: post.reactions.length,
    commentCount: post.comments.map((c) => c.childComments?.length + 1).reduce((a, b) => a + b, 0),
    reaction: post.reactions.find((r) => r.user.id === userId)?.reactionIndex ?? null,
});
