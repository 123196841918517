import { useCallback, useEffect, useReducer } from 'react';
import { NotificationContextType, NotificationData } from '../../context/notification.context';
import { Notification } from '../../types/notification/notification';
import useAuth from '../use-auth';
import { get, post } from '../../services/api';
import { ToastModel } from '../../types/notification/toast-model';
import { ToastSeverity } from '../../types/notification/toast-severity';
import { getMessage, getToastTitle } from '../../utils/chat.util';

const NOTIFICATION_POLL_FREQUENCY = 120000;
const TOAST_TIME = 3000;
const TOAST_CLOSE_TIME = 200;

interface ReducerStateType extends NotificationData {
    toastList: ToastModel[];
}

const initialNotificationState: ReducerStateType = {
    newNotifications: [],
    toast: undefined,
    toastList: [],
    showToast: false,
};

enum ReducerAction {
    RefreshNew,
    AddToast,
    OpenToast,
    CloseToast,
    BurnToast,
}
interface ReducerActionType {
    action: ReducerAction;
    notifications?: Notification[];
    toast?: ToastModel;
}

const reducer = (state: ReducerStateType, action: ReducerActionType): ReducerStateType => {
    switch (action.action) {
        case ReducerAction.RefreshNew:
            if (action.notifications && action.notifications.length > 0) {
                const topNotification = action.notifications[0];
                if (!state.newNotifications.find((n) => n.notificationId === topNotification.notificationId)) {
                    return {
                        ...state,
                        newNotifications: action.notifications ?? [],
                        toastList: [
                            ...state.toastList,
                            {
                                severity: ToastSeverity.Info,
                                title: getToastTitle(topNotification),
                                body: getMessage(topNotification),
                            },
                        ],
                    };
                }
            }
            return {
                ...state,
                newNotifications: action.notifications ?? [],
            };
        case ReducerAction.AddToast:
            return {
                ...state,
                toastList: action.toast ? [...state.toastList, action.toast] : state.toastList,
            };
        case ReducerAction.OpenToast:
            return {
                ...state,
                toast: state.toastList.length > 0 ? state.toastList[0] : undefined,
                toastList: state.toastList.slice(1),
                showToast: true,
            };
        case ReducerAction.CloseToast:
            return {
                ...state,
                showToast: false,
            };
        case ReducerAction.BurnToast:
            return {
                ...state,
                toast: undefined,
            };
    }
    return state;
};

const useNotificationContext = (): NotificationContextType => {
    const [state, dispatch] = useReducer(reducer, initialNotificationState);

    const auth = useAuth();

    const addToast = useCallback((toast: ToastModel) => {
        dispatch({ action: ReducerAction.AddToast, toast });
    }, []);

    const pollForNotifications = useCallback(() => {
        get<Notification[]>('/notification', auth.auth)
            .then((res) => {
                if (res.data) {
                    dispatch({ action: ReducerAction.RefreshNew, notifications: res.data });
                }
            })
            .catch((err) => console.log(err));
    }, [auth.auth]);

    useEffect(() => {
        if (auth.userId) {
            pollForNotifications();
            const interval = setInterval(pollForNotifications, NOTIFICATION_POLL_FREQUENCY);
            return () => clearInterval(interval);
        }
    }, [auth.userId, pollForNotifications]);

    const setSeenByChatId = useCallback(
        (chatId: number) => {
            const seen = state.newNotifications.filter((n) => n.chatId === chatId);
            if (seen.length > 0) {
                post('/notification/seen', { Ids: seen.map((n) => n.notificationId) }, auth.auth)
                    .then((res) => {
                        pollForNotifications();
                    })
                    .catch((err) => console.log(err));
            }
        },
        [auth.auth, pollForNotifications, state.newNotifications],
    );

    useEffect(() => {
        if (state.toastList.length > 0 && !state.toast) {
            dispatch({ action: ReducerAction.OpenToast });
        }
    }, [state.toastList, state.toast]);
    useEffect(() => {
        if (state.showToast) {
            setTimeout(() => {
                dispatch({ action: ReducerAction.CloseToast });
            }, TOAST_TIME);
        } else {
            setTimeout(() => {
                dispatch({ action: ReducerAction.BurnToast });
            }, TOAST_CLOSE_TIME);
        }
    }, [state.showToast]);

    return {
        ...state,
        setSeenByChatId,
        addToast,
    };
};

export default useNotificationContext;
