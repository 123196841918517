import { BadgeSize, SongState } from "../../types";
import LoadingSpinner from "../basic/loading-spinner.component";
import { MusicDiskImage } from "./music.styled";

interface MusicDiskProps {
    songState: SongState;
    coverImageUrl: string|null;
    size: BadgeSize;
    onClick?: () => void;
};
const MusicDisk = ({ songState, coverImageUrl, size, onClick }: MusicDiskProps) => {
    return (
        <MusicDiskImage
            key={songState}
            size={size}
            speed={size === "large" ? "50s" : "10s"}
            image={coverImageUrl ?? null}
            onClick={onClick}
            $playing={songState === SongState.Playing}>
                { (songState === SongState.Loading) && <LoadingSpinner/> }
        </MusicDiskImage>
    );
};

export default MusicDisk;