export enum ReportReason {
    Other = 0,
    Copyright = 1,
    Nudity = 2,
    Harassment = 3,
    HateSpeech = 4,
    Spam = 5,
    Fraud = 6,
    PersonalInfo = 7,
    Violence = 8,
    IllegalTransaction = 9,
}
