import { chevronDownOutline } from "ionicons/icons";
import { useState } from "react";
import SmallTextInput from "../../../components/forms/small-text-input.component";
import { StyledIcon } from "../../../components/navigation/navigation.styled";
import { Body } from "../../../components/typography.styled";
import UserListing from "../../../components/user/user-listing.component";
import { UserStub } from "../../../types";
import { DrawerCloseButton, FollowerDrawerList, FollowerDrawerSearchContainer, StyledDrawer, StyledDrawerBackdrop, StyledDrawerBody } from "../user.styled";

const getMatchRank = (user: UserStub, search: string): number => {
    if (search === "") return 1;
    const ind = user.displayName.toLowerCase().indexOf(search);
    if (ind === 0) return 1000;
    if (ind > 0) return 100 / ind;
    return -1;
};

interface FollowerDrawerProps {
    show: boolean;
    followers: UserStub[];
    title: string;
    onClose: () => void;
};
const FollowerDrawer = ({ show, followers, title, onClose }: FollowerDrawerProps) => {
    const [ search, setSearch ] = useState("");

    const lowerSearch = search.toLowerCase();

    const filteredFollowers = followers
        .map(f => { return { user: f, rank: getMatchRank(f, lowerSearch) }})
        .filter(fr => fr.rank > 0)
        .sort((a,b) => a.rank - b.rank)
        .map(fr => fr.user);

    return (
        <StyledDrawer $show={show}>
            <StyledDrawerBackdrop $show={show} onClick={onClose}/>
            <StyledDrawerBody $show={show}>
                <DrawerCloseButton onClick={onClose}>
                    <StyledIcon icon={chevronDownOutline} />
                </DrawerCloseButton>
                <Body align="center">{title}</Body>
                <FollowerDrawerSearchContainer>
                    <SmallTextInput id="search" label="Search" value={search} setValue={setSearch}/>
                </FollowerDrawerSearchContainer>
                <FollowerDrawerList>
                    {
                        filteredFollowers.map(f => <UserListing key={f.id} user={f}/>)
                    }
                </FollowerDrawerList>
            </StyledDrawerBody>
        </StyledDrawer>
    );
};

export default FollowerDrawer;