export interface ApiWrapper<T> {
    data: T|null;
    isLoading: boolean;
    hadError: boolean;
    status?: number;
    errorMessage?: string;
    error?: unknown;
};

export const getLoadingApiWrapper = <T>(): ApiWrapper<T> => {
    return {
        data: null,
        isLoading: true,
        hadError: false
    };
};

export const getSuccessApiWrapper = <T>(data: T, status?: number): ApiWrapper<T> => {
    return {
        data: data,
        isLoading: false,
        hadError: false,
        status: status ?? 200
    };
};

export const getErrorApiWrapper = <T>(message: string, error: unknown, status?: number): ApiWrapper<T> => {
    return {
        data: null,
        isLoading: false,
        hadError: true,
        status: status ?? 500,
        errorMessage: message,
        error: error
    };
};