import { IonIcon } from "@ionic/react";
import { Body } from "../typography.styled";
import { LoadingBarBack, LoadingBarBody, LoadingBarCenterBody, LoadingBarDoneFill, LoadingBarFill } from "./basic.styled";
import { checkmarkOutline } from "ionicons/icons";

interface LoadingBarProps {
    fillPercent: number;
    text?: string;
};
const LoadingBar = ({ fillPercent, text }: LoadingBarProps) => {
    return (
        <LoadingBarBack>
            <LoadingBarFill fillPercent={fillPercent}/>
            <LoadingBarDoneFill fillPercent={fillPercent}/>
            <LoadingBarBody>
                <Body>{text}</Body>
            </LoadingBarBody>
            <LoadingBarCenterBody fillPercent={fillPercent}>
                <IonIcon icon={checkmarkOutline} />
            </LoadingBarCenterBody>
        </LoadingBarBack>
    )
};

export default LoadingBar;