import { IonIcon } from '@ionic/react';
import {
    FavoritesSection,
    FeedOptionButton,
    FeedControlCollapseArea,
    FeedSelectRow,
    FavoriteFeedButton,
} from './feed.styled';
import { home, earth, star } from 'ionicons/icons';
import { Comment } from '../typography.styled';
import { FavoriteFeed, FeedOptions, FeedSortMode, FeedType } from '../../types';
import useLocations from '../../hooks/use-locations';
import useCurrentUser from '../../hooks/use-current-user';

interface FeedSelectProps {
    show: boolean;
    feedtype: FeedType;
    setFeedType: (type: FeedType) => void;
    setOptions: (o: FeedOptions) => void;
}
const FeedSelect = ({ show, feedtype, setFeedType, setOptions }: FeedSelectProps) => {
    const currentUser = useCurrentUser();
    const locations = useLocations();

    const setFeedFromFavorite = (favorite: FavoriteFeed) => {
        setOptions({
            feedType: FeedType.Discover,
            locationId: favorite.locationId,
            tags: favorite.tag ? [favorite.tag] : [],
            sortMode: FeedSortMode.Trending,
        });
    };

    return (
        <FeedControlCollapseArea $show={true}>
            <FeedSelectRow $selected={feedtype === FeedType.Home}>
                <IonIcon icon={home} />
                <FeedOptionButton
                    onClick={() => setFeedType(FeedType.Home)}
                    $selected={feedtype === FeedType.Home}
                    $show={show || feedtype !== FeedType.Home}
                >
                    Home
                </FeedOptionButton>
            </FeedSelectRow>
            <FeedSelectRow $selected={feedtype === FeedType.Discover}>
                <IonIcon icon={earth} />
                <FeedOptionButton
                    onClick={() => setFeedType(FeedType.Discover)}
                    $selected={feedtype === FeedType.Discover}
                    $show={show || feedtype !== FeedType.Discover}
                >
                    Discover
                </FeedOptionButton>
            </FeedSelectRow>
            <FeedSelectRow>
                <IonIcon icon={star} />
                <FavoritesSection>
                    {currentUser.user?.favoriteFeeds && currentUser.user.favoriteFeeds.length > 0 ? (
                        currentUser.user.favoriteFeeds.map((f, i) => (
                            <FavoriteFeedButton key={i} onClick={() => setFeedFromFavorite(f)}>
                                {locations.getLocation(f.locationId)?.name}
                                {f.tag ? ` • ${f.tag}` : ''}
                            </FavoriteFeedButton>
                        ))
                    ) : (
                        <Comment>- No Favorites Saved -</Comment>
                    )}
                </FavoritesSection>
            </FeedSelectRow>
        </FeedControlCollapseArea>
    );
};

export default FeedSelect;
