import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
    color: {
        primary: {
            main: '#ffffff',
            dark: '#aaaaaa',
            contrast: '#000000',
        },
        secondary: {
            main: '#ffffff',
            dark: '#aaaaaa',
            contrast: '#000000',
        },
        base: {
            main: '#202020',
            dark: '#000000',
            contrast: '#ffffff',
        },
        error: {
            main: '#d94a45',
            dark: '#7d1b27',
            contrast: '#ffffff',
        },
        warning: {
            main: '#f0b943',
            dark: '#7a5606',
            contrast: '#000000',
        },
        good: {
            main: '#6ec96b',
            dark: '#3b8f38',
            contrast: '#ffffff',
        },
        info: {
            main: '#8de0eb',
            dark: '#306269',
            contrast: '#000000',
        },
    },
    border: {
        radius: {
            small: '6px',
            medium: '10px',
            large: '16px',
            huge: '40px',
        },
    },
    margin: {
        small: '4px',
        medium: '8px',
        large: '16px',
        huge: '32px',
    },
    padding: {
        small: '4px',
        medium: '8px',
        large: '16px',
        huge: '32px',
    },
};

export default Theme;
