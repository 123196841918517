
export type BadgeSize = "tiny"|"small"|"medium"|"large";

export enum LinkType {
    Spotify,
    Apple,
    SoundCloud,
    Qobuz,
    Tidal,
    Amazon,
    Pandora,
    Linktree,
    Unknown
};

export type LinkList = Partial<Record<LinkType, string>>;

export interface Location {
    lat: number;
    lon: number;
    name?: string;
};