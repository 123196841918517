import { ReactNode, createContext } from 'react';
import useNotification from '../hooks/context/use-notification-context';
import { Notification } from '../types/notification/notification';
import { ToastModel } from '../types/notification/toast-model';

export interface NotificationData {
    newNotifications: Notification[];
    toast?: ToastModel;
    showToast: boolean;
}

export interface NotificationContextType extends NotificationData {
    setSeenByChatId: (chatId: number) => void;
    addToast: (toast: ToastModel) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
    newNotifications: [],
    setSeenByChatId: (c) => {},
    toast: undefined,
    showToast: false,
    addToast: (t) => {},
});

const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useNotification();
    return <NotificationContext.Provider value={store}>{children}</NotificationContext.Provider>;
};

export default NotificationContextProvider;
