import { createContext } from 'react';
import { Auth, AuthStatus, RegisterRequest, SignInRequest } from '../types';

export interface AuthBundle {
    auth: Auth | null;
    userId?: number;
    state: AuthStatus;
    errorMessage: string | null;
    isLoading: boolean;
    isWaitingOnRefresh: boolean;
    tryBetaCode: (code: string) => void;
    login: (req: SignInRequest) => void;
    tryVerification: () => void;
    resendVerification: () => void;
    register: (req: RegisterRequest) => void;
    logout: () => void;
    refresh: () => void;
}

export const AuthContext = createContext<AuthBundle>({
    auth: null,
    userId: undefined,
    state: AuthStatus.LoggedOut,
    errorMessage: null,
    isLoading: false,
    isWaitingOnRefresh: true,
    tryBetaCode: (code) => {},
    login: (req) => {},
    tryVerification: () => {},
    resendVerification: () => {},
    register: (req) => {},
    logout: () => {},
    refresh: () => {},
});
export const AuthProvider = AuthContext.Provider;
