import Banner from '../../../components/basic/banner.component';
import LoadingSkeleton from '../../../components/basic/loading-skeleton.component';
import SongListItem from '../../../components/music/song-list-item.component';
import useSongHandler from '../../../hooks/use-song-handler';
import { ApiWrapper, UserSong } from '../../../types';

interface SongsTabAreaProps {
    songs: ApiWrapper<UserSong[]>;
}
const SongsTabArea = ({ songs }: SongsTabAreaProps) => {
    const { song, setSongById } = useSongHandler();
    return (
        <LoadingSkeleton loading={songs.isLoading} height="100px" count={3}>
            {songs.data ? (
                songs.data.map((s) => (
                    <SongListItem
                        key={s.songId}
                        song={s}
                        playing={s.songId === song?.songId}
                        onClick={() => setSongById(s.songId)}
                    />
                ))
            ) : (
                <Banner severity="error">Something went wrong.</Banner>
            )}
        </LoadingSkeleton>
    );
};

export default SongsTabArea;
