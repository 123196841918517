import { useContext } from "react";
import { CommonDataContext } from "../context/common.context";
import { LocationDetail } from "../types/location/location-detail";

interface useLocationsBundle {
    locations: LocationDetail[];
    getLocation: (id: number) => LocationDetail|null;
};
const useLocations = (): useLocationsBundle => {
    const { locations } = useContext(CommonDataContext);
    
    const getLocation = (id: number): LocationDetail|null => {
        return locations.find(l => l.locationId === id) ?? null;
    };

    return {
        locations,
        getLocation
    };
};

export default useLocations;