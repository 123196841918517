import { StyledLabel, StyledLabelText, StyledTextArea } from "./form.styled";

interface TextInputProps {
    id: string;
    label: string;
    value: string;
    setValue: (v: string) => void;
};
const MultilineTextInput = ({ id, label, value, setValue }: TextInputProps) => {
    return (
        <>
            <StyledLabel htmlFor={id} $hasInput={value ? true : false} style={{marginTop:"10px"}}>
                <StyledLabelText>{label}</StyledLabelText>
                <StyledTextArea
                    id={id}
                    name={id}
                    rows={6}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </StyledLabel>
        </>
    );
};

export default MultilineTextInput;