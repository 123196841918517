import { useEffect, useState } from 'react';
import { FeatureFlagContextType } from '../../context/feature-flag.context';
import useAuth from '../use-auth';
import { get, post } from '../../services/api';
import { FeatureFlagData } from '../../types/feature-flag/feature-flag-data';

const useFeatureFlagContext = (): FeatureFlagContextType => {
    const [flagData, setFlagData] = useState<FeatureFlagData>();
    const [isWaiting, setIsWaiting] = useState(false);

    const auth = useAuth();

    useEffect(() => {
        if (auth.auth) {
            get<FeatureFlagData>('/features', auth.auth).then((res) => {
                if (res.data) setFlagData(res.data);
            });
        }
    }, [auth.auth]);

    const acceptTerms = () => {
        setIsWaiting(true);
        post<FeatureFlagData>('/features/accept-terms', {}, auth.auth)
            .then((res) => {
                if (res.data) setFlagData(res.data);
            })
            .finally(() => setIsWaiting(false));
    };

    return {
        terms: flagData?.terms,
        isWaiting,
        acceptTerms,
    };
};

export default useFeatureFlagContext;
