import { useCallback, useEffect, useState } from 'react';
import { CurrentUserContextType } from '../../context/current-user.context';
import useAuth from '../use-auth';
import { FavoriteFeed, User, UserStub } from '../../types';
import { get, post, postNoBody } from '../../services/api';
import { UserBlock } from '../../types/notification/user-block';

const useCurrentUserContext = (): CurrentUserContextType => {
    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState(false);
    const [userBlocks, setUserBlocks] = useState<UserBlock[]>([]);

    const auth = useAuth();

    const reload = useCallback(() => {
        if (auth.userId) {
            setIsLoading(true);
            get<User>(`/user/${auth.userId}`, auth.auth)
                .then((res) => {
                    if (res.data) setUser(res.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));
            get<UserBlock[]>(`/report/block`, auth.auth)
                .then((res) => {
                    if (res.data) setUserBlocks(res.data);
                })
                .catch((e) => console.log(e));
        } else {
            setUser(undefined);
        }
    }, [auth.auth, auth.userId]);

    useEffect(() => {
        reload();
    }, [auth.auth, auth.userId, reload]);

    const updateUser = (u: User) => {};

    const setFavoriteFeeds = (feeds: FavoriteFeed[]) => {
        if (!user) return;
        post<User>(`/user/${auth.userId}/favorite-feeds`, { favoriteFeeds: feeds }, auth.auth)
            .then((res) => {})
            .catch((err) => console.log(err));
        setUser({
            ...user,
            favoriteFeeds: feeds,
        });
    };

    const isFollowingUser = (id?: number): boolean => {
        return (user?.following?.filter((f) => f.id === id)?.length ?? -1) > 0;
    };

    const toggleFollowing = (id: number) => {
        if (!user) return;
        post<{ following: boolean; user: UserStub }>(`/user/${id}/follow`, {}, auth.auth)
            .then((res) => {
                if (res.data) {
                    if (res.data.following) {
                        setUser({
                            ...user,
                            following: [...user?.following, res.data.user],
                        });
                    } else {
                        setUser({
                            ...user,
                            following: user.following.filter((f) => f.id !== id),
                        });
                    }
                }
            })
            .catch((err) => console.log(err));
    };

    const isBlockingUser = (id?: number): boolean => {
        return (userBlocks?.filter((f) => f.userId === id)?.length ?? -1) > 0;
    };

    const toggleBlocking = (id: number) => {
        if (!user) return;
        post<UserBlock[]>(`/report/block/${id}`, {}, auth.auth)
            .then((res) => {
                if (res.data) setUserBlocks(res.data);
            })
            .catch((err) => console.log(err));
    };

    const logOut = () => {
        auth.logout();
    };

    const deleteAccount = () => {
        if (!user) return;
        postNoBody(`/user/delete_account`, {}, auth.auth).then((res) => console.log(res));
    };

    return {
        userId: auth.userId,
        user,
        isLoading,
        reload,
        updateUser,
        setFavoriteFeeds,
        isFollowingUser,
        toggleFollowing,
        isBlockingUser,
        toggleBlocking,
        logOut,
        deleteAccount,
    };
};

export default useCurrentUserContext;
