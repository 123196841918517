import { useEffect, useMemo, useState } from 'react';
import { get, post } from '../services/api';
import useAuth from './use-auth';
import { Chat } from '../types/notification/chat';
import { Notification } from '../types/notification/notification';
import { NotificationType } from '../types/notification/notification-type';
import dayjs from 'dayjs';
import useCurrentUser from './use-current-user';
import { UserStub } from '../types';
import { NotificationExpanded } from '../types/notification/notification-expanded';

interface ChatBundle {
    chatId: number;
    isLoading: boolean;
    user?: UserStub;
    isDone: boolean;
    messages: NotificationExpanded[];
    draft: string;
    setDraft: (v: string) => void;
    send: () => void;
}

const useMessages = (chatId: number): ChatBundle => {
    const [chat, setChat] = useState<Chat | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [draft, setDraft] = useState('');
    const [newMessages, setNewMessages] = useState<Notification[]>([]);

    const auth = useAuth();
    const currentUser = useCurrentUser();

    useEffect(() => {
        setIsLoading(true);
        get<Chat>(`/messages/${chatId}`, auth.auth)
            .then((res) => {
                if (res.data) {
                    setChat(res.data);
                    setIsDone(res.data.isDone ?? false);
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }, [auth.auth, chatId]);

    const send = () => {
        post(`/messages/${chatId}`, { body: draft }, auth.auth).catch((e) => console.log(e));
        setNewMessages([
            {
                type: NotificationType.DirectMessage,
                chatId,
                from: currentUser.user,
                notificationId: -1,
                createdAt: dayjs().format(),
                body: draft,
            },
            ...newMessages,
        ]);
        setDraft('');
    };

    const messages = useMemo((): NotificationExpanded[] => {
        const combined: NotificationExpanded[] = [...newMessages, ...(chat?.messages ?? [])].map((m) => ({
            ...m,
            time: dayjs(m.createdAt),
            timeGapSeconds: 86400,
            isSent: m.from?.id === currentUser.userId,
            isSameSender: false,
        }));
        for (let i = 0; i < combined.length - 1; i++) {
            combined[i].timeGapSeconds = combined[i].time.diff(combined[i + 1].time, 'seconds');
            combined[i].isSameSender = combined[i].from?.id === combined[i + 1].from?.id;
        }
        return combined;
    }, [newMessages, chat?.messages, currentUser.userId]);

    return {
        chatId,
        user: chat?.user,
        messages,
        isDone,
        isLoading,
        draft,
        setDraft,
        send,
    };
};

export default useMessages;
