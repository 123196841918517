import { LinkType } from "../types";
import spotifyIcon from "../assets/icons/spotify.svg";
import soundcloudIcon from "../assets/icons/soundcloud.svg";
import appleIcon from "../assets/icons/apple.svg";
import amazonIcon from "../assets/icons/amazon.svg";
import tidalIcon from "../assets/icons/tidal.svg";
import qobuzIcon from "../assets/icons/qobuz.svg";
import pandoraIcon from "../assets/icons/pandora.svg";
import linktreeIcon from "../assets/icons/linktree.svg";
import { linkOutline } from "ionicons/icons";

export const getAsValidLink = (link: string): string|null => {
    try {
        let formatted = link;
        if (link.indexOf('http') !== 0) formatted = `http://${link}`;
        const url = new URL(formatted);
        return formatted;
    }
    catch(e){ 
        return null; 
    }
}

export const getLinkType = (link: string): LinkType => {
    const url = (new URL(link));
    const parts = url.hostname.toLowerCase().split('.');
    if (parts.length < 2) return LinkType.Unknown;
    const domain = `${parts[parts.length-2]}.${parts[parts.length-1]}`;
    switch (domain) {
        case 'spotify.com':
            return LinkType.Spotify;
        case 'apple.com':
            return LinkType.Apple;
        case 'soundcloud.com':
            return LinkType.SoundCloud;
        case 'amazon.com':
            return LinkType.Amazon;
        case 'pandora.com':
            return LinkType.Pandora;
        case 'qobuz.com':
            return LinkType.Qobuz;
        case 'tidal.com':
            return LinkType.Tidal;
        case 'linktr.ee':
            return LinkType.Linktree;
    }
    return LinkType.Unknown;
}

export const getLinkIcon = (link: string): string => {
    const type = getLinkType(link);
    switch (type) {
        case LinkType.Spotify:
            return spotifyIcon;
        case LinkType.SoundCloud:
            return soundcloudIcon;
        case LinkType.Apple:
            return appleIcon;
        case LinkType.Amazon:
            return amazonIcon;
        case LinkType.Tidal:
            return tidalIcon;
        case LinkType.Qobuz:
            return qobuzIcon;
        case LinkType.Pandora:
            return pandoraIcon;
        case LinkType.Linktree:
            return linktreeIcon;
        default:
            return linkOutline;
    }
};