import Banner from '../../../components/basic/banner.component';
import LoadingSkeleton from '../../../components/basic/loading-skeleton.component';
import PostListCard from '../../../components/feeds/post-list-card.component';
import useSongHandler from '../../../hooks/use-song-handler';
import { ApiWrapper, PostStub } from '../../../types';

interface PostsTabAreaProps {
    posts: ApiWrapper<PostStub[]>;
    showUser?: boolean;
}
const PostsTabArea = ({ posts, showUser }: PostsTabAreaProps) => {
    const { song, setSongById } = useSongHandler();
    return (
        <LoadingSkeleton loading={posts.isLoading} height="100px" count={3}>
            {posts.data ? (
                posts.data.map((p) => (
                    <PostListCard
                        key={p.postId}
                        post={p}
                        showUser={showUser}
                        onClickPlay={() => {
                            if (p.song) setSongById(p.song.songId);
                        }}
                        playing={song?.songId === p.song?.songId}
                    />
                ))
            ) : (
                <Banner severity="error">Something went wrong.</Banner>
            )}
        </LoadingSkeleton>
    );
};

export default PostsTabArea;
