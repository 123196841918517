import styled, { keyframes } from 'styled-components';
import { addAlpha } from '../../utils/color.util';
import { Link } from 'react-router-dom';

export const CommentFadeInAnimation = keyframes`
    0% { opacity: 0; transform: translateY(-50px); }
    100% { opacity: 1; transform: translateY(0); }
`;

interface PageAreaProps {
    image?: string;
}
export const PageArea = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;
export const PageBackgroundArea = styled.div<PageAreaProps>`
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
    position: fixed;
    z-index: -1;
    background-image: url(${(props) => props.image});
    background-size: cover;
    filter: blur(20px);
`;
export const PageContentArea = styled.div`
    width: calc(100% - 60px);
    max-width: 600px;
    margin: auto;
    margin-top: max(var(--ion-safe-area-top, 0), ${(props) => props.theme.margin.large});
    margin-bottom: 160px;
    z-index: 10;
`;

export const BackButton = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    border: none;
    outline: none;
    margin-bottom: ${(props) => props.theme.margin.medium};
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    /* backdrop-filter: blur(8px); */
    border-radius: 999px;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 1)};
    }
    & > ion-icon {
        font-size: 32px;
        color: ${(props) => props.theme.color.base.contrast};
    }
`;

export const CommentWriteArea = styled.div`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.large};
    width: 100%;
    padding: ${(props) => props.theme.padding.large} ${(props) => props.theme.padding.huge};
    padding-bottom: ${(props) => props.theme.padding.small};
    z-index: 20;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* backdrop-filter: blur(8px); */
    margin-top: ${(props) => props.theme.margin.large};
`;
export const CommentArea = styled.div`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.large};
    width: 100%;
    padding: ${(props) => props.theme.padding.large} ${(props) => props.theme.padding.huge};
    z-index: 20;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* backdrop-filter: blur(8px); */
    margin-top: ${(props) => props.theme.margin.large};
`;

interface CommentLayoutProps {
    $isChild?: boolean;
}
export const CommentLayout = styled.div<CommentLayoutProps>`
    margin-top: ${(props) => (props.$isChild ? props.theme.margin.medium : '0')};
`;
export const CommentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
interface CommentBodyProps {
    $isChild?: boolean;
}
export const CommentBody = styled.div<CommentBodyProps>`
    display: flex;
    flex-direction: row;
    margin-bottom: ${(props) => (props.$isChild ? '0' : props.theme.margin.medium)};
    margin-top: -2px;
`;
export const CommentAreaLine = styled.div`
    width: 3px;
    background-color: ${(props) => props.theme.color.base.contrast};
    border-radius: 10px;
    margin-left: 17px;
    margin-right: 22px;
`;

export const CommentUserPicHolder = styled.div`
    margin-top: 18px;
    margin-right: ${(props) => props.theme.margin.medium};
`;
export const CommentSendButton = styled.button`
    border: none;
    outline: none;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    background-color: ${(props) => props.theme.color.primary.main};
    border-radius: 999px;
    margin-top: 18px;
    margin-left: ${(props) => props.theme.margin.medium};
    cursor: pointer;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.4)};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    color: ${(props) => props.theme.color.base.contrast};
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.4)};
    }
    & > ion-icon {
        margin-top: 4px;
        font-size: 14px;
    }
`;
interface CommentButtonCollapseAreaProps {
    show: boolean;
}
export const CommentButtonCollapseArea = styled.div<CommentButtonCollapseAreaProps>`
    height: 100%;
    width: ${(props) => (props.show ? '44px' : '0')};
    min-width: ${(props) => (props.show ? '44px' : '0')};
    overflow: hidden;
    transition: width 0.2s, min-width 0.2s;
`;

export const CommentFadeInArea = styled.div`
    animation: ${CommentFadeInAnimation} ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;
