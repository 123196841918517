// import { useRef } from 'react';
import { ChatTextArea, ChatTextboxHolder, ChatTextboxHolderInner, MessageSendButton } from './messages.styled';
import { IonIcon } from '@ionic/react';
import { send } from 'ionicons/icons';
import useKeyboardState from '../../hooks/use-keyboard-state';
import { useEffect, useRef } from 'react';

interface ChatTextboxProps {
    id: string;
    value: string;
    setValue: (v: string) => void;
    onSend: () => void;
}
const ChatTextbox = ({ id, value, setValue, onSend }: ChatTextboxProps) => {
    // const [selected, setSelected] = useState(false);
    // const [viewportHeight, setViewportHeight] = useState<number | null>();
    // visualViewport?.addEventListener('resize', (e) => {
    //     if (!e.target) return;
    //     var h = (e.target as any)['height'];
    //     if (typeof h === 'number' && h < (viewportHeight ?? 500)) setViewportHeight(h);
    // });
    // const holderRef = useRef<HTMLDivElement | null>(null);
    const isKeyboardOpen = useKeyboardState();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    // const resetSize = () => {
    //     if (textAreaRef.current) {
    //         textAreaRef.current.style.height = '1px';
    //         textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    //     }
    // };

    // const onInput = (e: React.FormEvent) => {
    //     // resetSize();
    //     // const target = e.target as HTMLTextAreaElement;
    //     // target.style.height = '1px';
    //     // target.style.height = target.scrollHeight + 'px';
    //     // if (holderRef.current) {
    //     //     holderRef.current.style.height = '1px';
    //     //     holderRef.current.style.height = 140 + target.scrollHeight + 'px';
    //     // }
    // };

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && value.length > 0) {
            onSend();
            e.preventDefault();
            if (textAreaRef.current) {
                textAreaRef.current.focus();
                textAreaRef.current.select();
            }
            // resetSize();
        }
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '1px';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
        }
    }, [value]);

    const onSendButtonPress = () => {
        onSend();
        if (textAreaRef.current) {
            textAreaRef.current.focus();
            textAreaRef.current.select();
        }
    };

    return (
        <ChatTextboxHolder $keyboardOpen={isKeyboardOpen}>
            <ChatTextboxHolderInner>
                <ChatTextArea
                    ref={textAreaRef}
                    id={id}
                    name={id}
                    rows={1}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={onKeyDown}
                    // onFocus={onFocus}
                    // onInput={onInput}
                    // autoFocus={$autofocus}
                />
                <MessageSendButton onClick={onSendButtonPress}>
                    <IonIcon icon={send} />
                </MessageSendButton>
            </ChatTextboxHolderInner>
        </ChatTextboxHolder>
    );
};

export default ChatTextbox;
