import { chevronDownOutline } from 'ionicons/icons';
import useSongHandler from '../../hooks/use-song-handler';
import Button from '../basic/button.component';
import { StyledIcon } from '../navigation/navigation.styled';
import { Body, SmallBody, Subtitle } from '../typography.styled';
import MusicDisk from './music-disk.component';
import {
    MusicCloseButton,
    MusicDetailsArea,
    MusicDetailsBody,
    MusicDetailsButtonHolder,
    MusicDetailsContentArea,
    MusicDiskHolder,
    TrackSeekHolder,
} from './music.styled';
import LinkCollection from '../basic/link-collection.component';
import SongCreditList from './song-credit-list.component';
import useCommonAction from '../../hooks/use-common-action';
import SlideDrawer from '../navigation/slide-drawer.component';
import TrackSeeker from './track-seeker.component';
import useKeyboardState from '../../hooks/use-keyboard-state';

interface MusicDetailsProps {
    expanded: boolean;
    setOpen: (v: boolean) => void;
}
const MusicDetails = ({ expanded, setOpen }: MusicDetailsProps) => {
    const { song, state, togglePlaying } = useSongHandler();
    const { setReportSong } = useCommonAction();
    const isKeyboardShown = useKeyboardState();

    return (
        <SlideDrawer isOpen={expanded} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
            <MusicDetailsArea $expanded={expanded} $hidden={isKeyboardShown}>
                <MusicDiskHolder size={expanded ? 'large' : 'medium'}>
                    <MusicDisk
                        songState={state}
                        coverImageUrl={song?.coverImageUrl ?? null}
                        size={expanded ? 'large' : 'medium'}
                        onClick={() => {
                            if (!expanded) setOpen(true);
                            else togglePlaying();
                        }}
                    />
                </MusicDiskHolder>
                <TrackSeekHolder>
                    <TrackSeeker canDrag />
                </TrackSeekHolder>
                <MusicDetailsBody $expanded={expanded}>
                    <MusicDetailsContentArea>
                        {song ? (
                            <>
                                <Subtitle align="center">{song?.title}</Subtitle>
                                <Body align="center" margin="0 0 16px 0">
                                    {song?.artist}
                                </Body>
                                <LinkCollection links={song?.links ?? []} />
                                <MusicDetailsButtonHolder>
                                    {song?.canAnyoneRepost && (
                                        <Button
                                            type="small"
                                            to={`/post/song/${song?.songId ?? 0}`}
                                            onClick={() => setOpen(false)}
                                        >
                                            Post This Song
                                        </Button>
                                    )}
                                </MusicDetailsButtonHolder>
                                <SongCreditList credits={song?.credits ?? []} closeDrawer={() => setOpen(false)} />
                                <Button type="small" onClick={() => setReportSong(song)}>
                                    Report Song
                                </Button>
                            </>
                        ) : (
                            <SmallBody align="center">- no song playing -</SmallBody>
                        )}
                        <MusicCloseButton onClick={() => setOpen(false)} show={expanded}>
                            <StyledIcon icon={chevronDownOutline} />
                        </MusicCloseButton>
                    </MusicDetailsContentArea>
                </MusicDetailsBody>
            </MusicDetailsArea>
        </SlideDrawer>
    );
};

export default MusicDetails;
