import { useState } from 'react';
import Form from '../../../components/forms/form.component';
import SubmitButton from '../../../components/forms/submit-button.component';
import TextInput from '../../../components/forms/text-input.component';
import useAuth from '../../../hooks/use-auth';

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const authBundle = useAuth();

    const onSubmit = () => {
        authBundle.register({
            username: username,
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <TextInput id="username" label="Username" value={username} setValue={setUsername} />
            <TextInput id="email" label="Email" value={email} setValue={setEmail} />
            <TextInput id="password" label="Password" value={password} setValue={setPassword} $isPassword />
            <TextInput
                id="confirm-password"
                label="Confirm Password"
                value={passwordConfirm}
                setValue={setPasswordConfirm}
                $isPassword
            />
            <SubmitButton text="Sign Up" isLoading={authBundle.isLoading} />
        </Form>
    );
};

export default RegisterForm;
