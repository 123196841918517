import imageCompression from 'browser-image-compression';
import { useCallback, useEffect, useState } from 'react';
import { get, postFile, put } from '../services/api';
import { ApiWrapper, getLoadingApiWrapper, User } from '../types';
import { compressionOptions, convertIfHeic } from '../utils/image.util';
import useAuth from './use-auth';
import useCurrentUser from './use-current-user';

export interface UserEditBundle {
    userPreview: User;
    profilePic: ImageFileBundle | null;
    onDropProfilePic: (files: File[]) => void;
    clearProfilePic: () => void;
    background: ImageFileBundle | null;
    onDropBackground: (files: File[]) => void;
    clearBackground: () => void;
    setName: (v: string) => void;
    setTitle: (v: string) => void;
    setHomeBaseId: (v: number | null) => void;
    setMessage: (v: string) => void;
    setLinks: (l: string[]) => void;
    startUpload: () => void;
    isUploading: boolean;
    uploadProgress: number;
    uploadErrorMessage: string | null;
}

interface ImageFileBundle {
    preview: string;
    file: File;
    title: string;
}

const useUserEdit = (userId: number): UserEditBundle => {
    const [user, setUser] = useState<ApiWrapper<User>>(getLoadingApiWrapper());
    const [profilePic, setProfilePic] = useState<ImageFileBundle | null>(null);
    const [background, setBackground] = useState<ImageFileBundle | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [homeBaseId, setHomeBaseId] = useState<number | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [links, setLinks] = useState<string[] | null>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState<string | null>(null);

    const auth = useAuth();
    const currentUser = useCurrentUser();

    useEffect(() => {
        if (userId < 0) return;
        get<User>(`/user/${userId}`, auth.auth)
            .then((res) => setUser(res))
            .catch((e) => console.log(e));
    }, [userId, auth.userId, auth.auth]);

    const onDropProfilePic = useCallback((files: File[]) => {
        if (files.length !== 1) return;
        Promise.all(files.map((f) => convertIfHeic(f).then((f) => imageCompression(f, compressionOptions))))
            .then((out) => {
                const file = out[0];
                setProfilePic({
                    preview: URL.createObjectURL(file),
                    file: file,
                    title: file.name,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const clearProfilePic = () => {
        setProfilePic(null);
    };

    const onDropBackground = useCallback((files: File[]) => {
        if (files.length !== 1) return;
        Promise.all(files.map((f) => convertIfHeic(f).then((f) => imageCompression(f, compressionOptions))))
            .then((out) => {
                const file = out[0];
                setBackground({
                    preview: URL.createObjectURL(file),
                    file: file,
                    title: file.name,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const clearBackground = () => {
        setBackground(null);
    };

    const userPreview: User = {
        id: userId,
        username: user.data?.username ?? '',
        displayName: name ?? user.data?.displayName ?? '',
        title: title ?? user.data?.title ?? '',
        homeBaseId: homeBaseId ?? user.data?.homeBaseId,
        profileMessage: message ?? user.data?.profileMessage ?? '',
        profilePicUrl: profilePic?.preview ?? user.data?.profilePicUrl ?? undefined,
        backgroundImage: background?.preview ?? user.data?.backgroundImage ?? undefined,
        links: links ?? user.data?.links ?? [],
        followers: [],
        following: [],
        favoriteFeeds: [],
    };

    const startUpload = () => {
        setUploadProgress(25);
        put<User>(`/user/${userId}`, userPreview, auth.auth)
            .then((res) => {
                if (res.hadError) {
                    setUploadError(res.errorMessage ?? 'Something went wrong');
                    return;
                }
                setUploadProgress(50);
                if (profilePic) {
                    postFile<User>(`/user/${userId}/picture`, profilePic.file, auth.auth)
                        .then((res) => {
                            console.log(res);
                            setUploadProgress(75);
                            if (background) {
                                postFile<User>(`/user/${userId}/background`, background.file, auth.auth)
                                    .then((res) => {
                                        setUploadProgress(100);
                                        currentUser.reload();
                                    })
                                    .catch((e) => console.log(e));
                            } else {
                                setUploadProgress(100);
                                currentUser.reload();
                            }
                        })
                        .catch((e) => console.log(e));
                } else if (background) {
                    postFile<User>(`/user/${userId}/background`, background.file, auth.auth)
                        .then((res) => {
                            console.log(res);
                            setUploadProgress(100);
                            currentUser.reload();
                        })
                        .catch((e) => console.log(e));
                } else {
                    setUploadProgress(100);
                    currentUser.reload();
                }
            })
            .catch((e) => console.log(e));
    };

    return {
        userPreview,
        profilePic,
        onDropProfilePic,
        clearProfilePic,
        background,
        onDropBackground,
        clearBackground,
        setName,
        setTitle,
        setHomeBaseId,
        setMessage,
        setLinks,
        startUpload,
        isUploading: uploadProgress > 0,
        uploadProgress,
        uploadErrorMessage: uploadError,
    };
};

export default useUserEdit;
