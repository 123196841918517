import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { addAlpha, darkenColor, lightenColor } from '../../utils/color.util';

export const PageAreaContent = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

export const ProfilePicContainerArea = styled.div`
    position: relative;
`;
export const EditProfileButton = styled(Link)`
    position: absolute;
    bottom: 0;
    right: 0;
    border: solid 1px ${(props) => props.theme.color.base.contrast};
    outline: none;
    background-color: ${(props) => props.theme.color.base.main};
    color: ${(props) => props.theme.color.base.contrast};
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.2)};
    }
`;

export const ContentCenterer = styled.div`
    width: calc(100% - ${(props) => props.theme.margin.huge});
    max-width: 600px;
    margin: auto;
    padding-top: ${(props) => props.theme.margin.huge};
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
    margin-bottom: 200px;
`;

interface UserBackgroundImageAreaProps {
    image: string | null;
}
export const UserBackgroundImageArea = styled.div<UserBackgroundImageAreaProps>`
    position: relative;
    width: 100%;
    height: 300px;
    ${(props) =>
        props.image
            ? `
        background-image: url(${props.image});
    `
            : `
        background-color: ${props.theme.color.base.main};
    `}
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: top;
    top: 0px;
    box-shadow: inset 0 -40px 32px -25px ${(props) => props.theme.color.base.dark};
`;

export const MainAreaContainer = styled.div`
    width: 100%;
    z-index: 104;
    margin-top: -100px;
`;

export const BackgroundFadeArea = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px) saturate(200%);
    background-size: calc(200% + 50px) 100%;
`;

export const MainAreaBase = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.color.base.dark};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MainArea = styled.div`
    margin-top: -143px;
    margin-bottom: 60px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FollowersLink = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.theme.padding.medium};
    margin: -57px 16px 0 16px;
    border: none;
    outline: none;
    border-radius: ${(props) => props.theme.border.radius.medium};
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const UserNameArea = styled.div`
    max-width: 300px;
    min-width: 150px;
    width: 100%;
    padding: 0 10px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const UserTabHolder = styled.div`
    position: relative;
    height: 80px;
    margin-top: -40px;
    z-index: 20;
    display: flex;
    width: 100%;
`;

interface UserTabLinkProps {
    tab: number;
    tabOn: number;
}
export const UserTabButton = styled.button<UserTabLinkProps>`
    height: 100%;
    color: ${(props) => props.theme.color.base.contrast};
    background-color: ${(props) =>
        props.tab === props.tabOn ? props.theme.color.base.main : props.theme.color.base.dark};
    outline: none;
    border: none;
    padding: 10px;
    z-index: 20;
    ${(props) => props.tab === props.tabOn && `border-radius: ${props.theme.border.radius.huge};`}
    ${(props) => props.tab === props.tabOn - 1 && `border-radius: 0 0 ${props.theme.border.radius.huge} 0;`}
    ${(props) => props.tab === props.tabOn + 1 && `border-radius: 0 0 0 ${props.theme.border.radius.huge};`}
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    &:first-child {
        width: 100%;
        ${(props) =>
            props.tab === props.tabOn &&
            `border-radius: 0 ${props.theme.border.radius.huge} ${props.theme.border.radius.huge} 0;`}
        justify-content: flex-end;
    }
    &:last-child {
        width: 100%;
        ${(props) =>
            props.tab === props.tabOn &&
            `border-radius: ${props.theme.border.radius.huge} 0 0 ${props.theme.border.radius.huge};`}
        justify-content: flex-start;
    }
    > * {
        width: 100%;
        min-width: 80px;
        max-width: 170px;
        @media screen and (max-width: 500px) {
            width: 100%;
            min-width: 25vw;
            max-width: 100%;
        }
    }
`;

export const UserTabAreaBase = styled.div`
    width: 100%;
    margin-top: -${(props) => props.theme.border.radius.huge};
    background-color: ${(props) => props.theme.color.base.main};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: calc(${(props) => props.theme.border.radius.huge} + 16px);
    padding-bottom: 150px;
`;

export const UserTabArea = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface StyledDrawerProps {
    $show: boolean;
}
export const StyledDrawer = styled.div<StyledDrawerProps>`
    position: fixed;
    top: ${(props) => (props.$show ? '0' : '100vh')};
    height: ${(props) => (props.$show ? '100vh' : '200px')};
    width: 100%;
    z-index: 100;
    transition: all 0.2s;
`;
export const StyledDrawerBackdrop = styled.div<StyledDrawerProps>`
    height: ${(props) => (props.$show ? '100px' : '0')};
    width: 100%;
    transition: all 0.2s;
`;
export const StyledDrawerBody = styled.div<StyledDrawerProps>`
    height: ${(props) => (props.$show ? 'calc(100vh - 100px)' : '200px')};
    width: 100%;
    background-color: ${(props) => props.theme.color.base.main};
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const DrawerCloseButton = styled.button`
    background-color: ${(props) => props.theme.color.base.main};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 40px;
    width: 100px;
    height: 50px;
    margin-top: 8px;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.1)};
    }
`;
export const FollowerDrawerSearchContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin: ${(props) => props.theme.margin.medium};
    display: flex;
    flex-direction: column;
`;
export const FollowerDrawerList = styled.div`
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    padding-bottom: 150px;
`;

interface UserActionButtonProps {
    $right?: boolean;
}
export const UserActionButton = styled.button<UserActionButtonProps>`
    position: fixed;
    top: calc(16px + var(--ion-safe-area-top, 0));
    outline: none;
    border: none;
    ${(props) => (props.$right ? 'right: 16px;' : 'left: 16px;')}
    z-index: 100;
    width: 60px;
    height: 60px;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.8)};
    border-radius: 50%;
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const UserSettingsArea = styled.div`
    position: fixed;
    top: calc(86px + var(--ion-safe-area-top, 0));
    outline: none;
    border: solid 1px white;
    right: 16px;
    z-index: 100;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.medium};
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    & > button:last-child {
        background-color: ${(props) => props.theme.color.error.main};
        color: ${(props) => props.theme.color.error.contrast};
        &:hover {
            background-color: ${(props) => darkenColor(props.theme.color.error.main, 0.2)};
        }
    }
`;
export const UserActionsIcon = styled(IonIcon)`
    pointer-events: none;
`;

export const FixedTitleArea = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.color.base.main};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    padding-top: var(--ion-safe-area-top, 0);
`;
export const FixedTitleContent = styled.div`
    width: calc(100% - ${(props) => props.theme.margin.huge});
    border-bottom: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.33)};
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.padding.large} 0;
`;

interface BackgroundImagePreviewProps {
    image?: string;
}
export const BackgroundImagePreview = styled.div<BackgroundImagePreviewProps>`
    width: 100%;
    height: 200px;
    ${(props) =>
        props.image
            ? `
        background-image: url(${props.image});
    `
            : `
        background-color: ${props.theme.color.base.main};
    `}
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.33)};
`;

export const MoreOptionsArea = styled.div`
    position: absolute;
    right: 0;
    bottom: 58px;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.medium};
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
`;
export const MoreOptionsOption = styled.button`
    width: 100%;
    border: none;
    outline: none;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.4)};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 1em;
    font-weight: 200;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.4s;
    &:not(:last-child) {
        border-bottom: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.4)};
    }
`;

export const BlockAlert = styled.div`
    position: fixed;
    z-index: 1000;
    top: 80px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
        background-color: ${(props) => props.theme.color.error.main};
        color: ${(props) => props.theme.color.error.contrast};
        padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
        border-radius: ${(props) => props.theme.border.radius.medium};
        width: fit-content;
    }
`;

interface DeleteConfirmProps {
    $checked: boolean;
}
export const DeleteConfirm = styled.div<DeleteConfirmProps>`
    width: 24px;
    height: 24px;
    outline: solid 1px ${(props) => props.theme.color.base.contrast};
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: ${(props) => (props.$checked ? props.theme.color.error.main : props.theme.color.base.dark)};
    & > * {
        display: ${(props) => (props.$checked ? 'auto' : 'none')};
    }
`;
