import useCommonAction from '../../hooks/use-common-action';
import useReport, { UseReportBundle } from '../../hooks/use-report';
import { ReportReason } from '../../types/posts/report-reason';
import Button from '../basic/button.component';
import Drawer from '../basic/drawer.component';
import { FlexContainer } from '../components.styled';
import SlideDrawer from '../navigation/slide-drawer.component';
import { Body, Comment } from '../typography.styled';
import { ReportReasonChip } from './posts.styled';

interface ReportReasonToggleProps {
    report: UseReportBundle;
    reason: ReportReason;
    name: string;
}
const ReportReasonToggle = ({ report, reason, name }: ReportReasonToggleProps) => {
    return (
        <ReportReasonChip $selected={report.hasReason(reason)} onClick={() => report.toggleReason(reason)}>
            {name}
        </ReportReasonChip>
    );
};

interface ReportDrawerProps {
    show: boolean;
}
const ReportDrawer = ({ show }: ReportDrawerProps) => {
    const {
        reportPost,
        reportComment,
        reportSong,
        reportUser,
        setReportPost,
        setReportComment,
        setReportSong,
        setReportUser,
    } = useCommonAction();
    const report = useReport();

    const cancelReport = () => {
        setReportPost(null);
        setReportComment(null);
        setReportSong(null);
        setReportUser(null);
        report.setReasons([]);
    };

    const submitReport = () => {
        if (reportPost) report.submitPostReport(reportPost);
        if (reportComment) report.submitCommentReport(reportComment);
        if (reportSong) report.submitSongReport(reportSong);
        if (reportUser) report.submitUserReport(reportUser);
    };

    const getReportTitle = (): string => {
        if (!!reportPost) return 'Report Post';
        if (!!reportComment) return 'Report Comment';
        if (!!reportSong) return 'Report Song';
        if (!!reportUser) return 'Report User';
        return 'Report';
    };
    const getReportMessage = (): string => {
        if (!!reportPost) return 'The owner of this post will not be shown who reported their post.';
        if (!!reportComment) return 'The owner of this comment will not be shown who reported it.';
        if (!!reportSong) return 'The owner of this song will not be shown who reported it.';
        if (!!reportUser) return 'This user will not be showsn who reported them.';
        return '';
    };

    return (
        <SlideDrawer isOpen={show} onClose={cancelReport}>
            <Drawer show={show} onClose={cancelReport}>
                <p>
                    <Body>{getReportTitle()}</Body>
                </p>
                <Comment>{getReportMessage()} Please select all that apply.</Comment>
                <FlexContainer $wrap>
                    <ReportReasonToggle report={report} reason={ReportReason.Copyright} name="Copyright Violation" />
                    <ReportReasonToggle report={report} reason={ReportReason.Nudity} name="Nudity/Sexual Activity" />
                    <ReportReasonToggle
                        report={report}
                        reason={ReportReason.Harassment}
                        name="Bullying or Harassment"
                    />
                    <ReportReasonToggle report={report} reason={ReportReason.HateSpeech} name="Hate Speech" />
                    <ReportReasonToggle report={report} reason={ReportReason.Fraud} name="Fraud/Impersonation" />
                    <ReportReasonToggle
                        report={report}
                        reason={ReportReason.PersonalInfo}
                        name="Sharing Personal Information"
                    />
                    <ReportReasonToggle report={report} reason={ReportReason.Violence} name="Threatening Violence" />
                    <ReportReasonToggle
                        report={report}
                        reason={ReportReason.IllegalTransaction}
                        name="Illegal Transaction"
                    />
                    <ReportReasonToggle report={report} reason={ReportReason.Spam} name="Spam" />
                    <ReportReasonToggle report={report} reason={ReportReason.Other} name="Other" />
                </FlexContainer>
                <Button type="primary" onClick={submitReport} disabled={!report.reasons || report.reasons.length === 0}>
                    Submit
                </Button>
            </Drawer>
        </SlideDrawer>
    );
};

export default ReportDrawer;
