export enum FeedType {
    Home = 0,
    Discover = 1
};

export const getFeedTypeString = (feedType: FeedType): string => {
    switch (feedType) {
        case FeedType.Home:
            return "Home";
        case FeedType.Discover:
            return "Discover";
    }
    return "";
};