import styled from 'styled-components';
import { addAlpha } from '../../utils/color.util';

export const ContentArea = styled.div`
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: auto;
    padding-top: calc(var(--ion-safe-area-top, 0) + ${(props) => props.theme.margin.large});
    padding-bottom: calc(var(--ion-safe-area-bottom, 0) + ${(props) => props.theme.margin.large});
    padding-left: ${(props) => props.theme.margin.large};
    padding-right: ${(props) => props.theme.margin.large};
    display: flex;
    flex-direction: column;
    & > button {
        margin-left: auto;
        margin-top: ${(props) => props.theme.margin.large};
        height: 80px;
    }
`;

export const TermsArea = styled.div`
    width: 100%;
    flex-grow: 1;
    color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.7)};
    border: solid 1px ${(props) => props.theme.color.base.contrast};
    padding: ${(props) => props.theme.padding.medium};
    overflow-y: scroll;
    & > h3 {
        margin-bottom: ${(props) => props.theme.margin.large};
        margin-top: ${(props) => props.theme.margin.large};
    }
    & > p {
        margin-bottom: ${(props) => props.theme.margin.medium};
    }
`;

export const LoadingHolder = styled.div`
    margin: auto;
    margin-top: ${(props) => props.theme.margin.large};
    height: 80px;
`;
