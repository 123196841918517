import { PermanentSplashAreaGray, SplashAreaGray } from './components.styled';
import LoadingSpinner from './basic/loading-spinner.component';

interface SplashProps {
    show: boolean;
}

const FullscreenLoading = ({ show }: SplashProps) => {
    if (show) {
        return (
            <PermanentSplashAreaGray>
                <LoadingSpinner />
            </PermanentSplashAreaGray>
        );
    }
    return (
        <SplashAreaGray>
            <LoadingSpinner />
        </SplashAreaGray>
    );
};

export default FullscreenLoading;
