import { useState } from 'react';
import { CommonActionContextType } from '../../context/common-action.context';
import { Comment, PostStub, SongStub, UserStub } from '../../types';

const useCommonActionContext = (): CommonActionContextType => {
    const [showShareDrawer, setShowShareDrawer] = useState<boolean>(false);
    const [shareLink, setShareLink] = useState<string | null>(null);
    const [sharePost, setSharePost] = useState<PostStub | null>(null);

    const [reportPost, setReportPost] = useState<PostStub | null>(null);
    const [reportComment, setReportComment] = useState<Comment | null>(null);
    const [reportSong, setReportSong] = useState<SongStub | null>(null);
    const [reportUser, setReportUser] = useState<UserStub | null>(null);

    return {
        showShareDrawer,
        setShowShareDrawer,
        shareLink,
        setShareLink,
        sharePost,
        setSharePost,
        reportPost,
        setReportPost,
        reportComment,
        setReportComment,
        reportSong,
        setReportSong,
        reportUser,
        setReportUser,
    };
};
export default useCommonActionContext;
