import { LogoImage } from "../login.styled";
import logo from "../../../assets/logo.png";
import { Impact, Comment } from "../../../components/typography.styled";
import Button from "../../../components/basic/button.component";

interface CoverTextProps {
    onContinue: () => void
};
const CoverText = ({ onContinue }: CoverTextProps) => {
    return (
        <>
            <LogoImage src={logo}/>
            <Impact>
                The only social media app you'll need to start your music journey
            </Impact>
            <Comment align="center" padding="40px 64px 64px 64px">
                Get plugged into the latest music in your area and region.
                Find the songs and artists that ressonate with your vision, and start getting discovered.
            </Comment>
            <Button type="big" onClick={onContinue}>Get Started</Button>
        </>
    )
};

export default CoverText;