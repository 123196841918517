import { useEffect, useState } from 'react';
import { get } from '../services/api';
import { ApiWrapper, getLoadingApiWrapper, User, PostStub } from '../types';
import { UserSong } from '../types/music/user-song';
import useAuth from './use-auth';

const useUser = (userId: number) => {
    const [user, setUser] = useState<ApiWrapper<User>>(getLoadingApiWrapper<User>());
    const [posts, setPosts] = useState<ApiWrapper<PostStub[]>>(getLoadingApiWrapper<PostStub[]>());
    const [likes, setLikes] = useState<ApiWrapper<PostStub[]>>(getLoadingApiWrapper<PostStub[]>());
    const [songs, setSongs] = useState<ApiWrapper<UserSong[]>>(getLoadingApiWrapper<UserSong[]>());

    const auth = useAuth();

    useEffect(() => {
        if (!auth.auth) return;
        get<User>(`/user/${userId}`, auth.auth)
            .then((res) => {
                setUser(res);
            })
            .catch((e) => console.log(e));

        get<UserSong[]>(`/song/user/${userId}`, auth.auth)
            .then((res) => {
                setSongs(res);
            })
            .catch((e) => console.log(e));

        get<PostStub[]>(`/post/user/${userId}`, auth.auth)
            .then((res) => {
                setPosts(res);
            })
            .catch((e) => console.log(e));

        get<PostStub[]>(`/post/user/${userId}/liked`, auth.auth)
            .then((res) => {
                setLikes(res);
            })
            .catch((e) => console.log(e));
    }, [userId, auth.auth, auth.auth?.accessToken, auth.userId]);

    return { user, posts, likes, songs };
};

export default useUser;
