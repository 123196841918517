import { FlexContainer } from '../components.styled';
import { StyledIcon } from '../navigation/navigation.styled';
import { Body, Comment, SmallBody } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import {
    PostBodyArea,
    PostButton,
    PostCardArea,
    PostEllipsisButton,
    PostLikeButton,
    PostUserPreviewArea,
    RepostMessageArea,
} from './feed.styled';
import { heartOutline, paperPlaneOutline, chatbubblesOutline, ellipsisHorizontal, repeatOutline } from 'ionicons/icons';
import { PostBodyBundle } from '../../hooks/use-post';
import PostBody from './post-body.component';
import PostImageCarousel from './post-image-carousel.component';
import { PostStub } from '../../types';
import { useState } from 'react';
import { IonIcon } from '@ionic/react';
import useCurrentUser from '../../hooks/use-current-user';

interface PostCardPreviewProps {
    post: PostBodyBundle;
    repost?: PostStub;
}
const PostCardPreview = ({ post, repost }: PostCardPreviewProps) => {
    const [isMinimized, setIsMinimized] = useState(false);

    const iconSize = '32px';
    const carouselSize = repost ? '200px' : '300px';
    const currentUser = useCurrentUser();

    return (
        <>
            <PostCardArea
                $minimized={isMinimized}
                style={{ marginTop: '-50px' }}
                onClick={() => isMinimized && setIsMinimized(false)}
            >
                <FlexContainer direction="row" align="stretch" justify="space-between">
                    <PostUserPreviewArea>
                        <ProfilePic size="small" user={currentUser.user ?? null} />
                        <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                            <SmallBody>{currentUser.user?.displayName ?? ''}</SmallBody>
                            <Comment style={{ marginTop: '-6px' }}>@{currentUser.user?.username ?? ''}</Comment>
                        </FlexContainer>
                    </PostUserPreviewArea>
                    <PostEllipsisButton>
                        <StyledIcon icon={ellipsisHorizontal} style={{ fontSize: '24px' }} />
                    </PostEllipsisButton>
                </FlexContainer>
                <PostBodyArea>
                    <Body>
                        <PostBody body={post.body} />
                    </Body>
                </PostBodyArea>
                <FlexContainer direction="row" align="stretch" justify="space-between" margin="20px 0 0 0">
                    <PostLikeButton reacted={false}>
                        <StyledIcon icon={heartOutline} style={{ fontSize: iconSize }} />
                        <Body $noWidth100 margin="0 0 0 12px">
                            1
                        </Body>
                    </PostLikeButton>
                    <PostButton>
                        <StyledIcon icon={paperPlaneOutline} style={{ fontSize: iconSize }} />
                    </PostButton>
                    <PostButton>
                        <StyledIcon icon={chatbubblesOutline} style={{ fontSize: iconSize }} />
                        <Body $noWidth100 margin="0 0 0 12px">
                            0
                        </Body>
                    </PostButton>
                </FlexContainer>
            </PostCardArea>
            {repost && (
                <RepostMessageArea>
                    <IonIcon icon={repeatOutline} />
                    <Body>Reposted</Body>
                </RepostMessageArea>
            )}
            {repost && (
                <PostCardArea $isRepost $minimized={!isMinimized} onClick={() => !isMinimized && setIsMinimized(true)}>
                    <FlexContainer direction="row" align="stretch" justify="space-between">
                        <PostUserPreviewArea>
                            <ProfilePic size="small" user={repost.user ?? null} />
                            <FlexContainer direction="column" justify="center" margin="0 0 0 10px">
                                <SmallBody>{repost.user?.displayName ?? ''}</SmallBody>
                                <Comment style={{ marginTop: '-6px' }}>@{repost.user?.username ?? ''}</Comment>
                            </FlexContainer>
                        </PostUserPreviewArea>
                        <PostEllipsisButton>
                            <StyledIcon icon={ellipsisHorizontal} style={{ fontSize: '24px' }} />
                        </PostEllipsisButton>
                    </FlexContainer>
                    <PostBodyArea>
                        <Body>
                            <PostBody body={repost.body} />
                        </Body>
                    </PostBodyArea>
                    {repost.media && repost.media.length > 0 && (
                        <PostImageCarousel images={repost.media.map((i) => i)} size={carouselSize} />
                    )}
                    <FlexContainer direction="row" align="stretch" justify="space-between" margin="20px 0 0 0">
                        <PostLikeButton reacted={false}>
                            <StyledIcon icon={heartOutline} style={{ fontSize: iconSize }} />
                            <Body $noWidth100 margin="0 0 0 12px">
                                1
                            </Body>
                        </PostLikeButton>
                        <PostButton>
                            <StyledIcon icon={paperPlaneOutline} style={{ fontSize: iconSize }} />
                        </PostButton>
                        <PostButton>
                            <StyledIcon icon={chatbubblesOutline} style={{ fontSize: iconSize }} />
                            <Body $noWidth100 margin="0 0 0 12px">
                                0
                            </Body>
                        </PostButton>
                    </FlexContainer>
                </PostCardArea>
            )}
        </>
    );
};

export default PostCardPreview;
