import { useEffect } from "react";
import { StyledSmallLabel, StyledSmallLabelText, StyledSmallTextArea, StyledSmallTextInput } from "./form.styled";

interface SmallTextInputProps {
    id: string;
    label: string;
    value: string;
    setValue: (v: string) => void;
    $multiline?: boolean;
    $isPassword?: boolean;
    $grayout?: boolean;
    $autofocus?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onFocus?: () => void;
};
const SmallTextInput = ({ id, label, value, setValue, $multiline, $isPassword, $grayout, $autofocus, onKeyDown, onFocus }: SmallTextInputProps) => {
    const onInput = (e: React.FormEvent) => {
        const target = e.target as HTMLTextAreaElement;
        target.style.height = "1px";
        target.style.height = (target.scrollHeight)+"px";
    };
    useEffect(() => {
        if (value.length !== 0 || !$multiline) return;
        const target = document.getElementById(id) as HTMLTextAreaElement;
        if (target) {
            target.style.height = "1px";
            target.style.height = (target.scrollHeight)+"px";
        }
    }, [id, value, $multiline]);
    return (
        <>
            <StyledSmallLabel htmlFor={id} $hasInput={value ? true : false} $grayout={$grayout}>
                <StyledSmallLabelText>{label}</StyledSmallLabelText>
                {
                    $multiline
                        ? <StyledSmallTextArea
                            id={id}
                            name={id}
                            rows={2}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            onKeyDown={e => onKeyDown && onKeyDown(e)}
                            onFocus={onFocus}
                            onInput={onInput}
                            autoFocus={$autofocus}
                        />
                        : <StyledSmallTextInput
                            type={$isPassword ? "password" : "text"}
                            id={id}
                            name={id}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            onKeyDown={e => onKeyDown && onKeyDown(e)}
                            onFocus={onFocus}
                            autoFocus={$autofocus}
                        />
                }
            </StyledSmallLabel>
        </>
    );
};

export default SmallTextInput;