import styled from 'styled-components';
import { BadgeSize } from '../../types/basic';
import { addAlpha } from '../../utils/color.util';

const getPxSize = (size: BadgeSize): string => {
    switch (size) {
        case 'tiny':
            return '24px';
        case 'small':
            return '36px';
        case 'medium':
            return '56px';
        case 'large':
            return '128px';
    }
};
const getFontSize = (size: BadgeSize): string => {
    switch (size) {
        case 'tiny':
            return '16px';
        case 'small':
            return '22px';
        case 'medium':
            return '36px';
        case 'large':
            return '88px';
    }
};
const colors = [
    '#E5384B',
    '#db2e79',
    '#d43dae',
    '#c53dd4',
    '#9d3dd4',
    '#7e3dd4',
    '#513dd4',
    '#3d84d4',
    '#3db6d4',
    '#3dd4c7',
    '#3dd49d',
    '#3dd460',
    '#72d43d',
    '#acd43d',
    '#cfd43d',
    '#e8b425',
    '#e87a25',
    '#e84925',
];
const getColor = (n: number): string => {
    return colors[n % colors.length];
};
const getGradient = (n: number): string => {
    if (n < 0) return `linear-gradient(137deg, #ccc 0%, #ccc 100%)`;
    return `linear-gradient(137deg, ${getColor(n)} 0%, ${getColor(n + 1)} 100%)`;
};

interface UserImageProps {
    size: BadgeSize;
    $highlight?: boolean;
    image: string;
}
export const UserImage = styled.div<UserImageProps>`
    width: ${(props) => getPxSize(props.size)};
    height: ${(props) => getPxSize(props.size)};
    min-width: ${(props) => getPxSize(props.size)};
    min-height: ${(props) => getPxSize(props.size)};
    border-radius: 1000px;
    border: ${(props) => (props.$highlight ? `solid 4px ${props.theme.color.primary.main}` : 'solid 2px white')};
    background-image: ${(props) => props.image};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;
interface AdminImageProps {
    size: BadgeSize;
    $highlight?: boolean;
}
export const AdminImage = styled.img<AdminImageProps>`
    width: ${(props) => getPxSize(props.size)};
    height: ${(props) => getPxSize(props.size)};
    min-width: ${(props) => getPxSize(props.size)};
    min-height: ${(props) => getPxSize(props.size)};
    border-radius: 1000px;
    /* border: solid 2px ${(props) => props.theme.color.primary.main}; */
`;

interface UserDefaultBadgeProps {
    size: BadgeSize;
    $highlight?: boolean;
    userId: number;
}
export const UserDefaultBadge = styled.div<UserDefaultBadgeProps>`
    width: ${(props) => getPxSize(props.size)};
    height: ${(props) => getPxSize(props.size)};
    min-width: ${(props) => getPxSize(props.size)};
    min-height: ${(props) => getPxSize(props.size)};
    border-radius: 1000px;
    border: ${(props) => (props.$highlight ? `solid 4px ${props.theme.color.primary.main}` : 'solid 2px white')};
    background: ${(props) => getGradient(props.userId)};
    text-decoration: none !important;
    color: white;
    font-size: ${(props) => getFontSize(props.size)};
    text-align: center;
`;

interface UserListingAreaProps {
    $noBorder?: boolean;
}
export const UserListingArea = styled.div<UserListingAreaProps>`
    width: 100%;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.$noBorder ? '' : `border-bottom: solid 1px ${addAlpha(props.theme.color.base.contrast, 0.1)};`)}
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
    &:active {
        transition: background-color 0s;
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:last-child {
        border-bottom: none;
    }
`;
interface UserListingAreaProps {
    $noBorder?: boolean;
}
export const UserListingAreaSimple = styled.div<UserListingAreaProps>`
    width: 100%;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.$noBorder ? '' : `border-bottom: solid 1px ${addAlpha(props.theme.color.base.contrast, 0.1)};`)}
    &:last-child {
        border-bottom: none;
    }
`;
