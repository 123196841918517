import { ThemeProvider } from 'styled-components';
import Theme from './theme/theme';
import SongContextProvider from './context/song.context';
import { ReactNode } from 'react';
import CommonDataContextProvider from './context/common.context';
import NotificationContextProvider from './context/notification.context';
import CurrentUserContextProvider from './context/current-user.context';
import { AuthProvider } from './context/auth.context';
import useAuthControl from './hooks/use-auth-control';
import CommonActionContextProvider from './context/common-action.context';
import FeatureFlagContextProvider from './context/feature-flag.context';

interface ProvidersProps {
    children: ReactNode;
}
const Providers = ({ children }: ProvidersProps) => {
    const authBundle = useAuthControl();

    return (
        <AuthProvider value={authBundle}>
            <CurrentUserContextProvider>
                <CommonDataContextProvider>
                    <CommonActionContextProvider>
                        <FeatureFlagContextProvider>
                            <NotificationContextProvider>
                                <SongContextProvider>
                                    <ThemeProvider theme={Theme}>{children}</ThemeProvider>
                                </SongContextProvider>
                            </NotificationContextProvider>
                        </FeatureFlagContextProvider>
                    </CommonActionContextProvider>
                </CommonDataContextProvider>
            </CurrentUserContextProvider>
        </AuthProvider>
    );
};

export default Providers;
