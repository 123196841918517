import { UserStub } from '../../types';

interface PostBodyProps {
    body: string;
    mentions?: UserStub[];
}
const PostBody = ({ body, mentions }: PostBodyProps) => {
    const regex = /(\B[#@][\w-_.]*\w|\r?\n)/g;
    const parts = body
        .trim()
        .split(regex)
        .filter((p) => p.length > 0);

    return (
        <>
            {parts.map((p, i) => {
                if (p.indexOf('\n') >= 0) return <br key={i} />;
                if (p.startsWith('#')) return <b key={i}>{p}</b>;
                if (p.startsWith('@')) {
                    return <b key={i}>{p}</b>;
                }
                return <span key={i}>{p}</span>;
            })}
        </>
    );
};

export default PostBody;
