import { useEffect, useRef, useState } from 'react';
import { FlexContainer } from '../components.styled';
import {
    LinkInputItem,
    LinkInputListArea,
    LinkXButton,
    NoLinksCommentHolder,
    StyledChipSuggestion,
    StyledChipSuggestionArea,
    StyledSmallLabel,
    StyledSmallLabelText,
    StyledSmallTextInput,
} from './form.styled';
import SmallTextInput from './small-text-input.component';
import Button from '../basic/button.component';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Comment, SmallBody, Subtitle } from '../typography.styled';
import { SongCredit, UserStub } from '../../types';
import Modal from '../basic/modal.component';
import Form from './form.component';
import useDebounce from '../../hooks/use-debounce';
import useUserSearch from '../../hooks/use-user-search';
import UserListing from '../user/user-listing.component';
import ProfilePic from '../user/profile-pic.component';

interface SongCreditInputProps {
    credits: SongCredit[];
    setCredits: (c: SongCredit[]) => void;
}
const SongCreditInput = ({ credits, setCredits }: SongCreditInputProps) => {
    const [open, setOpen] = useState(false);
    const [credit, setCredit] = useState<SongCredit>({ role: '', name: '' });
    const suggestions = useUserSearch(credit.name ?? '');

    const addCredit = () => {
        setCredits([...credits, credit]);
        setCredit({ role: '', name: '' });
        setOpen(false);
    };
    const removeCredit = (i: number) => {
        setCredits(credits.filter((_, ind) => ind !== i));
    };

    const onSelectUser = (user: UserStub) => {
        setCredit({
            ...credit,
            name: null,
            user: user,
        });
    };

    const inputElement = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (open && inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
        }
    }, [open]);

    return (
        <FlexContainer direction="column">
            <Modal show={open} onClose={() => setOpen(false)} top>
                <Subtitle margin="0 0 20px 0" $block>
                    Add Song Credit
                </Subtitle>
                <FlexContainer direction="column">
                    <StyledSmallLabel htmlFor="role" $hasInput={!!credit.role}>
                        <StyledSmallLabelText>Role</StyledSmallLabelText>
                        <StyledSmallTextInput
                            type="text"
                            id="role"
                            name="role"
                            value={credit.role}
                            onChange={(e) => setCredit({ ...credit, role: e.target.value })}
                            ref={inputElement}
                        />
                    </StyledSmallLabel>
                    {credit.user ? (
                        <FlexContainer align="center">
                            <UserListing user={credit.user} noBorder />
                            <LinkXButton onClick={() => setCredit({ ...credit, name: '', user: null })}>
                                <IonIcon icon={closeOutline} />
                            </LinkXButton>
                        </FlexContainer>
                    ) : (
                        <>
                            <SmallTextInput
                                id="name"
                                label="Name"
                                value={credit.name ?? ''}
                                setValue={(n) => setCredit({ ...credit, name: n })}
                            />
                            <StyledChipSuggestionArea>
                                {suggestions.map((s, i) => (
                                    <StyledChipSuggestion key={i} onClick={() => onSelectUser(s)}>
                                        <UserListing user={s} />
                                    </StyledChipSuggestion>
                                ))}
                            </StyledChipSuggestionArea>
                        </>
                    )}
                    <FlexContainer direction="row" justify="flex-end">
                        <Button
                            type="small"
                            disabled={credit.role === '' || (!credit.name && !credit.user)}
                            onClick={addCredit}
                        >
                            Add Credit
                        </Button>
                    </FlexContainer>
                </FlexContainer>
            </Modal>
            <FlexContainer direction="row" align="center">
                <Button type="small" onClick={() => setOpen(true)}>
                    Add new credit
                </Button>
            </FlexContainer>
            <LinkInputListArea>
                {credits.length === 0 && (
                    <NoLinksCommentHolder>
                        <Comment $noWidth100>- No Credits -</Comment>
                    </NoLinksCommentHolder>
                )}
                {credits.map((c, i) => (
                    <LinkInputItem key={i}>
                        {/* <IonIcon src={getLinkIcon(l)}/>
                        <a href={l}>{l}</a> */}
                        {c.user && <ProfilePic user={c.user} size="tiny" />}
                        <SmallBody>
                            {c.user?.displayName ?? c.name ?? ''}
                            <Comment>{` ● ${c.role}`}</Comment>
                        </SmallBody>
                        <LinkXButton onClick={() => removeCredit(i)}>
                            <IonIcon icon={closeOutline} />
                        </LinkXButton>
                    </LinkInputItem>
                ))}
            </LinkInputListArea>
        </FlexContainer>
    );
};

export default SongCreditInput;
