import {
    TrackSeekContainer,
    TrackSeekSliderContainer,
    TrackSeekSliderCurrentPos,
    TrackSeekSliderHandle,
    TrackSeekSliderHandleBall,
    TrackSeekSliderHandleLine,
    TrackSeekSliderHandleTime,
    TrackSeekSliderLine,
} from './music.styled';
import { Comment } from '../typography.styled';
import { secondsToTimestamp } from '../../utils/string.util';
import { useCallback, useEffect, useRef, useState } from 'react';
import useSongPosition from '../../hooks/use-song-position';

interface TrackSeekerProps {
    startPos?: number;
    setStartPos?: (n: number) => void;
    onFinishDrag?: (pos: number) => void;
    canDrag?: boolean;
    endBuffer?: number;
}
const TrackSeeker = ({ startPos, setStartPos, onFinishDrag, canDrag = false, endBuffer = 0 }: TrackSeekerProps) => {
    const [draggingPos, setDraggingPos] = useState<number | undefined>();
    const [isDragging, setIsDragging] = useState(false);
    const { pos, duration, percent, seek } = useSongPosition();

    const sliderRef = useRef(null);
    const startPosRef = useRef(startPos);

    const dragging = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!sliderRef.current) return;

            let x;
            if (e instanceof MouseEvent) {
                x = e.clientX;
            } else {
                x = e.touches[0].clientX;
            }

            const rect = (sliderRef.current as HTMLElement).getBoundingClientRect();
            x = x - rect.left;
            const width = rect.width;
            const clickPositionInSlider = x / width;
            const val = Math.max(0, Math.min(duration - endBuffer, clickPositionInSlider * duration));
            if (setStartPos) setStartPos(val);
            else {
                startPosRef.current = val;
                setDraggingPos(val);
            }

            e.preventDefault();
            e.stopPropagation();
        },
        [duration, setStartPos, endBuffer],
    );

    useEffect(() => {
        startPosRef.current = startPos;
    }, [startPos]);

    const stopDragging = useCallback(() => {
        setIsDragging(false);
        setDraggingPos(undefined);
        seek(startPosRef.current ?? 0);
        if (onFinishDrag) onFinishDrag(startPosRef.current ?? 0);
        document.removeEventListener('mousemove', dragging);
        document.removeEventListener('mouseup', stopDragging);
        document.removeEventListener('touchmove', dragging);
        document.removeEventListener('touchend', stopDragging);
    }, [dragging, seek, onFinishDrag]);

    const startDragging = () => {
        setIsDragging(true);
        document.addEventListener('mousemove', dragging);
        document.addEventListener('mouseup', stopDragging);
        document.addEventListener('touchmove', dragging);
        document.addEventListener('touchend', stopDragging);
    };

    let sliderWidth = 0;
    if (sliderRef.current) {
        sliderWidth = (sliderRef.current as HTMLElement).getBoundingClientRect().width;
    }

    return (
        <TrackSeekContainer onMouseDown={startDragging} onTouchStart={startDragging}>
            <Comment $noWidth100 style={{ marginTop: '-8px' }}>
                {secondsToTimestamp(pos)}
            </Comment>
            <TrackSeekSliderContainer>
                <TrackSeekSliderLine ref={sliderRef} redzone={isDragging ? (endBuffer / duration) * sliderWidth : 0} />
                {canDrag ? (
                    <TrackSeekSliderHandle
                        $canDrag
                        percent={isDragging && draggingPos !== undefined ? (draggingPos / duration) * 100 : percent}
                    >
                        <TrackSeekSliderHandleBall style={{ marginTop: '0px' }} />
                        {isDragging && (
                            <TrackSeekSliderHandleTime>
                                <Comment $noWidth100>{secondsToTimestamp(draggingPos ?? pos)}</Comment>
                            </TrackSeekSliderHandleTime>
                        )}
                    </TrackSeekSliderHandle>
                ) : (
                    <TrackSeekSliderCurrentPos percent={percent} />
                )}
                <TrackSeekSliderHandle
                    $canDrag={setStartPos !== undefined}
                    percent={((startPos ?? 0) / duration) * 100}
                >
                    {startPos !== undefined && <TrackSeekSliderHandleLine />}
                    {setStartPos && <TrackSeekSliderHandleBall />}
                    {isDragging && setStartPos && (
                        <TrackSeekSliderHandleTime>
                            <Comment $noWidth100>{secondsToTimestamp(startPos ?? 0)}</Comment>
                        </TrackSeekSliderHandleTime>
                    )}
                </TrackSeekSliderHandle>
            </TrackSeekSliderContainer>
            <Comment $noWidth100 style={{ marginTop: '-8px' }}>
                {secondsToTimestamp(duration)}
            </Comment>
        </TrackSeekContainer>
    );
};

export default TrackSeeker;
