import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { StyledIcon } from './components/navigation/navigation.styled';
import ProfilePic from './components/user/profile-pic.component';
import HomeFeedPage from './pages/home-feed/home-feed-page.component';
import UserPage from './pages/user/user-page.component';
import { musicalNotes, search } from 'ionicons/icons';
import MusicDetails from './components/music/music-details.component';
import PostSongSelectPage from './pages/post/songselect/post-song-select-page.component';
import PostSongUploadPage from './pages/post/songupload/post-song-upload-page.component';
import PostPage from './pages/post/post-page.component';
import UserEditPage from './pages/user/edit/user-edit-page.component';
import ChatListPage from './pages/chat/chat-list-page.component';
import PostDetailsPage from './pages/post-details/post-details-page.component';
import PostShareMenu from './components/feeds/post-share-menu.component';
import ReportDrawer from './components/posts/report-drawer.component';
import SearchPage from './pages/search/search-page.component';
import RepostPage from './pages/post/repost-page.component';
import useCurrentUser from './hooks/use-current-user';
import ChatPage from './pages/chat/chat-page.component';
import useCommonAction from './hooks/use-common-action';
import ChatTabIcon from './components/navigation/chat-tab-icon.component';
import AppUrlListener from './/AppUrlListener';

const MainRouter = () => {
    const [expanded, setExpanded] = useState(false);
    const currentUser = useCurrentUser();
    const { showShareDrawer, showReportDrawer } = useCommonAction();

    return (
        <>
            <IonReactRouter>
                <AppUrlListener></AppUrlListener>
                <MusicDetails expanded={expanded} setOpen={setExpanded} />
                <PostShareMenu show={showShareDrawer} />
                <ReportDrawer show={showReportDrawer} />
                <IonTabs>
                    <IonRouterOutlet>
                        <Route exact path="/home">
                            <HomeFeedPage />
                        </Route>
                        <Route exact path="/home/user/:userId">
                            <UserPage />
                        </Route>
                        <Route exact path="/home/post-details/:postId">
                            <PostDetailsPage />
                        </Route>
                        <Route path="/chat/:chatId/user/:userId">
                            <UserPage />
                        </Route>
                        <Route path="/chat/:chatId" exact>
                            <ChatPage />
                        </Route>
                        <Route path="/chat" exact>
                            <ChatListPage />
                        </Route>
                        <Route path="/search">
                            <SearchPage />
                        </Route>
                        <Route exact path="/user/:userId/edit">
                            <UserEditPage />
                        </Route>
                        <Route exact path="/user/:userId">
                            <UserPage />
                        </Route>
                        <Route exact path="/user/post-details/:postId">
                            <PostDetailsPage />
                        </Route>
                        <Route exact path="/post/song/:songId">
                            <PostPage />
                        </Route>
                        <Route exact path="/post/:postId/repost">
                            <RepostPage />
                        </Route>
                        <Route exact path="/post/songselect">
                            <PostSongSelectPage />
                        </Route>
                        <Route exact path="/post/songupload">
                            <PostSongUploadPage />
                        </Route>
                        <Redirect exact from="/" to="/home" />
                        <Redirect exact from="/post-details/:postId" to="/home/post-details/:postId" />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom" style={{ paddingBottom: 'var(--ion-statusbar-padding, 0)' }}>
                        <IonTabButton tab="home" href="/home">
                            <StyledIcon icon={musicalNotes} />
                        </IonTabButton>
                        <IonTabButton tab="search" href="/search">
                            <StyledIcon icon={search} />
                        </IonTabButton>
                        <IonTabButton tab="chat" href="/chat">
                            <ChatTabIcon />
                        </IonTabButton>
                        <IonTabButton tab="user" href={`/user/${currentUser.userId}`}>
                            <ProfilePic user={currentUser.user ?? null} size="small" />
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </>
    );
};

export default MainRouter;
