import { ReactNode, createContext } from 'react';
import useFeatureFlagContext from '../hooks/context/use-feature-flag-context';
import { TermsVersion } from '../types/feature-flag/terms-version';

export interface FeatureFlagData {
    terms?: TermsVersion;
    isWaiting: boolean;
}

export interface FeatureFlagContextType extends FeatureFlagData {
    acceptTerms: () => void;
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
    isWaiting: false,
    acceptTerms: () => {},
});

const FeatureFlagContextProvider = ({ children }: { children: ReactNode }) => {
    const store = useFeatureFlagContext();
    return <FeatureFlagContext.Provider value={store}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagContextProvider;
