import 'swiper/css';
import { SwiperSlide } from 'swiper/react';
import { FeedSlide, SlideBackground, StyledSwiperFeed } from './feed.styled';
import SwiperCore, { Keyboard, Mousewheel, Virtual } from 'swiper';
import { PostStub } from '../../types';
import PostCard from './post-card.component';
import { FlexContainer } from '../components.styled';
import useSongHandler from '../../hooks/use-song-handler';
import { Body, Comment } from '../typography.styled';
import { IonIcon } from '@ionic/react';
import { chevronDown, closeOutline } from 'ionicons/icons';
import usePostInteraction from '../../hooks/use-post-interaction';
import { isMobileDevice } from '../../utils/device.util';
import LoadingSpinner from '../basic/loading-spinner.component';
SwiperCore.use([Keyboard, Mousewheel, Virtual]);

interface PostCardContainerProps {
    post: PostStub;
}
const PostCardContainer = ({ post }: PostCardContainerProps) => {
    const interact = usePostInteraction(post);
    return <PostCard post={post} interact={interact} />;
};

interface FeedProps {
    posts: PostStub[];
    firstLoad?: boolean;
    moreLoading?: boolean;
    onSwipe?: (index: number) => void;
}
const Feed = ({ posts, firstLoad, moreLoading, onSwipe }: FeedProps) => {
    const { setSongById } = useSongHandler();

    const onInitialize = (swiper: SwiperCore) => {
        if (firstLoad) return;
        const postOn = posts[swiper.activeIndex];
        if (postOn && postOn.song) setSongById(postOn.song.songId, postOn.songStartPosition);
    };

    const onSlideChange = (swiper: SwiperCore) => {
        const index = swiper.activeIndex + (firstLoad ? -1 : 0);
        const postOn = posts[index];
        if (postOn?.song) setSongById(postOn.song.songId, postOn.songStartPosition);
        if (onSwipe) onSwipe(index);
    };

    return (
        <StyledSwiperFeed
            modules={[Virtual, Mousewheel]}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={onSlideChange}
            onSwiper={onInitialize}
            direction="vertical"
            mousewheel={{
                forceToAxis: true,
                thresholdDelta: 20,
            }}
            keyboard={true}
            threshold={isMobileDevice() ? 0 : 10}
            touchMoveStopPropagation
            touchStartPreventDefault
            // virtual={{ enabled: true, addSlidesBefore: 2, addSlidesAfter: 2, cache: true }}
        >
            {firstLoad && (
                <SwiperSlide virtualIndex={0}>
                    <FeedSlide>
                        <FlexContainer direction="column" align="center" justify="center">
                            <Body align="center">swipe to start feed.</Body>
                            <IonIcon style={{ color: 'white', fontSize: '32px' }} icon={chevronDown} />
                        </FlexContainer>
                    </FeedSlide>
                </SwiperSlide>
            )}
            {posts.length === 0 && (
                <SwiperSlide virtualIndex={0}>
                    <FeedSlide>
                        <FlexContainer direction="column" align="center" justify="center">
                            <IonIcon style={{ color: 'white', fontSize: '32px' }} icon={closeOutline} />
                            <Body align="center">this feed is empty</Body>
                            <Comment align="center">please adjust your settings</Comment>
                        </FlexContainer>
                    </FeedSlide>
                </SwiperSlide>
            )}
            {posts.map((p, i) => (
                <SwiperSlide key={i} virtualIndex={i + 1}>
                    <FeedSlide>
                        <SlideBackground image={p.backgroundImageUrl || p.song?.coverImageUrl || ''} />
                        <FlexContainer direction="column" align="center" justify="center">
                            <PostCardContainer post={p} />
                            {/* <LinkCollection links={p.song.links} /> */}
                        </FlexContainer>
                    </FeedSlide>
                </SwiperSlide>
            ))}
            <SwiperSlide virtualIndex={posts.length + 2}>
                <FeedSlide>
                    <FlexContainer direction="column" align="center" justify="center">
                        {moreLoading ? <LoadingSpinner /> : <Body align="center">end of feed</Body>}
                    </FlexContainer>
                </FeedSlide>
            </SwiperSlide>
        </StyledSwiperFeed>
    );
};

export default Feed;
