import { SmallBody } from "../typography.styled";
import { CheckboxArea, CheckboxBox } from "./basic.styled";
import { checkmarkOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

interface CheckboxProps {
    checked: boolean;
    setChecked: (value: boolean) => void;
    label: string;
};
const Checkbox = ({ checked, setChecked, label }: CheckboxProps) => {
    return (
        <CheckboxArea onClick={() => setChecked(!checked)}>
            <CheckboxBox $checked={checked}>
                <IonIcon icon={checkmarkOutline}/>
            </CheckboxBox>
            <SmallBody>{label}</SmallBody>
        </CheckboxArea>
    );
};

export default Checkbox;