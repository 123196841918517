import { Chat } from '../../types/notification/chat';
import { getChatTitle, getMessage } from '../../utils/chat.util';
import { Comment, SmallBody } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import { MessageListItemArea, MessageListItemTextHolder, UnreadMarker } from './messages.styled';

interface MessageListItemProps {
    chat: Chat;
    isUnread: boolean;
}
const MessageListItem = ({ chat, isUnread }: MessageListItemProps) => {
    return (
        <MessageListItemArea to={`/chat/${chat.chatId}`}>
            {chat.chatId >= 0 && chat.user ? (
                <ProfilePic size="small" user={chat.user} />
            ) : (
                <ProfilePic size="small" isAdminUser={true} />
            )}
            <MessageListItemTextHolder>
                <SmallBody>{getChatTitle(chat)}</SmallBody>
                <Comment>{getMessage(chat.messages[0], chat.user)}</Comment>
            </MessageListItemTextHolder>
            {isUnread && <UnreadMarker />}
        </MessageListItemArea>
    );
};

export default MessageListItem;
