import { ReactNode } from 'react';
import { DrawerArea, DrawerBackground, DrawerCloseButton, DrawerContentArea } from './basic.styled';
import { FlexContainer } from '../components.styled';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';

interface DrawerProps {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
}
const Drawer = ({ show, onClose, children }: DrawerProps) => {
    return (
        <DrawerBackground $show={show} onClick={onClose}>
            <DrawerArea $show={show} onClick={(e) => e.stopPropagation()}>
                <DrawerContentArea>
                    <FlexContainer>
                        <DrawerCloseButton onClick={onClose}>
                            <IonIcon icon={chevronDownOutline} />
                        </DrawerCloseButton>
                    </FlexContainer>
                    {children}
                </DrawerContentArea>
            </DrawerArea>
        </DrawerBackground>
    );
};

export default Drawer;
