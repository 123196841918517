import { ReactNode, useEffect, useState } from 'react';
import { DrawerHolder, NoSwipingSlide, SwiperDrawerArea } from './navigation.styled';
import Swiper from 'swiper';
import { SwiperSlide } from 'swiper/react';

interface SlideDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen?: () => void;
    children: ReactNode;
}
const SlideDrawer = ({ isOpen, onClose, onOpen, children }: SlideDrawerProps) => {
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    useEffect(() => {
        if (isOpen && swiper) swiper.slideTo(1);
        if (!isOpen && swiper) swiper.slideTo(0);
    }, [isOpen, swiper]);

    const onSlideChange = (slideOn: number) => {
        if (slideOn === 0) onClose();
        else if (onOpen) onOpen();
    };

    return (
        <DrawerHolder>
            <SwiperDrawerArea
                style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
                onSwiper={setSwiper}
                onSlideChange={(s) => onSlideChange(s.activeIndex)}
                spaceBetween={0}
                slidesPerView={1}
                direction="vertical"
                touchMoveStopPropagation
                touchStartPreventDefault={false}
            >
                <SwiperSlide style={{ pointerEvents: 'none' }}>
                    <NoSwipingSlide />
                </SwiperSlide>
                <SwiperSlide>{children}</SwiperSlide>
            </SwiperDrawerArea>
        </DrawerHolder>
    );
    //return <SlideDrawerArea id="SLIDEDRAWER">{children}</SlideDrawerArea>;
};

export default SlideDrawer;
