import { useEffect, useState } from 'react';
import { get } from '../services/api';
import { Chat } from '../types/notification/chat';
import useAuth from './use-auth';
import useNotifications from './use-notifications';

export interface MessagesBundle {
    messages: Chat[];
    isLoading: boolean;
    isUnread: (c: Chat) => boolean;
}

const useMessageList = (): MessagesBundle => {
    const [messages, setMessages] = useState<Chat[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const notifications = useNotifications();
    const auth = useAuth();

    useEffect(() => {
        setIsLoading(true);
        get<Chat[]>('/messages', auth.auth)
            .then((res) => {
                if (res.data) {
                    setMessages(res.data);
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }, [auth.auth]);

    const isUnread = (c: Chat): boolean => {
        return notifications.newNotifications.filter((n) => n.chatId === c.chatId).length > 0;
    };

    return {
        messages,
        isLoading,
        isUnread,
    };
};

export default useMessageList;
