import { useState } from 'react';
import { ReportReason } from '../types/posts/report-reason';
import { Comment, PostStub, SongStub, UserStub } from '../types';
import { post } from '../services/api';
import useAuth from './use-auth';
import useCommonAction from './use-common-action';

export interface UseReportBundle {
    reasons: ReportReason[];
    setReasons: (r: ReportReason[]) => void;
    toggleReason: (r: ReportReason) => void;
    hasReason: (r: ReportReason) => boolean;
    submitPostReport: (post: PostStub) => void;
    submitCommentReport: (comment: Comment) => void;
    submitSongReport: (song: SongStub) => void;
    submitUserReport: (user: UserStub) => void;
}

const useReport = (): UseReportBundle => {
    const [reasons, setReasons] = useState<ReportReason[]>([]);
    const { setReportPost } = useCommonAction();
    const auth = useAuth();

    const hasReason = (reason: ReportReason): boolean => {
        return reasons.indexOf(reason) >= 0;
    };

    const toggleReason = (reason: ReportReason) => {
        if (hasReason(reason)) {
            setReasons(reasons.filter((r) => r !== reason));
        } else {
            setReasons([...reasons, reason]);
        }
    };

    const submitPostReport = (p: PostStub) => {
        post('/report', { postId: p.postId, reasons }, auth.auth)
            .then((res) => {
                setReasons([]);
                setReportPost(null);
            })
            .catch((err) => console.log(err));
    };
    const submitCommentReport = (c: Comment) => {
        post('/report', { commentId: c.postCommentId, reasons }, auth.auth)
            .then((res) => {
                setReasons([]);
                setReportPost(null);
            })
            .catch((err) => console.log(err));
    };
    const submitSongReport = (s: SongStub) => {
        post('/report', { songId: s.songId, reasons }, auth.auth)
            .then((res) => {
                setReasons([]);
                setReportPost(null);
            })
            .catch((err) => console.log(err));
    };
    const submitUserReport = (u: UserStub) => {
        post('/report', { userId: u.id, reasons }, auth.auth)
            .then((res) => {
                setReasons([]);
                setReportPost(null);
            })
            .catch((err) => console.log(err));
    };

    return {
        reasons,
        setReasons,
        toggleReason,
        hasReason,
        submitPostReport,
        submitCommentReport,
        submitSongReport,
        submitUserReport,
    };
};

export default useReport;
