import { IonIcon } from '@ionic/react';
import Button from '../basic/button.component';
import { Body } from '../typography.styled';
import { SocialShareLinkHolder } from './feed.styled';
import PostListCard from './post-list-card.component';
import { copyOutline, repeatOutline } from 'ionicons/icons';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share';
import Drawer from '../basic/drawer.component';
import { FlexContainer } from '../components.styled';
import useCommonAction from '../../hooks/use-common-action';
import SlideDrawer from '../navigation/slide-drawer.component';

interface PostShareMenuProps {
    show: boolean;
}
const PostShareMenu = ({ show }: PostShareMenuProps) => {
    const { setShowShareDrawer, sharePost, shareLink } = useCommonAction();

    const domain = process.env.REACT_APP_PUBLIC_DOMAIN;
    const link = sharePost ? `${domain}/home/post-details/${sharePost.postId}` : shareLink ?? '';

    const clearShare = () => {
        setShowShareDrawer(false);
    };
    const copyLink = () => {
        navigator.clipboard.writeText(link);
        setShowShareDrawer(false);
    };

    return (
        <SlideDrawer isOpen={show} onClose={clearShare}>
            <Drawer show={show} onClose={clearShare}>
                <Body margin="0 0 0 8px">Share Post</Body>
                {sharePost && <PostListCard post={sharePost} showUser hideButtons />}
                <FlexContainer align="stretch" justify="flex-start">
                    <Button type="small" onClick={copyLink}>
                        <IonIcon icon={copyOutline} style={{ marginRight: '8px' }} />
                        Copy Link
                    </Button>
                    <Button
                        type="small"
                        to={`/post/${sharePost?.postId}/repost`}
                        onClick={() => setShowShareDrawer(false)}
                    >
                        <IonIcon icon={repeatOutline} style={{ marginRight: '8px' }} />
                        Repost
                    </Button>
                </FlexContainer>
                <SocialShareLinkHolder>
                    <FacebookShareButton url={link}>
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={link}>
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>
                    <RedditShareButton url={link}>
                        <RedditIcon size={48} round />
                    </RedditShareButton>
                    <WhatsappShareButton url={link}>
                        <WhatsappIcon size={48} round />
                    </WhatsappShareButton>
                    <LinkedinShareButton url={link}>
                        <LinkedinIcon size={48} round />
                    </LinkedinShareButton>
                    <EmailShareButton url={link} subject={`Check this out on Oxchord`}>
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                </SocialShareLinkHolder>
            </Drawer>
        </SlideDrawer>
    );
};

export default PostShareMenu;
