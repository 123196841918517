import { ReactNode } from "react";
import { BigButton, BigButtonLink, PrimaryButton, StandardButton, StandardButtonLink, StandardDisabledButtonLink } from "./basic.styled";
import LoadingSpinner from "./loading-spinner.component";

interface ButtonProps {
    type: "small"|"primary"|"big";
    onClick?: () => void;
    to?: string;
    disabled?: boolean;
    isLoading?: boolean;
    children?: ReactNode;
};
const Button = ({ type, onClick, to, disabled, isLoading, children }: ButtonProps) => {
    if (to) {
        if (disabled) {
            switch (type) {
                case "small":
                    return <StandardDisabledButtonLink disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</StandardDisabledButtonLink>;
            }
        }
        switch (type) {
            case "small":
                return <StandardButtonLink to={to} onClick={onClick} disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</StandardButtonLink>;
            case "big":
                return <BigButtonLink to={to} onClick={onClick} disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</BigButtonLink>;
        }
    }
    switch (type) {
        case "small":
            return <StandardButton onClick={onClick} disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</StandardButton>;
        case "primary":
            return <PrimaryButton onClick={onClick} disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</PrimaryButton>;
        case "big":
            return <BigButton onClick={onClick} disabled={disabled || isLoading}>{isLoading ? <LoadingSpinner /> : children}</BigButton>;
    }
};

export default Button;