import { IonIcon } from '@ionic/react';
import { FlexContainer } from '../components.styled';
import { Body, SubtleBody } from '../typography.styled';
import {
    ExplicitMarker,
    MusicDiskImage,
    PlaybackContent,
    PlaybackHolder,
    PlaybackPlayPauseButton,
    PlaybackPositionBackground,
} from './music.styled';
import { playOutline, pauseOutline } from 'ionicons/icons';
import useSongHandler from '../../hooks/use-song-handler';
import { SongState } from '../../types';
import useSongPosition from '../../hooks/use-song-position';
import TrackSeeker from './track-seeker.component';

interface SongPlaybackProps {
    startPos?: number;
    setStartPos?: (pos: number) => void;
    onFinishDrag?: (pos: number) => void;
}
const SongPlayback = ({ startPos, setStartPos, onFinishDrag }: SongPlaybackProps) => {
    const { song, state, togglePlaying } = useSongHandler();
    const { percent } = useSongPosition();

    return (
        <PlaybackHolder>
            <PlaybackPositionBackground $playing={state === SongState.Playing} percent={percent} />
            <PlaybackContent>
                <FlexContainer direction="column" align="stretch">
                    <FlexContainer direction="row" align="center">
                        <PlaybackPlayPauseButton onClick={() => togglePlaying()}>
                            <IonIcon icon={state === SongState.Playing ? pauseOutline : playOutline} />
                        </PlaybackPlayPauseButton>
                        <MusicDiskImage
                            image={song?.coverImageUrl ?? null}
                            size="small"
                            speed="10s"
                            $playing={state === SongState.Playing}
                            $hideBorder
                        />
                        <FlexContainer direction="column" padding="0 0 0 16px">
                            <Body>
                                {song?.isExplicit && <ExplicitMarker>E</ExplicitMarker>}
                                {song?.title}
                            </Body>
                            <SubtleBody>{song?.artist}</SubtleBody>
                        </FlexContainer>
                    </FlexContainer>
                    <TrackSeeker
                        startPos={startPos}
                        setStartPos={setStartPos}
                        onFinishDrag={onFinishDrag}
                        endBuffer={15}
                    />
                </FlexContainer>
            </PlaybackContent>
        </PlaybackHolder>
    );
};

export default SongPlayback;
