import { FavoriteFeed, FeedOptions, FeedType } from "../types";

export const matchesOptions = (a: FeedOptions|null, b: FeedOptions|null): boolean => {
    if (a === null || b === null) return a === null && b === null;
    return a.feedType === b.feedType && a.locationId === b.locationId && a.sortMode === b.sortMode
        && a.tags.every(t => b.tags.indexOf(t) >= 0);
};
export const matchesFavoriteFeed = (options: FeedOptions, favorite: FavoriteFeed): boolean => {
    if (favorite.tag === '' || favorite.tag === null) {
        return options.feedType === FeedType.Discover && options.locationId === favorite.locationId
        && (options.tags.length === 0 || (options.tags.length === 1 && options.tags[0] === ''));
    }
    return options.feedType === FeedType.Discover && options.locationId === favorite.locationId
        && options.tags.length === 1 && options.tags[0] === favorite.tag;
};
export const matchesAnyFavoriteFeed = (options: FeedOptions, favorites: FavoriteFeed[]): boolean => {
    if (!favorites || favorites.length === 0) return false;
    for (let i = 0; i < favorites.length; i++) {
        if (matchesFavoriteFeed(options, favorites[i])) return true;
    }
    return false;
};