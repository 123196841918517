import { IonIcon } from '@ionic/react';
import LoadingSpinner from '../../components/basic/loading-spinner.component';
import PostCard from '../../components/feeds/post-card.component';
import OxPage from '../../components/oxpage.component';
import usePostDetails from '../../hooks/use-post-details';
import { Post, toPostStub } from '../../types';
import { CenterContentArea } from '../post/post.styled';
import {
    BackButton,
    CommentArea,
    CommentButtonCollapseArea,
    CommentFadeInArea,
    CommentSendButton,
    CommentUserPicHolder,
    CommentWriteArea,
    PageArea,
    PageBackgroundArea,
    PageContentArea,
} from './post-details.styled';
import { arrowBack, send } from 'ionicons/icons';
import { useParams } from 'react-router-dom';
import SmallTextInput from '../../components/forms/small-text-input.component';
import usePostInteraction from '../../hooks/use-post-interaction';
import { FlexContainer } from '../../components/components.styled';
import ProfilePic from '../../components/user/profile-pic.component';
import useSongHandler from '../../hooks/use-song-handler';
import { useEffect } from 'react';
import PostComment from './components/post-comment.component';
import { Body, SmallBody } from '../../components/typography.styled';
import useCurrentUser from '../../hooks/use-current-user';

interface PostDetailsPageParams {
    postId: string;
}

interface PostDetailsPageInnerProps {
    post: Post;
}
const PostDetailsPageInner = ({ post }: PostDetailsPageInnerProps) => {
    const currentUser = useCurrentUser();
    const interact = usePostInteraction(toPostStub(post, currentUser.userId ?? -1), post.comments);
    const handler = useSongHandler();
    useEffect(() => {
        handler.setSongById(post.song.songId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.song]);
    const commentFocused = interact.commentComposingId === null;

    return (
        <PageArea>
            <PageBackgroundArea image={post?.backgroundImageUrl ?? post?.song?.coverImageUrl} />
            <PageContentArea>
                <BackButton to="../">
                    <IonIcon icon={arrowBack} />
                </BackButton>
                <PostCard detailView post={toPostStub(post, currentUser.userId ?? -1)} interact={interact} />
                <CommentWriteArea>
                    <FlexContainer>
                        <CommentButtonCollapseArea show={!commentFocused || interact.comment.length === 0}>
                            <CommentUserPicHolder>
                                <ProfilePic size="small" user={currentUser.user ?? null} />
                            </CommentUserPicHolder>
                        </CommentButtonCollapseArea>
                        <SmallTextInput
                            $multiline
                            id="comment"
                            label="Comment"
                            value={commentFocused ? interact.comment : ''}
                            setValue={interact.setComment}
                            onFocus={() => interact.setCommentComposingId(null)}
                        />
                        <CommentButtonCollapseArea show={commentFocused && interact.comment.length !== 0}>
                            <CommentSendButton onClick={() => interact.submitComment()}>
                                <IonIcon icon={send} />
                            </CommentSendButton>
                        </CommentButtonCollapseArea>
                    </FlexContainer>
                </CommentWriteArea>
                <CommentArea>
                    {post.comments.length === 0 && interact.myComments.length === 0 && <Body>No Comments Yet</Body>}
                    {interact.myComments
                        .filter((c) => c.parentId === null)
                        .map((myComment, index) => (
                            <CommentFadeInArea key={-1 - index}>
                                <PostComment preventInteract comment={myComment.comment} interact={interact} />
                            </CommentFadeInArea>
                        ))}
                    {post.comments.map((comment, index) => (
                        <PostComment key={index} comment={comment} interact={interact} />
                    ))}
                </CommentArea>
            </PageContentArea>
        </PageArea>
    );
};

const PostDetailsPage = () => {
    const { postId } = useParams<PostDetailsPageParams>();
    const { post, loading } = usePostDetails(+postId);

    if (!post && !loading) {
        return (
            <OxPage>
                <FlexContainer direction="column" align="center" justify="center">
                    <Body $noWidth100 $block>
                        Uh oh.
                    </Body>
                    <SmallBody $noWidth100 $block>
                        Could not find this post. Please go back.
                    </SmallBody>
                </FlexContainer>
            </OxPage>
        );
    }

    return (
        <OxPage>
            {loading || !post ? (
                <CenterContentArea>
                    <LoadingSpinner />
                </CenterContentArea>
            ) : (
                <PostDetailsPageInner post={post} />
            )}
        </OxPage>
    );
};

export default PostDetailsPage;
