import { useRef, useState } from 'react';
import { MAX_IMAGES, PostBodyBundle } from '../../hooks/use-post';
import {
    FullscreenFormBackground,
    FullscreenFormBody,
    FullscreenFormHeader,
    PostAdditionButton,
    PostAdditionHolder,
    PostBodyPreview,
    PostBodyPreviewTextArea,
    PostImageThumbnail,
    PostImageThumbnailHolder,
    PostImageThumbnailXButton,
    PostTagSuggestionButton,
    PostTextAreaBackground,
    PostUserSuggestionButton,
    StyledLabel,
    StyledLabelText,
    StyledPostTextArea,
} from './form.styled';
import Button from '../basic/button.component';
import { Comment, SmallBody, Subtitle } from '../typography.styled';
import PostBody from '../feeds/post-body.component';
import useTags from '../../hooks/use-tags';
import useDebounce from '../../hooks/use-debounce';
import { PostTag, UserStub } from '../../types';
import { getWordAtIndex, replaceWordAtIndex } from '../../utils/string.util';
import { useUserSearchCallback } from '../../hooks/use-user-search';
import ProfilePic from '../user/profile-pic.component';
import ImageSelectButton from './image-select-button.component';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import LoadingSpinner from '../basic/loading-spinner.component';

interface TextInputProps {
    id: string;
    label: string;
    post: PostBodyBundle;
    disableImages?: boolean;
}
const PostBodyInput = ({ id, label, post, disableImages }: TextInputProps) => {
    const [selected, setSelected] = useState(false);
    const [viewportHeight, setViewportHeight] = useState<number | null>();
    visualViewport?.addEventListener('resize', (e) => {
        if (!e.target) return;
        var h = (e.target as any)['height'];
        if (typeof h === 'number' && h < (viewportHeight ?? 500)) setViewportHeight(h);
    });

    const [wordIn, setWordIn] = useState('');
    const [tagSuggestions, setTagSuggestions] = useState<{ value: PostTag; comment: string }[]>([]);
    const [userSuggestions, setUserSuggestions] = useState<UserStub[]>([]);
    const { tagAutocomplete } = useTags();
    const { userAutocomplete } = useUserSearchCallback();
    useDebounce(
        () => {
            if (!wordIn || (!wordIn.startsWith('#') && !wordIn.startsWith('@'))) {
                setTagSuggestions([]);
                setUserSuggestions([]);
            } else if (wordIn.startsWith('#')) {
                setUserSuggestions([]);
                tagAutocomplete(wordIn.substring(1), (data) => setTagSuggestions(data));
            } else {
                setTagSuggestions([]);
                userAutocomplete(wordIn.substring(1), (data) => setUserSuggestions(data));
            }
        },
        [wordIn],
        300,
    );

    const textarea = useRef<HTMLDivElement | null>(null);
    const cursorPosition = useRef<number | undefined>(undefined);

    const onOpenForm = () => {
        setSelected(true);
        if (textarea.current) textarea.current.focus();
    };

    const handleChange = (event: React.FormEvent<HTMLDivElement>) => {
        if (!event) return;
        let text = event.currentTarget.innerText.replaceAll('<', '').replaceAll('>', '');
        text = text.replaceAll(/(\r?\n)(\r?\n)(\r?\n)+/g, (m) => (m.indexOf('\r') > 0 ? '\r\n\r\n' : '\n\n'));
        post.setBody(text);
        let selection = window.getSelection()?.focusNode;

        let p = selection?.parentElement;
        while (p?.id === '') {
            selection = selection?.parentNode;
            p = selection?.parentElement;
        }

        if (selection && selection.parentNode instanceof Element) {
            const parentNode = selection.parentNode as Element;
            const childNodes = Array.from(parentNode.childNodes);
            const index = childNodes.indexOf(selection as ChildNode);

            if (index !== -1) {
                const lengthBefore = childNodes
                    .filter((_, i) => i < index)
                    .map((n) => n.textContent?.length ?? 0)
                    .reduce((a, b) => a + b, 0);
                const position = lengthBefore + (window.getSelection()?.getRangeAt(0)?.startOffset ?? 0);
                cursorPosition.current = position;

                let nlCount = 0;
                for (let i = 0; i < position; i++) if (text[i] === '\n') nlCount++;
                const word = getWordAtIndex(text, position + nlCount);
                setWordIn(word ?? '');
            }
        }
        window.scrollTo(0, 0);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key.length > 1) return;
        updateTextarea(post.body);
    };

    const updateTextarea = (body: string) => {
        if (!textarea.current) return;
        let boldText =
            '<span>' +
            body.replace(/([#@][\w-_.]*\w)/g, (a) => {
                return `</span><b>${a}</b><span>`;
            }) +
            '</span>';
        boldText = boldText.replaceAll(' </span><b>', ' </span><b>');
        boldText = boldText.replaceAll(/\r?\n/g, '</span><br><span>');
        boldText = boldText.replaceAll(/> \w/g, (m) => `> ${m.substring(2)}`);
        // Update the innerHTML of the contenteditable div with the bolded text
        textarea.current.innerHTML = boldText;
        restoreCursorPosition();
    };

    const restoreCursorPosition = () => {
        if (cursorPosition.current && textarea.current?.firstChild) {
            const childNodes = Array.from(textarea.current.firstChild.parentElement?.childNodes ?? []);

            let index = -1;
            let pos = cursorPosition.current;
            while (true) {
                index++;
                let node = childNodes[index];
                if (node.firstChild) node = node.firstChild;
                const textLength = node.textContent?.length ?? 0;
                if (pos - textLength <= 0) {
                    break;
                } else {
                    pos -= textLength;
                }
            }

            const range = document.createRange();
            let node = childNodes[index];
            if (node.firstChild) node = node.firstChild;
            range.setStart(node, pos);
            range.collapse(true);
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    };

    const replaceWord = (tag: string) => {
        if (!cursorPosition.current) return;
        let nlCount = 0;
        for (let i = 0; i < cursorPosition.current; i++) if (post.body[i] === '\n') nlCount++;
        const body = replaceWordAtIndex(post.body, cursorPosition.current + nlCount, tag) ?? post.body;
        cursorPosition.current += tag.length - wordIn.length;
        post.setBody(body);
        updateTextarea(body);
        setTagSuggestions([]);
        setUserSuggestions([]);
        setWordIn('');
    };

    // const insert = (tag: string) => {
    //     if (!cursorPosition.current) {
    //         cursorPosition.current = post.body.length;
    //     }
    //     console.log(cursorPosition.current);
    //     console.log(post.body.substring(0, cursorPosition.current).split('\n').length - 1);
    //     const pos = cursorPosition.current + post.body.substring(0, cursorPosition.current).split('\n').length - 1;
    //     console.log(pos);
    //     const body = post.body.substring(0, pos) + tag + post.body.substring(pos);
    //     cursorPosition.current += tag.length;
    //     post.setBody(body);
    //     updateTextarea(body);
    // };

    const simulateKeyPress = (key: string) => {
        if (!textarea.current) return;
        textarea.current.focus();
        document.execCommand('insertText', false, key);
    };

    return (
        <>
            <FullscreenFormBackground $visible={selected} viewport={viewportHeight ?? 500}>
                <FullscreenFormBody>
                    <FullscreenFormHeader>
                        <Subtitle>What's on your mind?</Subtitle>
                        <Button type="small" onClick={() => setSelected(false)}>
                            Done
                        </Button>
                    </FullscreenFormHeader>
                    <StyledLabel htmlFor={id} $hasInput={post.body ? true : false} style={{ marginTop: '10px' }}>
                        <PostTextAreaBackground $visible={selected} viewport={viewportHeight ?? 500}>
                            <StyledPostTextArea
                                $visible={selected}
                                viewport={viewportHeight ?? 500}
                                ref={textarea}
                                id={id}
                                contentEditable={true}
                                onInput={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={() => window.scrollTo(0, 0)}
                            />
                            {(post.images.length > 0 || post.imagesLoading) && (
                                <PostImageThumbnailHolder>
                                    {post.images.map((i, index) => {
                                        return (
                                            <PostImageThumbnail key={index} img={i.preview}>
                                                <PostImageThumbnailXButton onClick={() => post.removeImage(index)}>
                                                    <IonIcon icon={close} />
                                                </PostImageThumbnailXButton>
                                            </PostImageThumbnail>
                                        );
                                    })}
                                    {post.imagesLoading && (
                                        <PostImageThumbnail>
                                            <LoadingSpinner />
                                        </PostImageThumbnail>
                                    )}
                                </PostImageThumbnailHolder>
                            )}
                        </PostTextAreaBackground>
                    </StyledLabel>
                    <PostAdditionHolder>
                        {wordIn.startsWith('#') &&
                            tagSuggestions.map((s, i) => (
                                <PostTagSuggestionButton key={i} onClick={() => replaceWord(`#${s.value.value}`)}>
                                    <SmallBody>{s.value.value}</SmallBody>
                                    <Comment style={{ fontWeight: '500' }}>{s.comment}</Comment>
                                </PostTagSuggestionButton>
                            ))}
                        {wordIn.startsWith('@') &&
                            userSuggestions.map((s, i) => (
                                <PostUserSuggestionButton key={i} onClick={() => replaceWord(`@${s.username}`)}>
                                    <ProfilePic user={s} size="tiny" />
                                    <SmallBody>{s.username}</SmallBody>
                                    {/* <Comment style={{ fontWeight: '500' }}>{s.username}</Comment> */}
                                </PostUserSuggestionButton>
                            ))}
                        {!wordIn.startsWith('#') && !wordIn.startsWith('@') && (
                            <>
                                {!disableImages && (
                                    <ImageSelectButton
                                        onAddImages={post.onAddImages}
                                        disabled={post.images.length >= MAX_IMAGES || post.imagesLoading}
                                    />
                                )}
                                <PostAdditionButton onClick={() => simulateKeyPress('#')}>#</PostAdditionButton>
                                <PostAdditionButton onClick={() => simulateKeyPress('@')} $isAt>
                                    @
                                </PostAdditionButton>
                            </>
                        )}
                    </PostAdditionHolder>
                </FullscreenFormBody>
            </FullscreenFormBackground>
            <StyledLabel $hasInput={post.body ? true : false} style={{ marginTop: '10px' }}>
                <StyledLabelText>{label}</StyledLabelText>
                <PostBodyPreview onClick={onOpenForm}>
                    <PostBodyPreviewTextArea>
                        <PostBody body={post.body} />
                    </PostBodyPreviewTextArea>
                    {(post.images.length > 0 || post.imagesLoading) && (
                        <PostImageThumbnailHolder>
                            {post.images.map((i, index) => {
                                return <PostImageThumbnail key={index} img={i.preview} />;
                            })}
                        </PostImageThumbnailHolder>
                    )}
                </PostBodyPreview>
            </StyledLabel>
        </>
    );
};

export default PostBodyInput;
