import styled, { keyframes } from 'styled-components';

export const FadeOut = keyframes`
    0% { visibility: visible; opacity: 1; }
    50% { visibility: visible; opacity: 1; }
    99% { visibility: visible; opacity: 0; }
    100% { visibility: hidden; opacity: 0; }
`;

export const SplashArea = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.primary.main};
    animation: ${FadeOut} 2s forwards;
`;
export const PermanentSplashArea = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.primary.main};
`;

export const SplashImage = styled.img`
    width: 100%;
    max-width: 120px;
`;

export const SplashAreaGray = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.base.main};
    animation: ${FadeOut} 2s forwards;
`;
export const PermanentSplashAreaGray = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.base.main};
`;

interface FlexContainerProps {
    direction?: 'row' | 'column';
    align?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
    justify?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'space-between' | 'space-around';
    padding?: string;
    margin?: string;
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    $wrap?: boolean;
}
export const FlexContainer = styled.div<FlexContainerProps>`
    width: ${(props) => props.width ?? '100%'};
    height: ${(props) => props.height ?? '100%'};
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
    display: flex;
    flex-direction: ${(props) => props.direction ?? 'row'};
    align-items: ${(props) => props.align ?? 'flex-start'};
    justify-content: ${(props) => props.justify ?? 'baseline'};
    flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')};
    padding: ${(props) => props.padding ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
`;
