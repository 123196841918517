import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Swiper } from 'swiper/react';
import { addAlpha, darkenColor } from '../../utils/color.util';
import { SpinAnimation } from '../basic/basic.styled';

export const StyledSwiperFeed = styled(Swiper)`
    width: 100%;
    height: 100%;
`;

interface ImageCarouselProps {
    height: string;
}
export const PostImageCarouselHolder = styled.div<ImageCarouselProps>`
    width: 100%;
    height: ${(props) => props.height};
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: ${(props) => props.theme.border.radius.large};
    margin-top: ${(props) => props.theme.margin.small};
    /* overflow: hidden; */
    & > * {
        height: ${(props) => props.height};
    }
`;
export const PostImageCarouselSwiper = styled(Swiper)`
    width: 100%;
    border-radius: ${(props) => props.theme.border.radius.large};
`;
interface PostImageCarouselImageProps {
    image: string;
}
export const PostImageCarouselImage = styled.div<PostImageCarouselImageProps>`
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.image});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
export const CarouselPaginationHolder = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 999px;
    margin: auto;
    padding: ${(props) => props.theme.padding.small};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -32px;
    z-index: 100;
`;
interface CarouselPaginationButtonProps {
    selected: boolean;
}
export const CarouselPaginationButton = styled.button`
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const CarouselPaginationButtonInner = styled.div<CarouselPaginationButtonProps>`
    width: ${(props) => (props.selected ? '10px' : '5px')};
    height: ${(props) => (props.selected ? '10px' : '5px')};
    border: none;
    outline: none;
    border-radius: 999px;
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, props.selected ? 1 : 0.5)};
    transition: width 0.2s, height 0.2s, background-color 0.2s;
`;

export const FeedSlide = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

interface FeedSlideProps {
    image: string;
}
export const SlideBackground = styled.div<FeedSlideProps>`
    position: absolute;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    background-image: url('${(props) => props.image}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(20px);
`;

export const PostCardHolder = styled.div<PostCardAreaProps>`
    width: 200%;
    margin-left: ${(props) => (props.$detailView ? `calc(-100% + ${props.theme.margin.medium})` : '-100%')};
    margin-top: ${(props) => (props.$detailView ? '0' : props.$isRepost ? '0' : '-98px')};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    padding-right: ${(props) => (props.$detailView ? 0 : props.$minimized ? 'calc(50% - 230px)' : 'calc(50% - 250px)')};
    overflow: hidden;
    transition: padding 0.2s;
    @media (max-width: 540px) {
        padding-right: ${(props) => (props.$detailView ? 0 : props.$minimized ? 40 : 20)}px;
    }
`;
export const RepostMessageArea = styled.div`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: 999px;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
    z-index: 80;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.color.base.contrast};
    margin: ${(props) => props.theme.margin.small} 0;
    & > ion-icon {
        font-size: 24px;
        margin-right: 6px;
    }
`;

interface PostCardAreaProps {
    $detailView?: boolean;
    $minimized?: boolean;
    $isRepost?: boolean;
}
export const PostCardArea = styled.div<PostCardAreaProps>`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: ${(props) => (props.$detailView ? props.theme.border.radius.large : props.theme.border.radius.huge)};
    width: ${(props) => (props.$detailView ? '50%' : props.$minimized ? 'calc(100vw - 80px)' : 'calc(100vw - 40px)')};
    ${(props) => (props.$detailView ? '' : props.$minimized ? 'max-width: 460px;' : 'max-width: 500px;')}
    padding: calc(${(props) => props.theme.padding.large} * 1.5);
    padding-bottom: ${(props) => props.theme.padding.medium};
    z-index: 20;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* backdrop-filter: blur(8px); */
    transition: width 0.2s, max-width 0.2s;
    cursor: ${(props) => (props.$minimized ? 'pointer' : 'auto')};
    /* box-shadow: inset 0 4px 10px rgba(255,255,255,0.1); */
    & > div:first-child > button {
        ${(props) => (props.$minimized ? 'display: none;' : '')}
    }
    & > div > span {
        transition: font-size 0.2s;
        ${(props) => (props.$minimized ? 'font-size: 1em;' : '')}
    }
    & > .carousel,
    & > .carousel > div:first-child {
        transition: height 0.2s;
        ${(props) => (props.$minimized ? 'height: 100px;' : '')}
    }
    & > div:last-child {
        overflow: hidden;
        transition: height 0.2s, min-height 0.2s, margin-top 0.2s;
        height: ${(props) => (props.$minimized ? '0' : '64px')};
        min-height: ${(props) => (props.$minimized ? '0' : '64px')};
        margin-top: ${(props) => (props.$minimized ? '10px' : '6px')};
    }
    & > div:last-child > span {
        font-size: 0.8em;
    }
`;
export const PostBodyArea = styled.div`
    width: 100%;
`;
interface PostActionsAreaProps {
    $show: boolean;
    $detailView?: boolean;
}
export const PostActionsArea = styled.div<PostActionsAreaProps>`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.large};
    height: 100%;
    max-width: ${(props) => (props.$show ? '200px' : '0')};
    overflow: hidden;
    margin-left: ${(props) => props.theme.margin.medium};
    margin-right: ${(props) => (props.$detailView ? '0' : `-${props.theme.margin.medium}`)};
    padding: 0;
    z-index: 20;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* backdrop-filter: blur(8px); */
    transition: max-width 0.4s ease;
    /* box-shadow: inset 0 4px 10px rgba(255,255,255,0.1); */
`;
interface PostActionButtonProps {
    $red?: boolean;
}
export const PostActionButton = styled.button<PostActionButtonProps>`
    border: none;
    outline: none;
    font-size: 0.8em;
    color: ${(props) => (props.$red ? props.theme.color.error.main : props.theme.color.base.contrast)};
    background-color: ${(props) =>
        props.$red ? addAlpha(props.theme.color.base.dark, 0.3) : addAlpha(props.theme.color.base.contrast, 0)};
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.1)};
    }
    &:not(:last-child) {
        border-bottom: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    }
`;
export const PostActionButtonLink = styled(Link)<PostActionButtonProps>`
    border: none;
    outline: none;
    font-size: 0.8em;
    color: ${(props) => (props.$red ? props.theme.color.error.main : props.theme.color.base.contrast)};
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.1)};
    }
    &:not(:last-child) {
        border-bottom: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    }
`;

interface PostUserAreaProps {
    $hideInteract?: boolean;
}
export const PostUserArea = styled(Link)<PostUserAreaProps>`
    width: calc(100% + ${(props) => props.theme.padding.medium} + ${(props) => props.theme.padding.medium});
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.padding.medium};
    margin: -${(props) => props.theme.padding.medium} 0 0 -${(props) => props.theme.padding.medium};
    border-radius: 999px;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: ${(props) => (props.$hideInteract ? 'default' : 'pointer')};
    ${(props) =>
        props.$hideInteract
            ? 'pointer-events: none;'
            : `
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    `}
`;
export const PostUserPreviewArea = styled.div`
    width: calc(100% + ${(props) => props.theme.padding.medium} + ${(props) => props.theme.padding.medium});
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.padding.medium};
    margin: -${(props) => props.theme.padding.medium} 0 0 -${(props) => props.theme.padding.medium};
`;

export const PostButton = styled.button`
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    width: 100%;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 ${(props) => props.theme.margin.large};
    padding: ${(props) => props.theme.padding.medium};
    border-radius: ${(props) => props.theme.border.radius.medium};
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
export const PostLinkButton = styled(Link)`
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    width: 100%;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 ${(props) => props.theme.margin.large};
    padding: ${(props) => props.theme.padding.medium};
    border-radius: ${(props) => props.theme.border.radius.medium};
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
interface PostLikeButtonProps {
    reacted: boolean;
}
export const PostLikeButton = styled(PostButton)<PostLikeButtonProps>`
    & > ion-icon {
        color: ${(props) => (props.reacted ? props.theme.color.primary.main : props.theme.color.base.contrast)};
    }
`;
export const PostEllipsisButton = styled.button`
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: -${(props) => props.theme.padding.medium};
    margin-left: ${(props) => props.theme.padding.small};
    margin-right: -${(props) => props.theme.padding.medium};
    padding: ${(props) => props.theme.padding.medium};
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const CommentButton = styled.button`
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
    border-radius: ${(props) => props.theme.border.radius.medium};
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
export const CommentLikeButton = styled(CommentButton)<PostLikeButtonProps>`
    margin-left: -11px;
    & > ion-icon {
        color: ${(props) => (props.reacted ? props.theme.color.primary.main : props.theme.color.base.contrast)};
    }
`;

const PulseAnimation = keyframes`
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1.5); opacity: 0; }
`;
export const LikeButtonPulse = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.color.primary.main};
    position: absolute;
    border-radius: ${(props) => props.theme.border.radius.medium};
    opacity: 0;
    animation-name: ${PulseAnimation};
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
`;

interface PostListCardAreaProps {
    image?: string | null;
}
export const PostListCardArea = styled.div<PostListCardAreaProps>`
    width: calc(100% - ${(props) => props.theme.margin.huge});
    border-radius: ${(props) => props.theme.border.radius.large};
    background-image: url(${(props) => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: ${(props) => props.theme.margin.large};
    margin: ${(props) => props.theme.margin.medium};
`;

interface PostListCardContentAreaProps {
    $hideButtons?: boolean;
}
export const PostListCardContentArea = styled.div<PostListCardContentAreaProps>`
    width: 100%;
    /* backdrop-filter: blur(8px); */
    border-radius: ${(props) => props.theme.border.radius.large};
    overflow: hidden;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    padding: ${(props) => props.theme.margin.large};
    ${(props) => (props.$hideButtons ? '' : `padding-bottom: ${props.theme.margin.small};`)}
`;

export const SongPlayButton = styled.button<PostListCardContentAreaProps>`
    width: 62px;
    min-width: 62px;
    height: 62px;
    min-height: 62px;
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    ${(props) => (props.$hideButtons ? 'pointer-events: none;' : '')}
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:active {
        transition: background-color 0s;
        background-color: rgba(255, 255, 255, 0.2);
    }
`;
interface SongPlayButtonCoverProps {
    image: string;
    $playing?: boolean;
    speed: string;
}
export const SongPlayButtonCover = styled.div<SongPlayButtonCoverProps>`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    & > ion-icon {
        padding-left: 5px;
    }
    ${(props) => (props.$playing ? SpinAnimation : ``)}
`;

interface FeedControlBarAreaProps {
    $expanded: boolean;
}
export const FeedControlBarArea = styled.div<FeedControlBarAreaProps>`
    width: 100%;
    height: ${(props) =>
        props.$expanded ? 'calc(100px + var(--ion-safe-area-top, 0))' : 'calc(70px + var(--ion-safe-area-top, 0))'};
    background-color: ${(props) => props.theme.color.base.main};
    transition: height 0.2s ease;
`;
export const FeedControlBarBackdropArea = styled.div<FeedControlBarAreaProps>`
    position: absolute;
    top: 0;
    overflow: hidden;
    z-index: 100;
    width: 100%;
    height: ${(props) => (props.$expanded ? '100%' : 'calc(70px + var(--ion-safe-area-top, 0))')};
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.6)};
    /* backdrop-filter: blur(8px); */
    transition: height 0.3s ease;
`;
export const FeedControlBarContentArea = styled.div`
    position: absolute;
    width: 100%;
    padding-bottom: ${(props) => props.theme.padding.small};
    background-color: ${(props) => props.theme.color.base.main};
    & > *:not(:first-child) {
        z-index: 10;
    }
`;
interface FeedControlImageProps {
    image?: string;
    expanded: boolean;
}
export const FeedControlImage = styled.div<FeedControlImageProps>`
    background-color: ${(props) => props.theme.color.base.main};
    ${(props) => (props.image ? `background-image: url(${props.image});` : '')}
    background-size: cover;
    background-position: center;
    filter: ${(props) =>
        props.image
            ? props.expanded
                ? 'grayscale(60%) brightness(40%) blur(0px)'
                : 'grayscale(80%) brightness(30%) blur(4px)'
            : 'auto'};
    width: 100%;
    height: ${(props) =>
        props.expanded ? 'calc(250px + var(--ion-safe-area-top, 0))' : 'calc(200px + var(--ion-safe-area-top, 0))'};
    transition: height 0.4s, filter 0.4s;
    position: absolute;
    z-index: 0;
`;
export const FeedControlImageGradient = styled.div<FeedControlBarAreaProps>`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100px;
    box-shadow: inset 0 -100px 50px -50px ${(props) => props.theme.color.base.main};
    margin-top: ${(props) =>
        props.$expanded ? 'calc(150px + var(--ion-safe-area-top, 0))' : 'calc(100px + var(--ion-safe-area-top, 0))'};
    transition: margin-top 0.4s;
`;
export const FeedControlBarFixedButtons = styled.div`
    position: relative;
    width: 100%;
    height: calc(70px + var(--ion-safe-area-top, 0));
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
`;
interface FeedControlBarButtonProps {
    $show?: boolean;
}
export const FeedSelectButton = styled.button<FeedControlBarButtonProps>`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.9)};
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: transform 0.4s, opacity 0.4s;
    transform: translateY(${(props) => (props.$show ? '0px' : '30px')})
        translateX(${(props) => (props.$show ? '0px' : '30px')});
    opacity: ${(props) => (props.$show ? '1' : '0')};
    cursor: ${(props) => (props.$show ? 'pointer' : 'default')};
    & > ion-icon {
        font-size: 30px;
        height: 20px;
        transition: transform 0.4s;
    }
`;
export const OptionsButton = styled.button<FeedControlBarButtonProps>`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0)};
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    margin: ${(props) => props.theme.padding.medium};
    border-radius: ${(props) => props.theme.border.radius.medium};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s, opacity 0.4s;
    transform: translateY(${(props) => (props.$show ? '0px' : '30px')});
    opacity: ${(props) => (props.$show ? '1' : '0')};
    cursor: ${(props) => (props.$show ? 'pointer' : 'default')};
`;
export const OptionsButtonInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: ${(props) => props.theme.padding.medium};
    & > span {
        text-align: right;
        line-height: 1.2;
    }
`;
export const OptionsButtonIconArea = styled.div`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.9)};
    width: 40px;
    height: 40px;
    color: ${(props) => props.theme.color.base.contrast};
    margin: ${(props) => props.theme.padding.medium};
    border-radius: 999px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > ion-icon {
        font-size: 30px;
        height: 24px;
        width: 24px;
    }
`;

interface FeedControlTabAreaProps {
    $show: boolean;
}
export const FeedControlCollapseArea = styled.div<FeedControlTabAreaProps>`
    position: relative;
    width: 100%;
    margin: 0 ${(props) => props.theme.margin.large};
    max-height: ${(props) => (props.$show ? '60vh' : '0px')};
    opacity: ${(props) => (props.$show ? '1' : '0')};
    transition: max-height 0.2s, opacity 0.2s;
    overflow: hidden;
`;
interface FeedSelectRowProps {
    $selected?: boolean;
}
export const FeedSelectRow = styled.div<FeedSelectRowProps>`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: ${(props) => props.theme.margin.medium} auto;
    padding-right: calc(${(props) => props.theme.margin.large} + ${(props) => props.theme.margin.large});
    & > ion-icon {
        color: ${(props) => (props.$selected ? props.theme.color.primary.main : props.theme.color.base.contrast)};
        font-size: 32px;
        height: 32px;
        width: 32px;
        margin-right: ${(props) => props.theme.margin.large};
        margin-top: 2px;
        transition: color 0.5s;
    }
`;
interface FeedOptionButtonProps {
    $show?: boolean;
    $selected: boolean;
}
export const FeedOptionButton = styled.button<FeedOptionButtonProps>`
    width: 100%;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.9)};
    border: none;
    box-shadow: inset ${(props) => (props.$selected ? '80px' : '60px')} 0 0 -60px ${(props) => (props.$selected ? props.theme.color.primary.main : props.theme.color.base.contrast)};
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    padding-left: ${(props) => (props.$selected ? '50px' : '16px')};
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 14px;
    display: flex;
    align-items: center;
    transform: translateY(${(props) => (props.$show ? '0px' : '-20px')})
        translateX(${(props) => (props.$show ? '0px' : '-20px')});
    opacity: ${(props) => (props.$show ? '1' : '0')};
    cursor: pointer;
    transition: box-shadow 0.5s, padding 0.8s, transform 0.4s, opacity 0.4s;
    &:hover {
        box-shadow: inset ${(props) => (props.$selected ? '80px' : '70px')} 0 0 -60px ${(props) => (props.$selected ? props.theme.color.primary.main : props.theme.color.base.contrast)};
        padding-left: ${(props) => (props.$selected ? '50px' : '20px')};
    }
`;
interface FeedOptionButtonSimpleProps {
    $gray?: boolean;
}
export const FeedOptionButtonSimple = styled.button<FeedOptionButtonSimpleProps>`
    width: 100%;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.9)};
    border: none;
    outline: none;
    color: ${(props) => addAlpha(props.theme.color.base.contrast, props.$gray ? 0.5 : 1)};
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 1)};
    }
`;
export const FavoritesSection = styled.div`
    width: 100%;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.9)};
    min-height: 100px;
    max-height: 200px;
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    padding: 0;
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
`;
export const FeedSortButtonHolder = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: ${(props) => props.theme.margin.large} 0;
    padding-right: calc(${(props) => props.theme.margin.large} + ${(props) => props.theme.margin.large});
    gap: ${(props) => props.theme.margin.medium};
    width: 100%;
`;
interface FeedSortButtonProps {
    $selected: boolean;
}
export const FeedSortButton = styled.button<FeedSortButtonProps>`
    width: 100%;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, props.$selected ? 0.9 : 0)};
    border: none;
    outline: none;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, props.$selected ? 0.9 : 0.3)};
    }
    & > ion-icon {
        font-size: 32px;
        height: 32px;
        width: 32px;
        margin-right: ${(props) => props.theme.margin.medium};
        margin-top: 2px;
        transition: color 0.5s;
    }
    & > span {
        font-weight: 400;
        width: fit-content;
        transition: color 0.5s;
    }
    & > * {
        color: ${(props) =>
            props.$selected ? props.theme.color.primary.main : darkenColor(props.theme.color.base.contrast, 0.6)};
    }
`;

export const FeedControlBarCloseButton = styled.button`
    border: none;
    outline: none;
    margin: auto;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0)};
    border-radius: ${(props) => props.theme.border.radius.small};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 24px;
    padding: 6px 24px 0 24px;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.5)};
    }
`;

export const FavoriteFeedButton = styled.button`
    width: 100%;
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    border: none;
    outline: none;
    color: ${(props) => props.theme.color.base.contrast};
    text-align: left;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
`;

export const SocialShareLinkHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: ${(props) => props.theme.margin.medium};
    margin-top: ${(props) => props.theme.margin.large};
    & > * {
        margin: ${(props) => props.theme.margin.small};
    }
`;

export const VerifiedBubble = styled.div`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    border-radius: ${(props) => props.theme.border.radius.large};
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
    z-index: 80;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${(props) => props.theme.margin.small};
    & > ion-icon {
        font-size: 24px;
        margin-right: 6px;
    }
`;
