import { UserStub } from "../../types";
import { SmallBody } from "../typography.styled";
import ProfilePic from "./profile-pic.component";
import { UserListingArea, UserListingAreaSimple } from "./user.styled";

interface UserListingProps {
    user: UserStub;
    noBorder?: boolean;
    noLink?: boolean;

}
const UserListing = ({ user, noBorder, noLink }: UserListingProps) => {
    if (noLink) {
        return (
            <UserListingAreaSimple $noBorder={noBorder}>
                <ProfilePic user={user} size="small" />
                <SmallBody margin="0 0 0 16px">{user.displayName}</SmallBody>
            </UserListingAreaSimple>
        );
    }
    return (
        <UserListingArea $noBorder={noBorder}>
            <ProfilePic user={user} size="small" />
            <SmallBody margin="0 0 0 16px">{user.displayName}</SmallBody>
        </UserListingArea>
    );
};

export default UserListing;