import { useReducer } from 'react';
import {
    FeedControlBarArea,
    FeedControlBarBackdropArea,
    FeedControlBarCloseButton,
    FeedControlBarContentArea,
    FeedControlBarFixedButtons,
    FeedControlImage,
    FeedControlImageGradient,
    FeedSelectButton,
    OptionsButton,
    OptionsButtonIconArea,
    OptionsButtonInfoArea,
} from './feed.styled';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline, options as optionsIcon, chevronUpOutline } from 'ionicons/icons';
import { Body, SmallBody, Comment } from '../typography.styled';
import FeedSelect from './feed-select.component';
import FeedOptionsSelect from './feed-options.component';
import Pagination from '../basic/pagination.component';
import { FlexContainer } from '../components.styled';
import { FeedOptions, FeedType, getFeedTypeString } from '../../types';
import useLocations from '../../hooks/use-locations';

interface FeedControlBarState {
    expanded: boolean;
    onOptions: boolean;
}
type FeedControlBarActions = 'CLOSE' | 'TOGGLE_FEED_SELECT' | 'TOGGLE_OPTIONS';
const feedControlBarReducer = (state: FeedControlBarState, action: FeedControlBarActions): FeedControlBarState => {
    switch (action) {
        case 'CLOSE':
            return { ...state, expanded: false };
        case 'TOGGLE_FEED_SELECT':
            if (state.expanded && state.onOptions) return { expanded: true, onOptions: false };
            return { expanded: !state.expanded, onOptions: false };
        case 'TOGGLE_OPTIONS':
            if (state.expanded && !state.onOptions) return { expanded: true, onOptions: true };
            return { expanded: !state.expanded, onOptions: true };
        default:
            return state;
    }
};

interface FeedControlBarProps {
    options: FeedOptions;
    optionsChanged: boolean;
    setOptions: (options: FeedOptions) => void;
    reloadFeed: () => void;
    toggleFavorite: (options: FeedOptions) => void;
}
const FeedControlBar = ({ options, optionsChanged, setOptions, reloadFeed, toggleFavorite }: FeedControlBarProps) => {
    const [fcbState, fcbDispatch] = useReducer(feedControlBarReducer, { expanded: false, onOptions: false });
    const { getLocation } = useLocations();

    const setFeedType = (type: FeedType) => {
        setOptions({ ...options, feedType: type });
        if (type === FeedType.Discover) {
            fcbDispatch('TOGGLE_OPTIONS');
        }
    };

    const setFavoriteOptions = (options: FeedOptions) => {
        setOptions(options);
        fcbDispatch('TOGGLE_OPTIONS');
    };

    const handleClose = () => {
        fcbDispatch('CLOSE');
        if (optionsChanged) reloadFeed();
    };

    return (
        <FeedControlBarArea $expanded={fcbState.expanded}>
            <FeedControlBarBackdropArea $expanded={fcbState.expanded} onClick={handleClose}>
                <FeedControlBarContentArea onClick={(e) => e.stopPropagation()}>
                    <FeedControlImage expanded={fcbState.expanded} />
                    <FeedControlImageGradient $expanded={fcbState.expanded} />
                    <FeedControlBarFixedButtons>
                        <FeedSelectButton
                            $show={!fcbState.expanded || fcbState.onOptions}
                            onClick={() => fcbDispatch('TOGGLE_FEED_SELECT')}
                        >
                            <SmallBody>{getFeedTypeString(options.feedType)}</SmallBody>
                            <IonIcon icon={chevronDownOutline} />
                        </FeedSelectButton>
                        <OptionsButton
                            $show={(!fcbState.expanded || !fcbState.onOptions) && options.feedType !== FeedType.Home}
                            onClick={() => fcbDispatch('TOGGLE_OPTIONS')}
                        >
                            <OptionsButtonInfoArea>
                                <Body>{getLocation(options.locationId)?.name}</Body>
                                <Comment>{options.tags[0] ?? ''}</Comment>
                            </OptionsButtonInfoArea>
                            <OptionsButtonIconArea>
                                <IonIcon icon={optionsIcon} />
                            </OptionsButtonIconArea>
                        </OptionsButton>
                    </FeedControlBarFixedButtons>
                    <Pagination pageOn={fcbState.onOptions ? 1 : 0} relative>
                        <FeedSelect
                            show={!fcbState.onOptions && fcbState.expanded}
                            feedtype={options.feedType}
                            setFeedType={setFeedType}
                            setOptions={setFavoriteOptions}
                        />
                        <FeedOptionsSelect options={options} setOptions={setOptions} toggleFavorite={toggleFavorite} />
                    </Pagination>
                    <FlexContainer>
                        <FeedControlBarCloseButton onClick={handleClose}>
                            <IonIcon icon={chevronUpOutline} />
                        </FeedControlBarCloseButton>
                    </FlexContainer>
                </FeedControlBarContentArea>
            </FeedControlBarBackdropArea>
        </FeedControlBarArea>
    );
};

export default FeedControlBar;
