import { get } from "../services/api";
import useAuth from "./use-auth";
import { PostTag } from "../types";


export interface TagsBundle {
    tagAutocomplete: (search: string, callback: (data: { value: PostTag, comment: string }[]) => void) => void;
    customTagValidation: (values: PostTag[], v: string) => PostTag | null;
};

const useTags = (): TagsBundle => {
    const auth = useAuth();

    const tagAutocomplete = (search: string, callback: (data: { value: PostTag, comment: string }[]) => void) => {
        get<{ value: PostTag, comment: string }[]>(`/post/tag/search?query=${search}`, auth.auth)
            .then(res => {
                if (res.hadError || !res.data) { return; }
                callback(res.data);
            })
            .catch(e => console.log(e))
    };

    return {
        tagAutocomplete,
        customTagValidation
    };
};

function customTagValidation(values: PostTag[], v: string): PostTag | null {
    if (values.findIndex(t => t.value === v) >= 0) return null;
    const cleaned = cleanTag(v);
    if (cleaned === "") return null;
    return {
        id: -2 - values.length,
        value: cleaned
    };
};

function cleanTag(v: string): string {
    let cleanedTag = v.replaceAll("-", " ").trim().replaceAll(" ", "-").toLowerCase();
    while (cleanedTag.indexOf("--") >= 0) cleanedTag = cleanedTag.replaceAll("--", "-");
    const arr = cleanedTag.split("").filter(c => isAlphaNumeric(c) || c === "-");
    cleanedTag = arr.join("");
    return cleanedTag;
};

function isAlphaNumeric(c: string): boolean {
    const code = c.charCodeAt(0);
    if ((code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123)) { // lower alpha (a-z)
            return true;
    }
    return false;
};

export default useTags;