import { getLinkIcon } from '../../utils/link.util';
import { CollectionContainer, IconLinkButton, StyledLinkIcon } from './basic.styled';

interface LinkCollectionProps {
    links: string[];
}
const LinkCollection = ({ links }: LinkCollectionProps) => {
    return (
        <CollectionContainer>
            {links.map((l, i) => (
                <IconLinkButton href={l} key={i}>
                    <StyledLinkIcon src={getLinkIcon(l)} />
                </IconLinkButton>
            ))}
        </CollectionContainer>
    );
};

export default LinkCollection;
