import { useCallback, useEffect, useState } from 'react';
import { compressionOptions, convertIfHeic } from '../utils/image.util';
import imageCompression from 'browser-image-compression';
import { post, postFile } from '../services/api';
import useAuth from './use-auth';
import { NewPostRequest, Post, PostStub, PostTag, SongStub, UserStub } from '../types';
import useTags from './use-tags';
import useCurrentUser from './use-current-user';

export const MAX_IMAGES = 5;

export interface PostBodyBundle {
    body: string;
    setBody: (value: string) => void;
    images: ImageFileBundle[];
    onAddImages: (files: File[]) => void;
    removeImage: (i: number) => void;
    imagesLoading: boolean;
}
export interface PostBundle extends PostBodyBundle {
    songStart: number;
    setSongStart: (value: number) => void;
    tags: PostTag[];
    setTags: (value: PostTag[]) => void;
    locationId: number;
    setLocationId: (value: number) => void;
    tagAutocomplete: (search: string, callback: (data: { value: PostTag; comment: string }[]) => void) => void;
    customTagValidation: (values: PostTag[], v: string) => PostTag | null;
    background: ImageFileBundle | null;
    onDropBackground: (files: File[]) => void;
    clearBackground: () => void;
    postPreview: PostStub;
    startUpload: () => void;
    isUploading: boolean;
    uploadProgress: number;
    uploadErrorMessage: string | null;
}

interface ImageFileBundle {
    preview: string;
    file: File;
    title: string;
}

const usePost = (song: SongStub | null): PostBundle => {
    const [backgroundFile, setBackgroundFile] = useState<ImageFileBundle | null>(null);
    const [imageFiles, setImageFiles] = useState<ImageFileBundle[]>([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [body, setBody] = useState<string>('');
    const [songStart, setSongStart] = useState<number>(0);
    const [tags, setTags] = useState<PostTag[]>([]);
    const [locationId, setLocationId] = useState<number>(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState<string | null>(null);

    const auth = useAuth();
    const currentUser = useCurrentUser();

    useEffect(() => {
        if (currentUser.user?.homeBaseId) {
            setLocationId(currentUser.user?.homeBaseId);
        }
    }, [currentUser.user?.homeBaseId]);

    const onDropBackground = useCallback((files: File[]) => {
        if (files.length !== 1) return;
        //setIsProcessing(true);
        Promise.all(files.map((f) => convertIfHeic(f).then((f) => imageCompression(f, compressionOptions))))
            .then((out) => {
                const file = out[0];
                setBackgroundFile({
                    preview: URL.createObjectURL(file),
                    file: file,
                    title: file.name,
                });
            })
            .catch((err) => {
                console.log(err);
            });
        //.finally(() => setIsProcessing(false));
    }, []);
    const clearBackground = () => {
        setBackgroundFile(null);
    };

    const onAddImages = useCallback(
        (files: File[]) => {
            if (files.length < 1 || imageFiles.length >= MAX_IMAGES) return;
            setImagesLoading(true);
            Promise.all(files.map((f) => convertIfHeic(f).then((f) => imageCompression(f, compressionOptions))))
                .then((out) => {
                    const newImages: ImageFileBundle[] = out
                        .filter((_, i) => i < MAX_IMAGES - imageFiles.length)
                        .map((file) => ({
                            preview: URL.createObjectURL(file),
                            file: file,
                            title: file.name,
                        }));
                    setImageFiles([...imageFiles, ...newImages]);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => setImagesLoading(false));
        },
        [imageFiles],
    );

    const removeImage = useCallback(
        (i: number) => {
            if (i < 0 || i >= imageFiles.length) return;
            setImageFiles([...imageFiles.slice(0, i), ...imageFiles.slice(i + 1)]);
        },
        [imageFiles],
    );

    const uploadImage = (postId: number, index: number) => {
        setUploadProgress(25 + (75 / (imageFiles.length + 1)) * index);
        postFile<Post>(`/post/${postId}/media`, imageFiles[index].file, auth.auth)
            .then((a) => {
                if (a.hadError) {
                    setUploadError(a.errorMessage ?? 'Something went wrong');
                    return;
                }
                if (imageFiles.length > index + 1) {
                    uploadImage(postId, index + 1);
                } else {
                    setUploadProgress(100);
                }
            })
            .catch((e) => {
                console.log(e);
                setUploadError('Something went wrong');
            });
    };

    const startUpload = () => {
        if (!body) return;
        if (!song) return;
        const request: NewPostRequest = {
            body,
            songStartPosition: songStart,
            songId: song.songId,
            locationId,
            tags: tags.map((t) => t.value),
        };
        setUploadProgress(25);
        post<Post>('/post', request, auth.auth)
            .then((s) => {
                if (s.hadError || !s.data?.postId) {
                    setUploadError(s.errorMessage ?? 'Something went wrong');
                    return;
                }
                if (imageFiles.length > 0) {
                    uploadImage(s.data?.postId, 0);
                } else {
                    setUploadProgress(100);
                }
            })
            .catch((e) => {
                console.log(e);
                setUploadError('Something went wrong');
            });
    };

    const { tagAutocomplete, customTagValidation } = useTags();

    return {
        body,
        setBody,
        songStart,
        setSongStart,
        tags,
        setTags,
        locationId,
        setLocationId,
        tagAutocomplete,
        customTagValidation,
        images: imageFiles,
        onAddImages,
        removeImage,
        imagesLoading,
        background: backgroundFile,
        onDropBackground,
        clearBackground,
        postPreview: {
            postId: -1,
            body,
            song: song,
            reactionCount: 0,
            commentCount: 0,
            reaction: null,
            user: currentUser.user as UserStub,
            createdAt: new Date(),
        },
        startUpload,
        isUploading: uploadProgress > 0,
        uploadProgress,
        uploadErrorMessage: uploadError,
    };
};

export default usePost;
