import styled from 'styled-components';
import { BarberpoleThick } from '../../components/basic/basic.styled';

export const ConstructionTape = styled.div`
    width: 100%;
    max-width: 600px;
    height: 20px;
    background-size: calc(200% + 50px) 100%;
    animation: ${BarberpoleThick} 10s linear infinite;
    background-image: repeating-linear-gradient(
        -45deg,
        ${(props) => props.theme.color.warning.main},
        ${(props) => props.theme.color.warning.main} 30px,
        rgba(0, 0, 0, 0) 30px,
        rgba(0, 0, 0, 0) 60px
    );
`;

export const ContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${(props) => props.theme.margin.huge});
    height: 100%;
    max-width: 600px;
    margin: ${(props) => props.theme.margin.huge} auto;
    padding-top: var(--ion-safe-area-top, 0);
    align-items: center;
`;
interface ChatContentAreaProps {
    $hasTextbox?: boolean;
}
export const ChatContentArea = styled.div<ChatContentAreaProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding-bottom: ${(props) => (props.$hasTextbox ? 140 : 75)}px;
    align-items: center;
    overflow-y: scroll;
`;

export const ChatHeaderArea = styled.div`
    width: 100%;
    border-bottom: solid 1px ${(props) => props.theme.color.primary.main};
    padding-top: var(--ion-safe-area-top, 0);
`;
export const ChatHeaderContentArea = styled.div`
    width: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.padding.medium};
    & > span {
        margin-left: ${(props) => props.theme.margin.medium};
    }
    & > a {
        margin: 0;
        margin-right: ${(props) => props.theme.margin.medium};
    }
`;
export const MessageArea = styled.div`
    width: 100%;
    padding-bottom: 80px;
    width: calc(100% - 40px);
    max-width: 500px;
    margin: 0 auto;
`;
