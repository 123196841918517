import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

interface LoadingSkeletonProps {
    width?: string;
    height?: string;
    count?: number;
    loading: boolean;
    children: ReactNode;
};
const LoadingSkeleton = ({width, height, count, loading, children}: LoadingSkeletonProps) => {
    return loading ?
        <Skeleton width={width ?? "100%"} height={height} count={count ?? 1} baseColor="rgba(31,30,32,0.2)" highlightColor="rgba(130,120,140,0.2)" />
        : <>{children}</>
};

export default LoadingSkeleton;