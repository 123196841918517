import { PaginationArea, PaginationAreaInner, PaginationPage } from "./basic.styled";


interface PaginationProps {
    pageOn: number;
    relative?: boolean;
    fullscreen?: boolean;
    children: any[];
};
const Pagination = ({ pageOn, fullscreen, relative, children }: PaginationProps) => {
    return (
        <PaginationArea $fullscreen={fullscreen} $relative={relative}>
            <PaginationAreaInner pageCount={children.length} pageOn={pageOn}>
                {children.map((c,i) => <PaginationPage key={i} visible={pageOn === i ? 1 : 0}>{c}</PaginationPage>)}
            </PaginationAreaInner>
        </PaginationArea>
    );
}

export default Pagination;