import { SongStub } from "../../types";
import { FlexContainer } from "../components.styled";
import { Body, SubtleBody } from "../typography.styled";
import { MusicDiskImage, SongListItemArea, SongListItemLinkArea } from "./music.styled";

interface SongListItemProps {
    song: SongStub;
    playing?: boolean;
    onClick?: () => void;
    to?: string;
};
const SongListItem = ({ song, playing, onClick, to }: SongListItemProps) => {
    const content = <>
        <MusicDiskImage
            image={song.coverImageUrl ?? null}
            size="small"
            speed="10s"
            $playing={playing ?? false}
            $hideBorder
        />
        <FlexContainer direction="column" padding="0 0 0 16px">
            <Body>{song.title}</Body>
            <SubtleBody>{song.artist}</SubtleBody>
        </FlexContainer>
    </>
    if (to) {
        return (
            <SongListItemLinkArea to={to} onClick={onClick}>
                {content}
            </SongListItemLinkArea>
        );
    }
    return (
        <SongListItemArea onClick={onClick}>
            {content}
        </SongListItemArea>
    );
};

export default SongListItem;